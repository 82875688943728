import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router';

const PrivateRoute = () => {
  const location = useLocation();
  const authentication = useSelector((state) => state.users.authentication);
  
  return authentication ? (
    <Outlet />
  ) : (
    <Navigate to='/login' state={{ from: location.pathname }} />
  );
}

export default PrivateRoute;