import React, { useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@mui/material';
import { MdSearch } from 'react-icons/md';

import Input from '../../../components/Input';
import DataTable from '../../../components/DataTable';
import ButtonsForm from '../../../components/ButtonsForm';
import SimilarList from '../../../components/SimilarList';

import { ListProducts, UpdateProduct, ClearProducts } from '../../../redux/actions/Products';

import Functions from '../../../utils/Functions';

const InitialSimilar = {
  _id: ''
};

const InitialValues = {
  _id: '',
  description: '',
  brand: '',
  similar: [{ ...InitialSimilar }],
  unity: 'PC',
  stock: '',
  stockAlert: '',
  costPrice: '',
  salePrice: '',
  ncm: '',
  tag: false
};

const InitialInvalids = {
  _id: false,
  description: false,
  costPrice: false,
  salePrice: false,
  stock: false,
  stockAlert: false
};

const columns = [
  { id: '_id', numeric: false, disablePadding: false, label: 'Código' },
  { id: 'description', numeric: false, disablePadding: true, label: 'Descrição', limit: 30 },
  { id: 'stock', numeric: true, disablePadding: false, label: 'Estoque' },
  { id: 'costPrice', numeric: true, disablePadding: false, label: 'Custo', coin: true },
  { id: 'salePrice', numeric: true, disablePadding: false, label: 'Venda', coin: true }
];

const Balance = () => {
  const dispatch = useDispatch();
  const searchFocus = useRef(null);
  const products = useSelector((state) => state.products.products);
  const [values, setValues] = useState(InitialValues);
  const [invalids, setInvalids] = useState(InitialInvalids);
  const [feedBacks, setFeedBacks] = useState({});

  const setFocus = () => {
    searchFocus.current && searchFocus.current.focus()
  }

  const [searchValue, setSearchValue] = useState('');

  const handle = (event, type, required, size) => {
    const { name, value } = event.target;

    if (type === 'currency') {
      if (name === 'costPrice') {
        let sale = String(Number(value.replace(/\D/g, '')) * 2);
        setValues({
          ...values,
          costPrice: Functions.MaskCurrency(value),
          salePrice: Functions.MaskCurrency(sale),
        });
      }
      else {
        setValues({
          ...values,
          [name]: Functions.MaskCurrency(value)
        });
      }
    }
    else if (type === 'text') {
      setValues({
        ...values,
        [name]: value.toUpperCase()
      });
    }
    else {
      setValues({
        ...values,
        [name]: value
      });
    }
    if (invalids[name]) {
      validateField(name, value, type, required, size);
    }
  };

  const validateField = (name, value, type, required, size) => {
    let valueReturn = {
      invalid: false,
      feedBack: undefined
    }
    if (required && value.length === 0) {
      valueReturn = {
        invalid: true,
        feedBack: 'Preenchimento obrigatório.'
      }
    }
    else {
      switch (type) {
        case 'text':
          valueReturn = Functions.ValidateText(value, size);
          break
        case 'select':
          if (value === '-1') {
            valueReturn = {
              invalid: true,
              feedBack: 'Seleção inválida.'
            }
          }
          break
        default:
          valueReturn = {
            invalid: false,
            feedBack: undefined
          }
          break;
      }
    }

    setInvalids({
      ...invalids,
      [name]: valueReturn.invalid
    });
    setFeedBacks({
      ...feedBacks,
      [name]: valueReturn.feedBack
    });

  };

  const setSimilar = (list) => {
    setValues({
      ...values,
      similar: list
    });
  }

  const save = () => {
    console.log(values);
    if ((values._id === '' || values.description === '' || values.stock === '' || values.stockAlert === '' ||
      values.costPrice === '' || values.salePrice === '') || (invalids._id !== false || invalids.description !== false ||
        invalids.costPrice !== false || invalids.salePrice !== false || invalids.stock !== false || invalids.stockAlert !== false)) {
      //erro
      Functions.ErrorString('Preenchimento incoreto, por favor verefique os campos em destaque.');
    }
    else {
      const data = {
        ...values,
        costPrice: Functions.APICurrency(values.costPrice),
        salePrice: Functions.APICurrency(values.salePrice),
        tag: 1
      }
      console.log(values);
      dispatch(UpdateProduct(data, apiReturn));
    }
  }

  const apiReturn = (response, error) => {
    if (response) {
      Functions.SuccessString(`Produto atualizado com sucesso.`);

      setValues({
        ...InitialValues,
        similar: [{ ...InitialSimilar }],        
      });
      //dispatch(ClearProducts());
      setSearchValue('');
      setFocus();
      setInvalids(InitialInvalids);
    }
    else {
      console.log(error);
      return Functions.ErrorString('NETWORK ERROR');
    }
  }

  const handleSearchValue = (event) => {
    const value = event.target.value;
    setSearchValue(value.toUpperCase());
    if (value.length >= 3) {
      getSearch();
    }
  }

  const getSearch = () => {
    if (searchValue.length >= 3) {
      const data = {
        field: '_id',
        search: searchValue
      };
      return dispatch(ListProducts(data));
    }
  }

  const KeySpect = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      save(values);
    }
  }

  const setProduct = (Selected) => {
    const newPrice = Selected.costPrice + ((12 / 100) * Selected.costPrice);
    setValues({
      ...values,
      _id: Selected._id,
      description: Selected.description,
      brand: Selected.brand,
      similar: Selected.similar,
      unity: Selected.unity,
      stockAlert: Selected.stockAlert,
      costPrice: Functions.ShowCurrency(newPrice),
      salePrice: Functions.ShowCurrency((newPrice > Selected.salePrice) ?
        newPrice : Selected.salePrice
      ),
      ncm: Selected.ncm,
      tag: Selected.tag === 1 ? true : false
    })
  }

  useEffect(() => {
    if (products.length === 1) {
      setProduct(products[0]);
    }

    // eslint-disable-next-line
  }, [products]);

  useEffect(() => {
    dispatch(ClearProducts());
    document.title = `SIGEP - Balanço`;

    // eslint-disable-next-line
  }, []);

  return (
    <Grid container className='body-main form' spacing={3}>
      <Grid item xs={12} sm={12} md={7} lg={3}>
        <Input
          model='icon'
          name='search'
          label='Pesquisar'
          id='search'
          autoFocus
          value={searchValue}
          onChange={handleSearchValue}
          labelWidth={75}
          action={getSearch}
          icon={<MdSearch />}
          inputRef={searchFocus}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={3}>
        <Input
          id='_id'
          label='Código'
          name='_id'
          value={values._id}
          onKeyDown={KeySpect}
          required
          disabled
          onChange={(event) => handle(event, 'text', true, 5)}
          onBlur={() => validateField('_id', values._id, 'text', true, 5)}
          error={invalids._id || (values._id === '' && feedBacks.form !== undefined)}
          helperText={feedBacks._id || feedBacks.form}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Input
          id='description'
          label='Decrição'
          name='description'
          value={values.description}
          onKeyDown={KeySpect}
          required
          disabled
          onChange={(event) => handle(event, 'text', true, 5)}
          onBlur={() => validateField('description', values.description, 'text', true, 5)}
          error={invalids.description || (values.description === '' && feedBacks.form !== undefined)}
          helperText={feedBacks.description || feedBacks.form}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Grid container spacing={3} className='quantity-form'>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              id='stock'
              label='Estoque'
              name='stock'
              type='number'
              value={values.stock}
              onKeyDown={KeySpect}
              onChange={(event) => handle(event, 'number', true)}
              onBlur={() => validateField('stock', values.stock, 'number', true)}
              required
              error={invalids.stock || (values.stock === '' && feedBacks.form !== undefined)}
              helperText={feedBacks.stock || feedBacks.form}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              id='stockAlert'
              label='Alerta'
              name='stockAlert'
              type='number'
              value={values.stockAlert}
              onKeyDown={KeySpect}
              onChange={(event) => handle(event, 'number', true)}
              onBlur={() => validateField('stockAlert', values.stockAlert, 'number', true)}
              required
              error={invalids.stockAlert || (values.stockAlert === '' && feedBacks.form !== undefined)}
              helperText={feedBacks.stockAlert || feedBacks.form}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              id='costPrice'
              label='Preço de custo'
              name='costPrice'
              model='currency'
              value={values.costPrice}
              onKeyDown={KeySpect}
              onChange={(event) => handle(event, 'currency', true)}
              onBlur={() => validateField('costPrice', values.costPrice, 'currency', true)}
              labelWidth={125}
              required
              error={invalids.costPrice || (values.costPrice === '' && feedBacks.form !== undefined)}
              helperText={feedBacks.costPrice || feedBacks.form}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              id='salePrice'
              label='Preço de venda'
              name='salePrice'
              model='currency'
              value={values.salePrice}
              onKeyDown={KeySpect}
              onChange={(event) => handle(event, 'currency', true)}
              onBlur={() => validateField('salePrice', values.salePrice, 'currency', true)}
              labelWidth={125}
              required
              error={invalids.salePrice || (values.salePrice === '' && feedBacks.form !== undefined)}
              helperText={feedBacks.salePrice || feedBacks.form}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <SimilarList list={values.similar} setList={setSimilar} />
      </Grid>
      {products.length > 0 && (
        <DataTable columns={columns} noSelect
          rows={products}
          selectAction={setProduct}
        />
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <ButtonsForm save={save} />
      </Grid>
    </Grid>
  );
}

export default Balance;