import {
  signin,
  verify,
  registered,
  credentials,
  updatePassword,
  resetPassword,
  resend,
  list,
  add,
  find,
  update,
  updateEmail,
  activate 
} from './api';
import { SetLoading, RemoveLoading } from '../System';

import Functions from '../../../utils/Functions';

export const SIGN_IN = 'SIGN_IN';
export const SOCIAL_USER = 'SOCIAL_USER';
export const SIGN_OUT = 'SIGN_OUT';
export const FIND_USER = 'FIND_USER';
export const LIST_USERS = 'LIST_USERS';
export const CLEAR_USERS = 'CLEAR_USERS';

export const SignIn = (data, call, fail) => {
  return (dispatch) => {
    dispatch(SetLoading('SignIn'));
    return signin(data)
      .then((response) => {
        dispatch({
          type: SIGN_IN,
          payload: response.data,
        });
      })
      .then(call)
      .catch((error) => {
        fail(error.response, data);
      })
      .finally(() => dispatch(RemoveLoading('SignIn')));
  };
};

export const Verify = (token, data, call, fail) => {
  return (dispatch) => {
    dispatch(SetLoading('VerifyUser'));
    return verify(token, data)
      .then((response) => {
        call(response);
      })
      .catch((error) => {
        fail(error.response);
      })
      .finally(() => dispatch(RemoveLoading('VerifyUser')));
  };
};

export const Registered = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('Registered'));
    return registered(data)
      .then((response) => {
        call(response.data);
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('Registered')));
  };
};

export const Credentials = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('Credentials'));
    return credentials(data)
      .then((response) => {
        call();
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('Credentials')));
  };
};

export const UpdatePassword = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('UpdatePassword'));
    return updatePassword(data)
      .then((response) => {
        call(response);
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('UpdatePassword')));
  };
};

export const UpdateEmail = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('UpdateEmail'));
    return updateEmail(data)
      .then((response) => {
        call(response);
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('UpdatePassword')));
  };
};

export const ResetPassword = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('ResetPassword'));
    console.log(data);
    return resetPassword(data)
      .then((response) => {
        call(response);
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('ResetPassword')));
  };
};

export const Resend = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('Resend'));
    return resend(data)
      .then((response) => {
        call();
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('Resend')));
  };
};

export const SocialUser = (data) => {
  return (dispatch) => {
    return dispatch({
      type: SOCIAL_USER,
      payload: data,
    });
  };
};

export const SignOut = () => {
  return (dispatch) => {
    return dispatch({
      type: SIGN_OUT,
    });
  };
};

export const ListUsers = (data) => {
  return (dispatch) => {
    dispatch(SetLoading('GetUsers'));
    return list(data)
      .then((response) => {
        dispatch({
          type: LIST_USERS,
          payload: response.data,
        });
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('GetUsers')));
  };
};

export const FindUser = (_id, call) => {
  return (dispatch) => {
    dispatch(SetLoading('FindUser'));
    return find(_id)
      .then((response) => {
        dispatch({
          type: FIND_USER,
          payload: response.data,
        });
        if(call) call();
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('FindUser')));
  };
};

export const AddUser = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('AddUser'));
    return add(data)
      .then((response) => call(response.data))
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('AddUser')));
  };
};

export const UpdateUser = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('UpdateUser'));
    return update(data)
      .then((response) => call(response.data))
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('UpdateUser')));
  };
};

export const ActivateUser = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('ActivateUser'));
    return activate(data)
      .then(call)
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('ActivateUser')));
  };
};

export const ClearUsers = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_USERS
    })
  }
};
