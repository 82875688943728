import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Input from '../../components/Input';
import Button from '../../components/Button';
import SocialLogin from '../../components/SocialLogin';

import { SignIn, Resend, ResetPassword } from '../../redux/actions/Users';
import { OpenDialog, SetLoading, RemoveLoading } from '../../redux/actions/System';

import LogoJM from '../../assets/logo/jm-mono.svg';
import Logo from '../../assets/logo/white.svg';

import Functions from '../../utils/Functions';
import { useLocation, useNavigate } from 'react-router';

const InitialValues = {
  email: '',
  password: ''
}
const InitialInvalids = {
  email: false,
  password: false
}

const Login = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [values, setValues] = useState(InitialValues);
  const [invalids, setInvalids] = useState(InitialInvalids);
  const [feedBacks, setFeedBacks] = useState({});
  const [dataLogin, setDataLogin] = useState({});
  const dialog = useSelector((state) => state.system.dialog);
  const user = useSelector((state) => state.users.user);

  const handle = (event) => {
    const { name, value } = event.target;
    let valueReturn = {
      invalid: false,
      feedBack: undefined
    }
    setValues({
      ...values,
      [name]: value
    });
    if (value.length === 0) {
      valueReturn = {
        invalid: true,
        feedBack: 'Preenchimento obrigatório.'
      }
    }

    setInvalids({
      ...invalids,
      [name]: valueReturn.invalid,
    });
    setFeedBacks({
      ...feedBacks,
      [name]: valueReturn.feedBack,
    });
  };

  const send = (data, social) => {
    if (!social) {
      if (values.email === '' || values.password === '') {
        Functions.ErrorString('Email e senha devem ser preenchidos.');
        setFeedBacks({
          ...feedBacks,
          form: 'Preenchimento obrigatório.'
        });
        return;
      }
      else {
        if (!data.email) {
          data = { ...values };
        }
      }
    }
    dispatch(SignIn(data, Loading, fail));
  }

  const Loading = () => {
    dispatch(SetLoading('PasswordValidate'));
  }

  useEffect(() => {
    if (user) {
      dispatch(RemoveLoading('PasswordValidate'));
      redirect();
    }
    // eslint-disable-next-line
  }, [user]);

  const fail = (error, data) => {
    setDataLogin(data);
    if (error.status === 416) {
      Functions.ErrorCode(error.status);
      dispatch(OpenDialog({
        title: 'Reenviar email de verificação',
        message: 'Seu email ainda não foi verificado. Deseja que reenviamos o email de verificação novamente?',
        label: 'Reenviar',
        origin: 'resend'
      }));
    }
    else {
      Functions.ErrorCode(error.status);
    }
  }

  const redirect = () => {
    if(location.state !== null){
      navigate(location.state.from);
    }
    else{
      navigate('/drashboard');
    }
  }

  const KeySpect = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      send(values);
    }
  }

  const resendSuccess = () => {
    Functions.SuccessString('Email reenviado, por favor realize a validação.')
  }

  const resetSuccess = () => {
    Functions.SuccessString('Caso email seja valido, por favor siga as instruções enviadas para redefinir sua senha.')
  }

  const callReset = () => {
    console.log(values.email);
    if (values.email !== '' && !invalids.email) {
      const data = {
        email: values.email
      }
      dispatch(ResetPassword(data, resetSuccess));
    }
    else {
      Functions.ErrorString('Email deve ser preenchido corretamente.');
    }

  }

  useEffect(() => {
    if (dialog.open === false && dialog.return.origin === 'resend' && dialog.return.status === true) {
        dispatch(Resend({ email: dataLogin.email }, resendSuccess));
    }
    // eslint-disable-next-line
  }, [dialog]);


  useEffect(() => {
    document.title = `SIGEP - Login`;

    // eslint-disable-next-line
  }, []);

  return (
    <div className='login'>
      <div className='area'>
        <div className='logo'>
          <img className='img' src={Logo} alt='Logo SIGEP' />
        </div>
        <div className='form'>
          <Input
            id='email'
            label='Email'
            type='email'
            name='email'
            value={values.email}
            onChange={handle}
            onKeyDown={KeySpect}
            autoFocus
            required
            error={invalids.email || (values.email === '' && feedBacks.form !== undefined)}
            helperText={feedBacks.email || feedBacks.form}
          />
          <Input
            model='password'
            name='password'
            label='Senha'
            id='password'
            value={values.password}
            onChange={handle}
            onKeyDown={KeySpect}
            required
            error={invalids.password || (values.password === '' && feedBacks.form !== undefined)}
            helperText={feedBacks.password || feedBacks.form}
          />
          <span className='label'>Esqueceu sua senha? <span className='link' onClick={callReset}>Clique aqui</span>.</span>
          <Button
            model='color'
            customColor='red'
            onClick={send}
            className='btn'>
            Entrar
        </Button>
          <SocialLogin label='entre' redirect={redirect} send={send} />
        </div>
        <div className='copyright'>
          <span className='text'>Developer by:</span>
          <a href='https://jmcreative.com.br' target='_blank' rel='noopener noreferrer' >
            <img className='img' src={LogoJM} alt='Logo JM Creative' />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Login;

