import { Typography } from '@mui/material';
import React, { Fragment } from 'react';

import Button from '../Button';

const ButtonsForm = ({ save, cancel, dates, addProduct, addService, close }) => {
  return (
    <div className='buttons-form'>
      {(addProduct || addService) && (
        <div className='orders-actions'>
          {addService && (
            <div className='buttons'>
              <Button
                model='color'
                customColor='green'
                onClick={() => addService(true)}
                className='btn'
              >
                + Serviço
              </Button>
            </div>
          )}
          {addProduct && (
            <div className='buttons'>
              <Button
                model='color'
                customColor='green'
                onClick={() => addProduct(true)}
                className='btn'
              >
                + Produto
              </Button>
            </div>
          )}
        </div>
      )}
      <div className='registrations-dates'>
        {dates && (
          <Fragment>
            <Typography variant='capition' component='span' color='primary'>
              Criado em: <small>{dates.createdAt}</small> - Por: <small>{dates.createdBy}</small>
            </Typography>
            <Typography variant='capition' component='span' color='primary'>
              Alterado em: <small>{dates.updateAt}</small> - Por: <small>{dates.updateBy}</small>
            </Typography>
          </Fragment>
        )}
      </div>
      <div className='buttons-area'>
        {cancel && (
          <div className='buttons'>
            <Button
              color='primary'
              onClick={cancel}
              className='btn'
            >
              Cancelar
            </Button>
          </div>
        )}
        <div className='buttons'>
          <Button
            model='color'
            customColor='red'
            onClick={save}
            className='btn'
          >
            Salvar
          </Button>
        </div>
        {close && (
          <div className='buttons-large'>
            <Button
              model='color'
              customColor='red'
              onClick={close}
              className='btn'
            >
              Salvar e Fechar
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ButtonsForm;