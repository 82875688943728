import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import AppRoutes from '../../routes/routesArray';

const BreadcrumbsBar = ({ location }) => {
  const [list, setList] = useState();

  const arrangeTree = () => {
    if (location === '/drashboard') {
      setList([{
        label: AppRoutes[0].name,
        link: AppRoutes[0].path
      }]);
    }
    else {
      const paths = location.split('/');
      let route, subRoute, subSubRoute = [];
      switch (paths.length) {
        case 2:
          route = AppRoutes.filter((r) => r.path === location)[0];
          if (route) {
            setList([
              {
                label: AppRoutes[0].name,
                link: AppRoutes[0].path
              },
              {
                label: route.name
              }
            ]);
          }
          break;
        case 3:
          route = AppRoutes.filter((r) => r.path === `/${paths[1]}`)[0];
          subRoute = route ? route.children.filter((s) => s.path === location)[0] : null;
          if (subRoute) {
            setList([
              {
                label: AppRoutes[0].name,
                link: AppRoutes[0].path
              },
              {
                label: route.name
              },
              {
                label: subRoute.name,
                link: subRoute.path
              }
            ]);
          }
          break;
        case 4:
          route = AppRoutes.filter((r) => r.path === `/${paths[1]}`)[0];
          subRoute = route ? route.children.filter((s) => s.path === `/${paths[1]}/${paths[2]}`)[0] : null;
          subSubRoute = subRoute ? subRoute.children.filter((s) => s.path === location)[0] : null;
          if (subSubRoute) {
            setList([
              {
                label: AppRoutes[0].name,
                link: AppRoutes[0].path
              },
              {
                label: route.name
              },
              {
                label: subRoute.name,
                link: subRoute.path
              },
              {
                label: subSubRoute.name,
                link: subSubRoute.path
              }
            ]);
          }
          else {
            setList([
              {
                label: AppRoutes[0].name,
                link: AppRoutes[0].path
              },
              {
                label: route.name
              },
              {
                label: 'Editar',
                link: location
              }
            ]);
          }
          break;
        default:
          setList([{
            label: AppRoutes[0].name,
            link: AppRoutes[0].path
          }]);
          break;
      }
    }
  }

  useEffect(() => {
    arrangeTree();

    // eslint-disable-next-line
  }, [location]);

  return (
    <div className="breadcrumbs">
      <Breadcrumbs aria-label='breadcrumb'>
        {list &&
          list.map((item, index) => {
            if (index !== (list.length - 1)) {
              if (item.link) {
                return (
                  <Link key={index} color='inherit' href={item.link} >
                    {item.label}
                  </Link>
                )
              }
              else {
                return (<Typography key={index}>{item.label}</Typography>);
              }

            }
            else {
              return (<Typography key={index} color='textPrimary'>{item.label}</Typography>);
            }
          })}
      </Breadcrumbs>
    </div>
  );
}

export default BreadcrumbsBar;