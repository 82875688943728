import { combineReducers } from 'redux';

import UsersReducer from './UsersReducer';
import SystemReducer from './SystemReducer';
import ProductsReducer from './ProductsReducer';
import ClientsReducer from './ClientsReducer';
import BuysReducer from './BuysReducer';
import OrdersReducer from './OrdersReducer';
import ProvidersReducer from './ProvidersReducer';
import LocationsReducer from './LocationsReducer';

const reducers = combineReducers({
  clients: ClientsReducer,
  buys: BuysReducer,
  orders: OrdersReducer,
  products: ProductsReducer,
  providers: ProvidersReducer,
  users: UsersReducer,
  system: SystemReducer,
  locations: LocationsReducer,
})

export default reducers;