import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import Main from './pages/Main';
import Login from './pages/Login';
import VerifyUser from './pages/Verify';
import LandingPage from './pages/LandingPage';

import PrivateRoute from './routes/PrivateRoute';

import DialogBox from './components/DialogBox';
import Loading from './components/Loading';

function App() {
  const loading = useSelector((state) => state.system.loading);
  const dialog = useSelector((state) => state.system.dialog);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/verify/:token' element={<VerifyUser />} />
        <Route path='/verify/:token/:requestPass' element={<VerifyUser />} />
        <Route path='/login' element={<Login />} />
        <Route element={<PrivateRoute />} >
          <Route path='*' element={<Main />} />
        </Route>
      </Routes>
      {loading.status && (
        <Loading />
      )}
      {dialog.open && (
        <DialogBox dialog={dialog} />
      )}
    </BrowserRouter>
  );
}

export default App;