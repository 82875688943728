import {
  FIND_ORDER,
  LIST_ORDERS,
  CLEAR_ORDERS,

  ADD_PRODUCT_ORDER,
  EDIT_PRODUCT_ORDER,
  DELETE_PRODUCT_ORDER,
  ADD_SERVICE_ORDER,
  EDIT_SERVICE_ORDER,
  DELETE_SERVICE_ORDER

} from '../../actions/Orders';

const global = {
  order: undefined,
  orders: [],
}

export default function OrdersReducer(state = global, action) {
  const valueReturn = action.payload;

  switch (action.type) {
    case LIST_ORDERS:
      return { ...state, orders: valueReturn }
    case FIND_ORDER:
      return { ...state, order: valueReturn }
    case ADD_PRODUCT_ORDER:
      const newProducts = state.order.products;
      newProducts.push(valueReturn);
      return {
        ...state,
        order: {
          ...state.order,
          products: newProducts
        }
      }
    case ADD_SERVICE_ORDER:
      const newServices = state.order.services;
      newServices.push(valueReturn);
      return {
        ...state,
        order: {
          ...state.order,
          services: newServices
        }
      }
    case EDIT_PRODUCT_ORDER:
      const editProducts = state.order.products.map(item => {
        if (item._id === valueReturn._id) {
          item.quantity = valueReturn.quantity;
          item.unityPrice = valueReturn.unityPrice;
          item.total = valueReturn.total;
        }
        return item;
      });
      return {
        ...state,
        order: {
          ...state.order,
          products: editProducts
        }
      };
    case EDIT_SERVICE_ORDER:
      const editServices = state.order.services.map(item => {
        if (item._id === valueReturn._id) {
          item.description = valueReturn.description;
          item.quantity = valueReturn.quantity;
          item.unityPrice = valueReturn.unityPrice;
          item.total = valueReturn.total;
        }
        return item;
      });
      return {
        ...state,
        order: {
          ...state.order,
          services: editServices
        }
      };
    case DELETE_PRODUCT_ORDER:
      const deleteProducts = state.order.products.filter(item => 
        item._id !== valueReturn._id);
      return {
        ...state,
        order: {
          ...state.order,
          products: deleteProducts
        }
      };
    case DELETE_SERVICE_ORDER:
      const deleteServices = state.order.services.filter(item => 
        item._id !== valueReturn._id);
      return {
        ...state,
        order: {
          ...state.order,
          services: deleteServices
        }
      };
    case CLEAR_ORDERS:
      return { ...state, orders: [] }
    default:
      return state;
  }
}