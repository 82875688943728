import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router';

import RoutesArray from './routesArray';

const AppRoutes = () => (
  <Routes>
    {RoutesArray.map((item) => {
      let valueReturn = [];
      if (item.children) {
        valueReturn.push(
          <Route
            path={item.path}
            element={item.element}
            name={item.name}
            key={item.path}
            exact={true}
          />
        );
        item.children.map((child) => {
          if (child.children) {
            valueReturn.push(
              <Route
                path={child.path}
                element={child.element}
                name={child.name}
                key={child.path}
                exact={true}
              />
            );
            return child.children.map((c) => {
              return valueReturn.push(
                <Route {...c} key={c.path} exact={true} />
              );
            })
          }
          else {
            return valueReturn.push(
              <Route {...child} key={child.path} exact={true} />
            );
          }
        })
        return valueReturn;
      }
      else {
        return (
          <Route {...item} key={item.path} exact={true} />
        );
      }
    })}
    <Route path='*' element={<Navigate to='/' />} />
  </Routes>
);

export default AppRoutes;