import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Grid } from '@mui/material';

import DataTable from '../../../components/DataTable';
import ActionsBar from '../../../components/ActionsBar';

import { ListClients, ActivateClient, DeleteClient } from '../../../redux/actions/Clients';
import Functions from '../../../utils/Functions';
import useWindowDimensions from '../../../utils/useWindowDimensions';

const Clients = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();

  const [searchValue, setSearchValue] = useState('');
  const [list, setList] = useState([]);
  const [columns, setColumns] = useState([]);

  const clients = useSelector((state) => state.clients.clients);

  const add = () => {
    navigate('/registrations/clients/add');
  }

  const search = (update) => {
    if (update && searchValue === '') {
      return dispatch(ListClients());
    }
    else {
      const data = {
        field: '_id',
        search: searchValue
      };
      return dispatch(ListClients(data));
    }
  }

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    if (value.length >= 3 || value.length === 0) {
      search();
    }
  }

  const handleCheck = (item, column) => {
    switch (column) {
      case 'active':
        dispatch(ActivateClient({ _id: item._id }, () => search(true)))
        break;
      default:
        break;
    }
  }

  const edit = (item) => {
    navigate('/registrations/clients/edit', {
      state: {
        client: item
      }
    });
  }

  const delet = (item) => {
    dispatch(DeleteClient(item._id, deleteReturn));
  }

  const deleteReturn = (response, error) => {
    if (response) {
      Functions.SuccessString(`Cliente excluido com sucesso.`);
      dispatch(ListClients());
    }
    else {
      Functions.ErrorString('NETWORK ERROR');
    }
  }

  useEffect(() => {
    if (clients.length > 0) {
      const tempList = [];
      clients.map((item) => {
        return tempList.push({
          ...item,
          phone: item.phones[0] ? Functions.MaskPhone(item.phones[0].number) : null,
          document: item.type === 1 ? Functions.MaskCNPJ(item.document) : Functions.MaskCPF(item.document)
        })
      });
      setList(tempList);
    }
    else {
      setList([]);
    }

    // eslint-disable-next-line
  }, [clients]);

  useEffect(() => {
    dispatch(ListClients());
    document.title = 'SIGEP - Clientes';

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let limit = 0;
    if (width < 1300) {
      limit = 30
    }
    else if (width < 1200) {
      limit = 20
    }
    else if (width < 1100) {
      limit = 15
    }
    else {
      limit = 40;
    }
    setColumns([
      { id: 'name', numeric: false, disablePadding: true, label: 'Nome', limit: limit },
      { id: 'document', numeric: false, disablePadding: false, label: 'Documento' },
      { id: 'phone', numeric: false, disablePadding: false, label: 'Telefone' },
      { id: 'active', numeric: false, disablePadding: false, label: 'Ativo', boolean: true },
    ]);

    // eslint-disable-next-line
  }, [height, width]);

  return (
    <Grid container className='body-main' spacing={3}>
      <ActionsBar
        add={add}
        search={search}
        inputValue={searchValue}
        handle={(event) => handleSearch(event)}
      />
      <DataTable
        columns={columns}
        rows={list}
        checkAction={handleCheck}
        editAction={edit}
        deleteAction={delet}
      />
    </Grid>
  );
}

export default Clients;