import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Grid } from '@mui/material';

import Input from '../../components/Input';
import DialogPopUp from '../../components/DialogPopUp';

import Functions from '../../utils/Functions';
import Validates from '../../utils/Validates';
import { AddServiceOrder, FindOrder, UpdateServiceOrder } from '../../redux/actions/Orders';

const InitialValues = {
  _id: '',
  description: '',
  quantity: 0,
  unityPrice: '',
  total: '',
  edit: false
};

const InitialInvalids = {
  _id: false,
  description: false,
  quantity: false,
  unityPrice: false,
  total: false
}

const InitialFeedBacks = {
  form: undefined
}

const ServiceForm = ({ open, toggle, orderId, service }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(InitialValues);
  const [invalids, setInvalids] = useState(InitialInvalids);
  const [feedBacks, setFeedBacks] = useState(InitialFeedBacks);

  const handle = (event, type, required, size) => {
    const { name, value } = event.target;

    if (name === 'quantity') {
      const total = String(Number(values.unityPrice.replace(/\D/g, '')) * value);
      setValues({
        ...values,
        quantity: value,
        total: Functions.MaskCurrency(total)
      });
    }
    else if (name === 'unityPrice') {
      const total2 = String(Number(value.replace(/\D/g, '')) * values.quantity);
      setValues({
        ...values,
        unityPrice: Functions.MaskCurrency(value),
        total: Functions.MaskCurrency(total2)
      });
    }
    else {
      setValues({
        ...values,
        [name]: value
      });
    }
    if (invalids[name]) {
      validateField(name, value, type, required, size);
    }
  };

  const validateField = (name, value, type, required, size) => {
    let valueReturn = Validates.Field(value, type, required, size);

    setInvalids({
      ...invalids,
      [name]: valueReturn.invalid
    });
    setFeedBacks({
      ...feedBacks,
      [name]: valueReturn.feedBack
    });

  };

  const save = () => {
    console.log(values);
    if (Validates.OrderService(values, invalids)) {
      setFeedBacks({
        ...feedBacks,
        form: 'Preenchimento Obrigatório.'
      });
      Functions.ErrorString('Preenchimento incorreto, por favor verifique os campos em destaque.');
    }
    else {
      const data = {
        ...values,
        _id: values._id,
        orderId,
        unityPrice: Functions.APICurrency(values.unityPrice),
        total: Functions.APICurrency(values.total)
      }
      values.edit ?
        dispatch(UpdateServiceOrder(data, apiReturn)) :
        dispatch(AddServiceOrder(data, apiReturn));
    }

  }

  const apiReturn = (response, error) => {
    if (response) {
      Functions.SuccessString(`Serviço ${values.edit ? 'editado' : 'adcionada'} com sucesso.`);
      toggle();
    }
    else {
      if (error.status && error.status === 404) {
        Functions.ErrorString('Produto não encontrado.')
      }
      else if (error.status && error.status === 400) {
        Functions.ErrorString('Quantidade em estoque não atende ao pedido.')
      }
      else {
        Functions.ErrorString('NETWORK ERROR');
      }
      return dispatch(FindOrder(orderId, toggle));

    }
  }

  const KeySpect = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      save(values);
    }
  }

  useEffect(() => {
    if (!open) {
      setValues(InitialValues);
      setInvalids(InitialInvalids);
      setFeedBacks(InitialFeedBacks);
    }
    else if (service) {
      setValues({
        ...values,
        _id: service._id,
        description: service.description,
        quantity: service.quantity,
        unityPrice: Functions.ShowCurrency(service.unityPrice),
        total: Functions.ShowCurrency(service.total),
        edit: true
      })
    }

    // eslint-disable-next-line
  }, [open]);


  return (
    <DialogPopUp
      open={open}
      toggle={toggle}
      title={values.edit ? 'Alterar serviço' : 'Adicionar serviço'}
      action={save}
    >
      <Grid container className='body-main form' spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Input
            id='description'
            label='Descrição'
            name='description'
            value={values.description}
            required
            onChange={(event) => handle(event, 'text', true, 5)}
            onBlur={() => validateField('description', values.description, 'text', true, 5)}
            error={invalids.description || (values.description === '' && feedBacks.form !== undefined)}
            helperText={feedBacks.description || feedBacks.form}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Input
            id='unityPrice'
            label='Valor unitário'
            name='unityPrice'
            model='currency'
            value={values.unityPrice}
            onChange={(event) => handle(event, 'currency', true)}
            onBlur={() => validateField('unityPrice', values.unityPrice, 'currency', true)}
            labelWidth={115}
            required
            error={invalids.unityPrice || (values.unityPrice === '' && feedBacks.form !== undefined)}
            helperText={feedBacks.unityPrice || feedBacks.form}
            onKeyDown={KeySpect}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Input
            id='quantity'
            label='Quantidade'
            name='quantity'
            type='number'
            value={values.quantity}
            onChange={(event) => handle(event, 'number', true)}
            onBlur={() => validateField('quantity', values.quantity, 'number', true)}
            required
            error={invalids.quantity || (values.quantity === 0 && feedBacks.form !== undefined)}
            helperText={feedBacks.quantity || feedBacks.form}
            onKeyDown={KeySpect}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <Input
            id='total'
            label='Valor total'
            name='total'
            model='currency'
            value={values.total}
            onChange={(event) => handle(event, 'currency', true)}
            onBlur={() => validateField('total', values.total, 'currency', true)}
            labelWidth={90}
            required
            readOnly={true}
            error={invalids.total || (values.total === '' && feedBacks.form !== undefined)}
            helperText={feedBacks.total || feedBacks.form}
          />
        </Grid>
      </Grid>
    </DialogPopUp>
  );
}

export default ServiceForm;