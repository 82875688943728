import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { Grid, Link, Typography } from '@mui/material';

import Input from '../../components/Input';
import ButtonsForm from '../../components/ButtonsForm';
import DataTable from '../../components/DataTable';
import ProductForm from './ProductForm';

import { AddBuy, UpdateBuy, FindBuy, DeleteProductBuy } from '../../redux/actions/Buys';
import { OpenDialog } from '../../redux/actions/System';
import { ListProviders } from '../../redux/actions/Providers';

import Functions from '../../utils/Functions';
import Validates from '../../utils/Validates';

const InitialValues = {
  _id: '',
  number: '',
  date: null,
  provider: null,
  products: [],
  subTotal: '',
  total: '',
  observations: '',
  closed: 0,
  edit: false,
};

const InitialInvalids = {
  number: false,
  date: false,
  provider: false,
  subTotal: false,
  total: false,
};

const InitialFeedBacks = {
  form: undefined
}

const columns = [
  { id: '_id', numeric: false, disablePadding: false, label: 'Código' },
  { id: 'description', numeric: false, disablePadding: true, label: 'Descrição', limit: 30 },
  { id: 'quantity', numeric: true, disablePadding: false, label: 'Qt' },
  { id: 'unityPrice', numeric: true, disablePadding: false, label: 'V. Unitário', coin: true },
  { id: 'total', numeric: true, disablePadding: false, label: 'V. Total', coin: true }
];

const Add = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { _id } = useParams();

  const [values, setValues] = useState(InitialValues);
  const [invalids, setInvalids] = useState(InitialInvalids);
  const [feedBacks, setFeedBacks] = useState(InitialFeedBacks);
  const [list, setList] = useState([]);
  const [openProductForm, setOpenProductForm] = useState(false);
  const [buyItem, setBuyItem] = useState(undefined);

  const providers = useSelector((state) => state.providers.providers);
  const buy = useSelector((state) => state.buys.buy);
  const dialog = useSelector((state) => state.system.dialog);

  const handle = (event, type, required, size) => {
    const { name, value } = event.target;

    if (type === 'select') {
      setValues({
        ...values,
        [name]: Number(value)
      });
    }
    else if (type === 'number') {
      setValues({
        ...values,
        [name]: value.replace(/\D/g, '')
      });
    }
    else {
      setValues({
        ...values,
        [name]: value
      });
    }
    if (invalids[name]) {
      validateField(name, value, type, required, size);
    }
  };

  const handleCurrency = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: Functions.MaskCurrency(value),
    });
  }

  const handleSearch = (name, value, required) => {
    setValues({ ...values, [name]: value });
    if (invalids[name]) {
      validateField(name, value, 'search', required);
    }
  }

  const handleDate = (value, name, required) => {
    setValues({
      ...values,
      [name]: value
    });
    if (invalids[name]) {
      validateField(name, value, 'date', required);
    }
  }

  const validateField = (name, value, type, required, size) => {
    let valueReturn = Validates.Field(value, type, required, size);

    setInvalids({
      ...invalids,
      [name]: valueReturn.invalid
    });
    setFeedBacks({
      ...feedBacks,
      [name]: valueReturn.feedBack
    });

  };

  const saveAndClose = () => {
    dispatch(OpenDialog({
      title: 'Confirmação',
      message: 'Compras fechadas não podem ser alterados futuramente. Deseja mesmo fechar essa compra?',
      label: 'Sim',
      origin: 'closeBuy'
    }));
  }

  const save = (close) => {
    console.log(close);
    console.log(values);
    if (Validates.Buy(values, invalids)) {
      setFeedBacks({
        ...feedBacks,
        form: true
      });
      Functions.ErrorString('Preenchimento incorreto, por favor verifique os campos em destaque.');
    }
    else {
      const data = {
        ...values,
        date: values.date.toISOString(),
        provider: {
          _id: values.provider._id,
          name: values.provider.name,
          phone: values.provider.phone
        },
        subTotal: Functions.APICurrency(values.subTotal),
        total: Functions.APICurrency(values.total),
        closed: close,
      };
      values.edit ?
        dispatch(UpdateBuy(data, apiReturn)) :
        dispatch(AddBuy(data, apiReturn));
    }
  }

  const apiReturn = (response, error) => {
    if (response) {
      Functions.SuccessString(`Compra  ${values.edit ? 'editada' : 'cadastrada'} com sucesso.`);
      if (!values.edit) {
        navigate(`/buys/edit/${response._id}`);
      }
      else if (response.closed === 1) {
        navigate(`/buys/view`);
      }
    }
    else {
      return Functions.ErrorString('NETWORK ERROR');
    }
  }

  const deleteReturn = (response, error) => {
    if (response) {
      Functions.SuccessString(`Produto removido com sucesso.`);
    }
    else {
      return Functions.ErrorString('NETWORK ERROR');
    }
  }

  const backList = () => {
    return navigate('/buys');
  }

  const editItem = (item) => {
    setBuyItem(item);
  }

  const deleteItem = (item) => {

    dispatch(DeleteProductBuy(item, deleteReturn));
  }

  useEffect(() => {
    if (dialog.open === false && dialog.return.status === true && dialog.return.origin === 'closeBuy') {
      save(1);
    }

    // eslint-disable-next-line
  }, [dialog]);

  useEffect(() => {
    if (!openProductForm) {
      setBuyItem(undefined);
    }

    // eslint-disable-next-line
  }, [openProductForm]);

  useEffect(() => {
    if (buyItem !== undefined) {
      setOpenProductForm(true);
    }

    // eslint-disable-next-line
  }, [buyItem]);

  useEffect(() => {
    if (buy && buy._id === values._id) {
      const listTemp = [];
      buy.products.map((item) => listTemp.push({
        ...item,
        total: item.quantity * item.unityPrice
      }));
      setList(listTemp);
      setValues({
        ...values,
        ...buy,
        date: new Date(buy.date),
        products: buy.products,
        subTotal: Functions.ShowCurrency(buy.subTotal),
        total: Functions.ShowCurrency(buy.total),
        edit: true
      });
    }

    // eslint-disable-next-line
  }, [buy]);

  useEffect(() => {
    if (_id && _id !== values._id) {
      setValues({
        ...values,
        _id: _id
      })
      dispatch(FindBuy(_id));
      document.title = `SIGEP - Editar compra`;
    }
    else {
      setValues({
        ...InitialValues,
        provider: null,
        date: new Date(),
        products: [],
      });
      document.title = `SIGEP - Adicionar compra`;
    }

    // eslint-disable-next-line
  }, [_id]);

  useEffect(() => {
    if (providers.length === 0) dispatch(ListProviders());

    // eslint-disable-next-line
  }, []);

  return (
    <Grid container className='body-main form' spacing={3}>
      {values.closed === 1 && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography
            component='h3'
            color='red'
          >
            Comprars fechados não podem ser editados
          </Typography>
          <Link href='/buys/view'>Voltar para lista</Link>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <Input
          id='number'
          label='Numero'
          name='number'
          type='number'
          value={values.number}
          required
          disabled={values.edit}
          onChange={(event) => handle(event, 'number', true)}
          onBlur={() => validateField('number', values.number, 'number', true)}
          error={invalids.number || (values.number === '' && feedBacks.form !== undefined)}
          helperText={feedBacks.number || feedBacks.form}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Input
          id='date'
          label='Data'
          name='date'
          value={values.date}
          required
          model='dateTime'
          time={true}
          showTodayButton={true}
          minDate={new Date()}
          onChange={(event) => handleDate(event, 'date', true)}
          onBlur={() => validateField('date', values.date, 'date', true)}
          error={invalids.date || (values.date === '' && feedBacks.form !== undefined)}
          helperText={feedBacks.date || feedBacks.form}
          disabled={values.edit}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Input
          id='provider'
          label='Fornecedor'
          name='provider'
          value={values.provider}
          model='search'
          list={providers}
          required
          autoFocus
          searchKey='name'
          onChange={(event, value) => handleSearch('provider', value, true)}
          onBlur={() => validateField('provider', values.provider, 'search', true)}
          error={invalids.provider || (values.provider === null && feedBacks.form !== undefined)}
          helperText={feedBacks.provider || feedBacks.form}
          disabled={values.edit}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Input
          id='subTotal'
          label='Sub Total'
          name='subTotal'
          model='currency'
          value={values.subTotal}
          onChange={handleCurrency}
          onBlur={() => validateField('subTotal', values.subTotal, 'currency', true)}
          required
          error={invalids.subTotal || (values.subTotal === '' && feedBacks.form !== undefined)}
          helperText={feedBacks.subTotal || feedBacks.form}
          disabled={values.closed}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Input
          id='total'
          label='Desc. de serviços'
          name='total'
          model='currency'
          value={values.total}
          onChange={handleCurrency}
          onBlur={() => validateField('total', values.total, 'currency', true)}
          required
          error={invalids.total || (values.total === '' && feedBacks.form !== undefined)}
          helperText={feedBacks.total || feedBacks.form}
          disabled={values.closed}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Input
          id='observations'
          label='Observações'
          name='observations'
          value={values.observations}
          onChange={(event) => handle(event, 'text', false)}
          disabled={values.closed}
        />
      </Grid>
      {values.closed === 0 && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ButtonsForm
            cancel={backList}
            save={() => save(0)}
            addProduct={values.edit && setOpenProductForm}
            close={values.edit && saveAndClose}
          />
        </Grid>
      )}
      {(values.edit && list.length > 0) && (
        <Fragment>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              component='h3'
              color='white'
            >
              Itens da compra:
            </Typography>
          </Grid>
          <DataTable
            columns={columns}
            noSelect allRows
            rows={list}
            editAction={editItem}
            deleteAction={deleteItem}
            disabled={values.closed === 1}
          />
        </Fragment>
      )}
      <ProductForm
        open={openProductForm}
        toggle={() => setOpenProductForm(!openProductForm)}
        buyId={values._id}
        product={buyItem}
      />
    </Grid>
  );
}

export default Add;