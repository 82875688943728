import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Grid } from '@mui/material';
import Button from '../../../components/Button';

import { GetTags, GetBalance } from '../../../redux/actions/Products';

const PrintTags = () => {
  const dispatch = useDispatch();

  const getTags = () => {
    dispatch(GetTags());
  }

  const getBalance = () => {
    dispatch(GetBalance());
  }

  useEffect(() => {
    document.title = `SIGEP - Drashboard`;

    // eslint-disable-next-line
  }, []);

  return (
    <Grid container className='body-main' spacing={1}>
      <Button
            model='color'
            customColor='red'
            onClick={getTags}
            className='btn'>
            IMPRIMIR ETIQUETAS
        </Button>
      <Button
            model='color'
            customColor='red'
            onClick={getBalance}
            className='btn'>
            IMPRIMIR BALANÇO
        </Button>
    </Grid>
  );
}

export default PrintTags;