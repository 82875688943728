import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Grid } from '@mui/material';

import DataTable from '../../../components/DataTable';
import ActionsBar from '../../../components/ActionsBar';

import { ListProducts, ActivateProduct, DeleteProduct, PrintTag } from '../../../redux/actions/Products';
import Functions from '../../../utils/Functions';
import useWindowDimensions from '../../../utils/useWindowDimensions';

const Products = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  
  const [searchValue, setSearchValue] = useState('');
  const [columns, setColumns] = useState([]);

  const products = useSelector((state) => state.products.products);

  const add = () => {
    navigate('/registrations/products/add');
  }

  const search = (update) => {
    if (update && searchValue === '') {
      return dispatch(ListProducts());
    }
    else {
      const data = {
        field: '_id',
        search: searchValue
      };
      return dispatch(ListProducts(data));
    }
  }

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    if (value.length >= 3 || value.length === 0) {
      search();
    }
  }

  const handleCheck = (item, column) => {
    switch (column) {
      case 'tag':
        dispatch(PrintTag({ _id: item._id }, () => search(true)))
        break;
      case 'active':
        dispatch(ActivateProduct({ _id: item._id }, () => search(true)))
        break;
      default:
        break;
    }
  }

  const edit = (item) => {
    navigate('/registrations/products/edit', {
      state:{
        product: item
      }
    });
  }

  const delet = (item) => {
    dispatch(DeleteProduct(item._id, deleteReturn));
  }

  const deleteReturn = (response, error) => {
    if (response) {
      Functions.SuccessString(`Produto excluido com sucesso.`);
      dispatch(ListProducts());
    }
    else {
      Functions.ErrorString('NETWORK ERROR');
    }
  }

  useEffect(() => {
    dispatch(ListProducts());
    document.title = 'SIGEP - Produtos';

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let limit = 0;
    if (width < 1300) {
      limit = 30
    }
      else if (width < 1200) {
      limit = 18
    }
    else if (width < 1100) {
      limit = 10
    }
    else {
      limit = 40;
    }
    setColumns([
      { id: '_id', numeric: false, disablePadding: false, label: 'Código' },
      { id: 'description', numeric: false, disablePadding: true, label: 'Descrição', limit: limit },
      { id: 'stock', numeric: true, disablePadding: false, label: 'Est' },
      { id: 'salePrice', numeric: true, disablePadding: false, label: 'Venda', coin: true },
      { id: 'tag', numeric: false, disablePadding: false, label: 'Etiqueta', boolean: true },
      { id: 'active', numeric: false, disablePadding: false, label: 'Ativo', boolean: true },
    ]);

    // eslint-disable-next-line
  }, [height, width]);

  return (
    <Grid container className='body-main' spacing={3}>
      <ActionsBar add={add} search={search} inputValue={searchValue} handle={(event) => handleSearch(event)} />
      <DataTable columns={columns} rows={products} checkAction={handleCheck} editAction={edit} deleteAction={delet} />
    </Grid>
  );
}

export default Products;