import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@mui/material';

import Input from '../../components/Input';
import DialogPopUp from '../../components/DialogPopUp';

import { SignOut, UpdateEmail } from '../../redux/actions/Users';

import Functions from '../../utils/Functions';
import Validates from '../../utils/Validates';

const InitialValues = {
  _id: undefined,
  name: '',
  old: '',
  email: '',
  reEnter: ''
};

const InitialInvalids = {
  old: false,
  email: false,
  reEnter: false,
};

const InitialFeedBacks = {
  form: undefined
}

const UpdateEmailForm = ({ open, toggle }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(InitialValues);
  const [invalids, setInvalids] = useState(InitialInvalids);
  const [feedBacks, setFeedBacks] = useState(InitialFeedBacks);
  const user = useSelector((state) => state.users.user);

  const handle = (event, type, required, size) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value
    });
    if (invalids[name]) {
      validateField(name, value, type, required, size);
    }
  };

  const validateField = (name, value, type, required, size) => {
    let valueReturn = Validates.Field(value, type, required, size);

    if(name === 'reEnter' && !valueReturn.invalid && values.email === values.reEnter){
      setInvalids({
        ...invalids,
        email: false,
        reEnter: false
      });
      setFeedBacks({
        ...feedBacks,
        email: undefined,
        reEnter: undefined
      });
    }
    else{
      setInvalids({
        ...invalids,
        [name]: valueReturn.invalid
      });
      setFeedBacks({
        ...feedBacks,
        [name]: valueReturn.feedBack
      });
    }

  };

  const save = () => {
    if (values.old === '' || invalids.old) {
      setInvalids({
        ...invalids,
        old: true
      });
      setFeedBacks({
        ...feedBacks,
        old: 'Email inválido.'
      });
    }
    else if (values.old !== user.email) {
      setInvalids({
        ...invalids,
        old: true
      });
      setFeedBacks({
        ...feedBacks,
        old: 'Email atual diferente.'
      });
    }
    else if (values.email === '' || (invalids.email && values.email !==values.reEnter)) {
      setInvalids({
        ...invalids,
        email: true
      });
      setFeedBacks({
        ...feedBacks,
        email: 'Email inválido.'
      });
    }
    else if (values.email !== values.reEnter) {
      setInvalids({
        ...invalids,
        email: true,
        reEnter: true
      });
      setFeedBacks({
        ...feedBacks,
        email: 'Email diferente da confirmação.',
        reEnter: 'Confirmação diferente do email.'
      });
    }
    else {
      const data = {
        _id: values._id,
        name: values.name,
        email: values.email
      };
      dispatch(UpdateEmail(data, apiReturn));
    }

  }

  const apiReturn = (response, error) => {
    if (response) {
      Functions.SuccessString(`Email alterado com sucesso. Por favor verifique o email e efetue o login novamente.`);
      return dispatch(SignOut());
    }
    else {
      return Functions.ErrorString('NETWORK ERROR');
    }
  }

  useEffect(() => {
    if (open) {
      setValues({
        ...values,
        _id: user._id,
        name: user.name
      });
    }
    else {
      setValues(InitialValues);
      setInvalids(InitialInvalids);
      setFeedBacks(InitialFeedBacks);
    }

    // eslint-disable-next-line
  }, [open]);

  return (
    <DialogPopUp
      open={open}
      toggle={toggle}
      title='Alterar email'
      action={save}
    >
      <Grid container className='body-main form' spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Input
            id='old'
            label='Email atual'
            name='old'
            value={values.old}
            required
            type='email'
            onChange={(event) => handle(event, 'email', true)}
            onBlur={() => validateField('old', values.old, 'email', true)}
            error={invalids.old || (values.old === '' && feedBacks.form !== undefined)}
            helperText={feedBacks.old || feedBacks.form}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Input
            id='email'
            label='Novo email'
            name='email'
            value={values.email}
            required
            type='email'
            onChange={(event) => handle(event, 'email', true)}
            onBlur={() => validateField('email', values.email, 'email', true)}
            error={invalids.email || (values.email === '' && feedBacks.form !== undefined)}
            helperText={feedBacks.email || feedBacks.form}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Input
            id='reEnter'
            label='Confirmação'
            name='reEnter'
            value={values.reEnter}
            required
            type='email'
            onChange={(event) => handle(event, 'email', true)}
            onBlur={() => validateField('reEnter', values.reEnter, 'email', true)}
            error={invalids.reEnter || (values.reEnter === '' && feedBacks.form !== undefined)}
            helperText={feedBacks.reEnter || feedBacks.form}
          />
        </Grid>
      </Grid>
    </DialogPopUp>
  );
}

export default UpdateEmailForm;