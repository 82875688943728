import { model } from '../../../services';

export const list = (data) => {
  if(data){
    return model.post(`/providers`, data);
  }
  else{
    return model.get(`/providers`);
  }
};

export const add = (data) => model.post(`/provider`, data);

export const find = (_id) => model.get(`/provider/${_id}`);

export const del = (_id) => model.delete(`/provider/${_id}`);

export const update = (data) => model.put(`/provider`, data);

export const activate = (data) => model.put(`/provider/activate`, data);
