import { LIST_PRODUCTS, CLEAR_PRODUCTS, FIND_PRODUCT } from '../../actions/Products';

const global = {
  products: [],
  product: undefined
}

export default function ProductsReducer(state = global, action) {
  const valueReturn = action.payload;

  switch (action.type) {
    case LIST_PRODUCTS:
      return { ...state, products: valueReturn }
    case FIND_PRODUCT:
      return { ...state, product: valueReturn }
    case CLEAR_PRODUCTS:
      return { ...state, products: [] }
    default:
      return state;
  }
}