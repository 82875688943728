import React from 'react';

import { Grid } from '@mui/material';
import { MdAdd, MdDelete } from 'react-icons/md';

import Input from '../Input';
import Button from '../Button';

import Functions from '../../utils/Functions';
import Validates from '../../utils/Validates';
import { PhoneLabelEnum } from '../../utils/Enums';

const Phone = ({
  item,
  index,
  addItem,
  removeItem,
  setItem,
  listSize,
  invalids,
  feedBacks,
  formFeedBack
}) => {

  const handle = (event, type, required, size) => {
    const { name, value } = event.target;
    let newItem = item;

    if (type === 'phone') {
      newItem[name] = Functions.MaskPhone(value);
    }
    else if (type === 'select') {
      newItem[name] = Number(value);
    }
    else {
      newItem[name] = value;
    }
    setItem(index, newItem, invalids, feedBacks);
    if (invalids[name]) {
      validateField(name, value, type, required, size);
    }
  }

  const validateField = (name, value, type, required, size) => {
    let newInvalids = invalids;
    let newFeedBacks = feedBacks;

    let valueReturn = Validates.Field(value, type, required, size);

    newInvalids[name] = valueReturn.invalid;
    newFeedBacks[name] = valueReturn.feedBack
    setItem(index, item, newInvalids, newFeedBacks);
  }

  return (
    <div className={index !== listSize-1 ? 'body divisor': 'body'}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Input
            id={`number_phone_${index}`}
            label='Numero'
            name='number'
            value={item.number}
            required
            onChange={(event) => handle(event, 'phone', true)}
            onBlur={() => validateField('number', item.number, 'phone', true)}
            error={invalids.number || (item.number === '' && formFeedBack !== undefined)}
            helperText={feedBacks.number || formFeedBack}
          />
        </Grid>
        <Grid item className='content'>
          <Input
            id={`label_${index}`}
            label='Tipo'
            name='label'
            model='select'
            list={PhoneLabelEnum}
            defaultValue='Selecione'
            value={item.label}
            onChange={(event) => handle(event, 'select', false)}
          />
        </Grid>
        <div className='actions'>
          <Button
            model='color'
            customColor='red'
            size='large'
            onClick={() => removeItem(index)}
            disabled={listSize === 1}
            className='btn'>
            <MdDelete />
          </Button>
          <Button
            model='color'
            customColor='green'
            size='large'
            onClick={() => addItem()}
            className='btn'>
            <MdAdd />
          </Button>
        </div>
      </Grid>
    </div>
  )
}

export default Phone;