import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@mui/material';
import { MdAdd, MdDelete } from 'react-icons/md';

import Input from '../Input';
import Button from '../Button';

import Functions from '../../utils/Functions';
import Validates from '../../utils/Validates';
import { AddressLabelEnum } from '../../utils/Enums';
import { GetAddress, GetCities, GetStates } from '../../redux/actions/Locations';

const Address = ({
  item,
  index,
  addItem,
  removeItem,
  setItem,
  listSize,
  invalids,
  feedBacks,
  formFeedBack
}) => {
  const dispatch = useDispatch();
  const [cities, setCities] = useState([]);
  const states = useSelector((state) => state.locations.states);

  const handle = (event, type, required, size) => {
    const { name, value } = event.target;
    let newItem = item;
    let newInvalid = invalids;
    let newFeedBacks = feedBacks;

    if (name === 'state') {
      if (value !== -1) {
        dispatch(GetCities(value, citiesReturn));
      }

      newItem = {
        ...newItem,
        state: Number(value),
        city: -1
      };
      newInvalid = { ...newInvalid, city: true };
      newFeedBacks = { ...newFeedBacks, city: 'Preenchimento Obrigatório.' };


    }
    else if (type === 'post_code') {
      newItem[name] = Functions.MaskCEP(value);
    }
    else if (type === 'select') {
      newItem[name] = Number(value);
    }
    else {
      newItem[name] = value;
    }
    setItem(index, newItem, newInvalid, newFeedBacks);
    if (invalids[name]) {
      validateField(name, value, type, required, size);
    }
  }

  const validateField = (name, value, type, required, size) => {
    let newInvalids = invalids;
    let newFeedBacks = feedBacks;

    let valueReturn = Validates.Field(value, type, required, size);

    newInvalids[name] = valueReturn.invalid;
    newFeedBacks[name] = valueReturn.feedBack
    setItem(index, item, newInvalids, newFeedBacks);
  }

  const getAddress = (post_code) => {
    if (post_code.replace(/\D/g, '').length === 8) {
      dispatch(GetAddress(post_code, addressReturn));
    }
    else {
      validateField('post_code', post_code, 'post_code', true);
    }
  }

  const addressReturn = (response, error) => {
    if (response && response.status === 200) {
      const stateTemp = states.filter((s) => s.abbreviation === response.state)[0];
      if (stateTemp) {
        dispatch(GetCities(stateTemp.value, citiesPostReturn, {
          ...response,
          state: stateTemp
        }));
      }
      else {
        let newItem = {
          ...item,
          state: -1,
          road: response.address,
          sub_urb: response.district,
        };
        let newInvalids = invalids;
        let newFeedBacks = feedBacks;

        newInvalids.post_code = false;
        newFeedBacks.post_code = undefined;

        setItem(index, newItem, newInvalids, newFeedBacks);
      }
    }
    else {
      Functions.ErrorString('Aconteceu um erro na consulta do CEP. Por favor tente novamente ou insira o endereço manualmente.');
    }
  }

  const citiesPostReturn = (response, addressResponse) => {
    if (response) {
      setCities(Functions.ArrangeAPIList(response));
      const cityTemp = response.filter((s) => s.name === addressResponse.city)[0];
      if (cityTemp) {


        let newItem = {
          ...item,
          state: Number(addressResponse.state.value),
          city: Number(cityTemp._id),
          road: addressResponse.address,
          sub_urb: addressResponse.district,
        };
        let newInvalids = {
          ...invalids,
          post_code: false,
          state: false,
          city: false,
        };
        let newFeedBacks = {
          ...feedBacks,
          post_code: undefined,
          state: undefined,
          city: undefined,
        };

        setItem(index, newItem, newInvalids, newFeedBacks);
      }
    }
  }

  const citiesReturn = (response) => {
    if (response) {
      setCities(Functions.ArrangeAPIList(response));
    }
  }

  useEffect(() => {
    if (item.city && cities.length === 0) dispatch(GetCities(item.state, citiesReturn));

    // eslint-disable-next-line
  }, [item]);

  useEffect(() => {
    if (states.length === 0) dispatch(GetStates());

    // eslint-disable-next-line
  }, [states]);

  return item ? (
    <div className='body'>
      <div className='content'>
        <Grid container className='address-form' spacing={3}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Input
              id={`label_${index}`}
              label='Tipo'
              name='label'
              model='select'
              list={AddressLabelEnum}
              defaultValue='Selecione'
              value={item.label}
              onChange={(event) => handle(event, 'select', false)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Input
              id={`post_code_${index}`}
              label='CEP'
              name='post_code'
              value={item.post_code}
              required
              onChange={(event) => handle(event, 'post_code', true)}
              onBlur={() => getAddress(item.post_code)}
              error={invalids.post_code || (item.post_code === '' && formFeedBack !== undefined)}
              helperText={feedBacks.post_code || formFeedBack}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Input
              id={`state_${index}`}
              label='Estado'
              name='state'
              value={item.state}
              model='select'
              list={states}
              defaultValue='Selecione'
              required
              onChange={(event) => handle(event, 'select', true)}
              onBlur={() => validateField('state', item.state, 'select', true)}
              error={invalids.state || (item.state === -1 && formFeedBack !== undefined)}
              helperText={feedBacks.state || formFeedBack}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Input
              id={`city_${index}`}
              label='Cidade'
              name='city'
              value={item.city}
              model='select'
              list={cities}
              defaultValue='Selecione'
              required
              onChange={(event) => handle(event, 'select', true)}
              onBlur={() => validateField('city', item.city, 'select', true)}
              error={invalids.city || (item.city === -1 && formFeedBack !== undefined)}
              helperText={feedBacks.city || formFeedBack}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Input
              id={`road_${index}`}
              label='Logradouro'
              name='road'
              value={item.road}
              required
              onChange={(event) => handle(event, 'text', true)}
              onBlur={() => validateField('road', item.road, 'text', true)}
              error={invalids.road || (item.road === '' && formFeedBack !== undefined)}
              helperText={feedBacks.road || formFeedBack}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Input
              id={`number_${index}`}
              label='Numero'
              name='number'
              value={item.number}
              required
              onChange={(event) => handle(event, 'number', true)}
              onBlur={() => validateField('number', item.number, 'number', true)}
              error={invalids.number || ((item.number === '' || item.number === null) && formFeedBack !== undefined)}
              helperText={feedBacks.number || formFeedBack}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Input
              id={`sub_urb_${index}`}
              label='Bairro'
              name='sub_urb'
              value={item.sub_urb}
              required
              onChange={(event) => handle(event, 'text', true)}
              onBlur={() => validateField('sub_urb', item.sub_urb, 'text', true)}
              error={invalids.sub_urb || (item.sub_urb === '' && formFeedBack !== undefined)}
              helperText={feedBacks.sub_urb || formFeedBack}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Input
              id={`complement_${index}`}
              label='Complemento'
              name='complement'
              value={item.complement}
              onChange={(event) => handle(event, 'text', false)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Input
              id={`reference_${index}`}
              label='Referência'
              name='reference'
              value={item.reference}
              onChange={(event) => handle(event, 'text', false)}
            />
          </Grid>
        </Grid>
      </div>
      <div className='actions'>
        <Button
          model='color'
          customColor='red'
          size='large'
          onClick={() => removeItem(index)}
          disabled={listSize === 1}
          className='btn'>
          <MdDelete />
        </Button>
        <Button
          model='color'
          customColor='green'
          size='large'
          onClick={() => addItem()}
          className='btn'>
          <MdAdd />
        </Button>
      </div>
    </div>
  ) : null
}

export default Address;