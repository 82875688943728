import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { FaFacebookSquare } from 'react-icons/fa';

import { Registered, Credentials, SocialUser } from '../../redux/actions/Users';
import { OpenDialog } from '../../redux/actions/System';

import Functions from '../../utils/Functions';

const InitialValues = {
  name: null,
  email: null,
  facebookId: null,
  googleId: null,
  photo: null,
}

const Login = ({ label, send }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(InitialValues);
  const [dataLogin, setDataLogin] = useState({});
  const dialog = useSelector((state) => state.system.dialog);

  const verify = () => {
    const data = {
      email: values.email
    };
    dispatch(Registered(data, action));
  }

  const action = (response) => {
    let data = null;
    if (response.registered) {
      dispatch(SocialUser(values));
      if (values.facebookId && response.facebookId) {
        //signin
        data = {
          email: values.email,
          facebookId: values.facebookId,
        }
        send(data, true);
      }
      else if (values.googleId && response.googleId) {
        //signin
        data = {
          email: values.email,
          googleId: values.googleId,
        }
        send(data, true);
      }
      else if (values.facebookId && !response.facebookId) {
        //link account
        data = {
          email: values.email,
          facebookId: values.facebookId,
        }
        setDataLogin(data);
        dispatch(OpenDialog({
          title: 'Conta não vinculada',
          message: 'Conta social utilizada, ainda não está vinculada ao seu cadastro. Você desejar vincular agora?',
          label: 'vincular',
          origin: 'social'
        }));

      }
      else if (values.googleId && !response.googleId) {
        //link account
        data = {
          email: values.email,
          googleId: values.googleId,
        }
        setDataLogin(data);
        dispatch(OpenDialog({
          title: 'Conta não vinculada',
          message: 'Conta social utilizada, ainda não está vinculada ao seu cadastro. Você desejar vincular agora?',
          label: 'vincular',
          origin: 'social'
        }));
      }
    }
    else {
      Functions.ErrorCode(1);
    }
  }

  const signin = () => {
    send(dataLogin, true);
  }

  useEffect(() => {
    if (values.email) {
      verify();
    }

    // eslint-disable-next-line
  }, [values]);

  useEffect(() => {
    if (dialog.open === false && dialog.return.status !== '') {
      if (dialog.return.origin === 'social' && dialog.return.status === true) {
        dispatch(Credentials(dataLogin, signin));
      }
    }

    // eslint-disable-next-line
  }, [dialog]);

  const responseFacebook = (response) => {
    if (response.name) {
      setValues({
        ...values,
        name: response.name,
        email: response.email,
        facebookId: response.id,
        googleId: null,
        photo: response.picture.data.url
      });
    }
    else {
      Functions.ErrorCode(10);
    }

  };

  const responseGoogle = (response) => {
    if (response.profileObj) {
      setValues({
        ...values,
        name: response.profileObj.name,
        email: response.profileObj.email,
        facebookId: null,
        googleId: response.profileObj.googleId,
        photo: response.profileObj.imageUrl
      });
    }
    else {
      console.log(response);
      Functions.ErrorCode(11);
    }
  };

  return (
    <div className='social-login'>
      <span className='label'>ou {label} com:</span>
      <div className='buttons'>
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_ID}
          fields='name,email,picture,birthday'
          cssClass='button-facebook'
          textButton='FACEBOOK'
          callback={responseFacebook}
          icon={<FaFacebookSquare />}
        />
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_ID}
          className='button-google'
          buttonText='GOOGLE'
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
        />
      </div>
    </div>
  );
}

export default Login;

