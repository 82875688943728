import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Grid } from '@mui/material';

import Input from '../../components/Input';
import DialogPopUp from '../../components/DialogPopUp';

import { FindUser, UpdateUser } from '../../redux/actions/Users';

import Functions from '../../utils/Functions';
import Validates from '../../utils/Validates';

const InitialValues = {
  _id: undefined,
  name: '',
  type: -1,
  email: '',
};

const InitialInvalids = {
  name: false,
  type: false,
  email: false,
};

const InitialFeedBacks = {
  form: undefined
}

const User = ({ open, toggle, openUpdateEmail, openUpdatePassword }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(InitialValues);
  const [invalids, setInvalids] = useState(InitialInvalids);
  const [feedBacks, setFeedBacks] = useState(InitialFeedBacks);
  const user = useSelector((state) => state.users.user);

  const handle = (event, type, required, size) => {
    const { name, value } = event.target;

    if (type === 'select') {
      setValues({
        ...values,
        [name]: Number(value)
      });
    }
    else {
      setValues({
        ...values,
        [name]: value
      });
    }
    if (invalids[name]) {
      validateField(name, value, type, required, size);
    }
  };

  const validateField = (name, value, type, required, size) => {
    let valueReturn = Validates.Field(value, type, required, size);

    setInvalids({
      ...invalids,
      [name]: valueReturn.invalid
    });
    setFeedBacks({
      ...feedBacks,
      [name]: valueReturn.feedBack
    });

  };

  const save = () => {
    if (Validates.User(values, invalids)) {
      //erro
      setFeedBacks({
        ...feedBacks,
        form: 'Preenchimento Obrigatório.'
      });
      Functions.ErrorString('Preenchimento incoreto, por favor verefique os campos em destaque.');
    }
    else {
      const data = {
        ...values
      };

      dispatch(UpdateUser(data, apiReturn));
    }

  }

  const apiReturn = (response, error) => {
    if (response) {
      Functions.SuccessString(`Dados editados com sucesso.`);
      return dispatch(FindUser(user._id, toggle));
    }
    else {
      return Functions.ErrorString('NETWORK ERROR');
    }
  }

  useEffect(() => {
    if (open) {
      setValues({
        ...values,
        ...user,
      });
    }
    else {
      setValues(InitialValues);
      setInvalids(InitialInvalids);
      setFeedBacks(InitialFeedBacks);
    }

    // eslint-disable-next-line
  }, [open]);

  return (
    <DialogPopUp
      open={open}
      toggle={toggle}
      title='Meus Dados'
      action={save}
    >
      <Grid container className='body-main form' spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Input
            id='name'
            label='Nome'
            name='name'
            value={values.name}
            autoFocus
            required
            onChange={(event) => handle(event, 'text', true, 3)}
            onBlur={() => validateField('name', values.name, 'text', true, 3)}
            error={invalids.name || (values.name === '' && feedBacks.form !== undefined)}
            helperText={feedBacks.name || feedBacks.form}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Input
            id='email'
            label='Email'
            name='email'
            value={values.email}
            required
            disabled={true}
            type='email'
            onChange={(event) => handle(event, 'email', true)}
            onBlur={() => validateField('email', values.email, 'email', true)}
            error={invalids.email || (values.email === '' && feedBacks.form !== undefined)}
            helperText={feedBacks.email || feedBacks.form}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className='user-actions'>
          <Button
            size='large'
            color='primary'
            onClick={openUpdateEmail}
            className='btn'>
            Alterar email
          </Button>
          <Button
            size='large'
            color='primary'
            onClick={openUpdatePassword}
            className='btn'>
            Alterar senha
          </Button>
        </Grid>
      </Grid>
    </DialogPopUp>
  );
}

export default User;