import React from 'react';

import OutlinedDiv from '../OutlinedDiv'
import Phone from './Phone';

const InitialInvalid = {
  number: false
}

const InitialPhone = {
  _id: undefined,
  number: '',
  label: -1
}
const PhoneList = ({ list, setList, invalidsList, feedBacksList, required, formFeedBack }) => {
  const addItem = () => {
    const newList = list;
    const newInvalids = invalidsList;
    const newFeedBacks = feedBacksList;

    newList.push({ ...InitialPhone });
    newInvalids.push({ ...InitialInvalid });
    newFeedBacks.push({});

    setList('phones', newList, newInvalids, newFeedBacks);
  }

  const removeItem = (index) => {
    const newList = list;
    const newInvalids = invalidsList;
    const newFeedBacks = feedBacksList;

    if (list.length > 1 || index !== 0) {
      newList.splice(index, 1);
      newInvalids.splice(index, 1);
      newFeedBacks.splice(index, 1);
    }

    setList('phones', newList, newInvalids, newFeedBacks);
  }

  const setItem = (index, item, invalid, feedBack) => {
    const newList = list;
    const newInvalids = invalidsList;
    const newFeedBacks = feedBacksList;

    newList[index] = item;
    newInvalids[index] = invalid;
    newFeedBacks[index] = feedBack;

    setList('phones', newList, newInvalids, newFeedBacks);
  }

  return (
    <div className='objects-list-area'>
      <OutlinedDiv label='Telefones' required={required} >
        {list &&
          list.map((item, index) => (
            <Phone
              key={`phone-${index}`}
              item={item}
              index={index}
              addItem={addItem}
              removeItem={removeItem}
              setItem={setItem}
              listSize={list.length}
              list={list}
              invalids={invalidsList[index]}
              feedBacks={feedBacksList[index]}
              formFeedBack={formFeedBack}
            />
          ))}
      </OutlinedDiv>
    </div>
  )
}

export default PhoneList;