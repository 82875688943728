import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Grid } from '@mui/material';

import DataTable from '../../components/DataTable';
import ActionsBar from '../../components/ActionsBar';

import { ListBuys } from '../../redux/actions/Buys';
import Functions from '../../utils/Functions';
import useWindowDimensions from '../../utils/useWindowDimensions';

const Buys = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const buys = useSelector((state) => state.buys.buys);
  const [searchValue, setSearchValue] = useState('');
  const [list, setList] = useState([]);
  const [columns, setColumns] = useState([]);

  const add = () => {
    navigate('/buys/add');
  }

  const search = (update) => {
    if (update && searchValue === '') {
      return dispatch(ListBuys({ field: 'date' }));
    }
    else {
      const data = {
        field: 'date',
        search: searchValue
      };
      return dispatch(ListBuys(data));
    }
  }

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    if (value.length >= 3 || value.length === 0) {
      search();
    }
  }

  const edit = (item) => {
    navigate(`/buys/edit/${item._id}`);
  }

  useEffect(() => {
    if (buys.length > 0) {
      const tempList = [];
      buys.map((item) => {
        return tempList.push({
          ...item,
          providerName: item.provider.name,
          date: Functions.ShowDateTime(item.date, true)
        })
      });
      setList(tempList);
    }
    else {
      setList([]);
    }

    // eslint-disable-next-line
  }, [buys]);

  useEffect(() => {
    dispatch(ListBuys());
    document.title = `SIGEP - Compras`;

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let limit = 0;
    if (width < 1300) {
      limit = 30
    }
    else if (width < 1200) {
      limit = 18
    }
    else if (width < 1100) {
      limit = 10
    }
    else {
      limit = 40;
    }
    setColumns([
      { id: 'number', numeric: false, disablePadding: false, label: '#' },
      { id: 'providerName', numeric: false, disablePadding: true, label: 'Fornecedor', limit: limit },
      { id: 'date', numeric: false, disablePadding: false, label: 'Data' },
      { id: 'total', numeric: true, disablePadding: false, label: 'V. Total', coin: true },
    ]);

    // eslint-disable-next-line
  }, [height, width]);

  return (
    <Grid container className='body-main' spacing={3}>
      <ActionsBar
        add={add}
        search={search}
        inputValue={searchValue}
        handle={(event) => handleSearch(event)}
      />
      <DataTable
        columns={columns}
        rows={list}
        editAction={edit}
        editCondition='closed'
        viewAction={edit}
      />
    </Grid>
  );
}

export default Buys;