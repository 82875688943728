import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { Grid, Link, Typography } from '@mui/material';

import Input from '../../components/Input';
import ButtonsForm from '../../components/ButtonsForm';
import DataTable from '../../components/DataTable';
import OutlinedDiv from '../../components/OutlinedDiv';
import ProductForm from './ProductForm';
import ServiceForm from './ServiceForm';

import { AddOrder, UpdateOrder, FindOrder, DeleteServiceOrder, DeleteProductOrder } from '../../redux/actions/Orders';
import { OpenDialog } from '../../redux/actions/System';
import { ListClients } from '../../redux/actions/Clients';

import Functions from '../../utils/Functions';
import Validates from '../../utils/Validates';

const InitialValues = {
  _id: '',
  number: '',
  date: null,
  client: null,
  vehicle: '',
  vehicleColor: '',
  vehicleNumber: '',
  vehiclePlate: '',
  vehicleKM: '',
  products: [],
  services: [],
  productsSubtotal: 0,
  productsDiscount: 0,
  servicesSubtotal: 0,
  servicesDiscount: 0,
  total: 0,
  observations: '',
  closed: 0,
  edit: false,
};


const InitialInvalids = {
  date: false,
  client: false,
  vehiclePlate: false,
  vehicleNumber: false,
  vehicleColor: false,
  vehicle: false,
  productsDiscount: false,
  servicesDiscount: false,
};

const InitialFeedBacks = {
  form: undefined
}

const columns = [
  { id: '_id', numeric: false, disablePadding: false, label: 'Código' },
  { id: 'description', numeric: false, disablePadding: true, label: 'Descrição', limit: 30 },
  { id: 'quantity', numeric: true, disablePadding: false, label: 'Qt' },
  { id: 'unityPrice', numeric: true, disablePadding: false, label: 'V. Unitário', coin: true },
  { id: 'total', numeric: true, disablePadding: false, label: 'V. Total', coin: true }
];

const Add = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { _id } = useParams();

  const [values, setValues] = useState(InitialValues);
  const [invalids, setInvalids] = useState(InitialInvalids);
  const [feedBacks, setFeedBacks] = useState(InitialFeedBacks);
  const [list, setList] = useState([]);
  const [openProductForm, setOpenProductForm] = useState(false);
  const [openServiceForm, setOpenServiceForm] = useState(false);
  const [orderItem, setOrderItem] = useState(undefined);

  const clients = useSelector((state) => state.clients.clients);
  const order = useSelector((state) => state.orders.order);
  const dialog = useSelector((state) => state.system.dialog);

  const handle = (event, type, required, size) => {
    const { name, value } = event.target;

    if (type === 'select') {
      setValues({
        ...values,
        [name]: Number(value)
      });
    }
    else if (type === 'number') {
      setValues({
        ...values,
        [name]: value.replace(/\D/g, '')
      });
    }
    else if (type === 'plate') {
      setValues({
        ...values,
        [name]: Functions.MaskPlate(value)
      });
    }
    else {
      setValues({
        ...values,
        [name]: value
      });
    }
    if (invalids[name]) {
      validateField(name, value, type, required, size);
    }
  };

  const handleCurrency = (event) => {
    const { name, value } = event.target;

    let total = '';
    if (name === 'productsDiscount') {
      total = String(
        (Number(values.productsSubtotal.replace(/\D/g, '')) -
          Number(value.replace(/\D/g, ''))) +
        (Number(values.servicesSubtotal.replace(/\D/g, '')) -
          Number(values.servicesDiscount.replace(/\D/g, ''))));

      setValues({
        ...values,
        productsDiscount: Functions.MaskCurrency(value),
        total: Functions.MaskCurrency(total),
      });
    }
    else {
      total = String(
        (Number(values.productsSubtotal.replace(/\D/g, '')) -
          Number(values.productsDiscount.replace(/\D/g, ''))) +
        (Number(values.servicesSubtotal.replace(/\D/g, '')) -
          Number(value.replace(/\D/g, ''))));

      setValues({
        ...values,
        servicesDiscount: Functions.MaskCurrency(value),
        total: Functions.MaskCurrency(total),
      });
    }
  }

  const handleSearch = (name, value, required) => {
    setValues({ ...values, [name]: value });
    if (invalids[name]) {
      validateField(name, value, 'search', required);
    }
  }

  const handleDate = (value, name, required) => {
    setValues({
      ...values,
      [name]: value
    });
    if (invalids[name]) {
      validateField(name, value, 'date', required);
    }
  }

  const validateField = (name, value, type, required, size) => {
    let valueReturn = Validates.Field(value, type, required, size);

    setInvalids({
      ...invalids,
      [name]: valueReturn.invalid
    });
    setFeedBacks({
      ...feedBacks,
      [name]: valueReturn.feedBack
    });

  };

  const saveAndClose = () => {
    dispatch(OpenDialog({
      title: 'Confirmação',
      message: 'Pedido fechados não podem ser alterados futuramente. Deseja mesmo fechar esse pedido?',
      label: 'Sim',
      origin: 'closeOrder'
    }));
  }

  const save = (close) => {
    console.log(values);
    if (Validates.Order(values, invalids)) {
      setFeedBacks({
        ...feedBacks,
        form: true
      });
      Functions.ErrorString('Preenchimento incorreto, por favor verifique os campos em destaque.');
    }
    else {
      let data = {}

      if (!values.edit) {
        data = {
          ...values,
          date: values.date.toISOString(),
          client: {
            _id: values.client._id,
            name: values.client.name,
            phone: values.client.phones[0].number
          }
        };
        dispatch(AddOrder(data, apiReturn));
      }
      else {
        data = {
          ...values,
          date: values.date.toISOString(),
          client: {
            _id: values.client._id,
            name: values.client.name,
            phone: values.client.phone
          },
          productsSubtotal: Functions.APICurrency(values.productsSubtotal),
          productsDiscount: Functions.APICurrency(values.productsDiscount),
          servicesSubtotal: Functions.APICurrency(values.servicesSubtotal),
          servicesDiscount: Functions.APICurrency(values.servicesDiscount),
          total: Functions.APICurrency(values.total),
          closed: close,
        };
        dispatch(UpdateOrder(data, apiReturn))
      }
    }
  }

  const apiReturn = (response, error) => {
    if (response) {
      Functions.SuccessString(`Pedido ${values.edit ? 'editado' : 'cadastrado'} com sucesso.`);
      if (!values.edit) {
        navigate(`/orders/edit/${response._id}`);
      }
      else if (response.closed === 1) {
        navigate(`/orders/view`);
      }
    }
    else {
      return Functions.ErrorString('NETWORK ERROR');
    }
  }

  const deleteReturn = (response, error) => {
    if (response) {
      Functions.SuccessString(`${response.type} removido com sucesso.`);
    }
    else {
      return Functions.ErrorString('NETWORK ERROR');
    }
  }

  const backList = () => {
    return navigate('/orders');
  }

  const editItem = (item) => {
    setOrderItem(item);
  }

  const deleteItem = (item) => {
    item.type === 'service' ?
      dispatch(DeleteServiceOrder(item, deleteReturn)) :
      dispatch(DeleteProductOrder(item, deleteReturn));
  }

  useEffect(() => {
    if (dialog.open === false && dialog.return.status === true && dialog.return.origin === 'closeOrder') {
      save(1);
    }

    // eslint-disable-next-line
  }, [dialog]);

  useEffect(() => {
    if (!openServiceForm) {
      setOrderItem(undefined);
    }

    // eslint-disable-next-line
  }, [openServiceForm]);

  useEffect(() => {
    if (!openProductForm) {
      setOrderItem(undefined);
    }

    // eslint-disable-next-line
  }, [openProductForm]);

  useEffect(() => {
    if (orderItem !== undefined) {
      orderItem.type === 'service' ?
        setOpenServiceForm(true) :
        setOpenProductForm(true);
    }

    // eslint-disable-next-line
  }, [orderItem]);

  useEffect(() => {
    if (order && order._id === values._id) {
      const listTemp = [];
      let productsSubtotal = 0;
      let servicesSubtotal = 0;
      order.services.map((item) => {
        servicesSubtotal = servicesSubtotal + item.total
        return listTemp.push({ ...item, type: 'service' });
      });
      order.products.map((item) => {
        productsSubtotal = productsSubtotal + item.total
        return listTemp.push({ ...item, type: 'product' });
      });
      setList(listTemp);
      setValues({
        ...values,
        ...order,
        date: new Date(order.date),
        products: order.products,
        services: order.services,
        productsSubtotal: Functions.ShowCurrency(productsSubtotal),
        productsDiscount: values.productsDiscount !== 0 ?
          values.productsDiscount : Functions.ShowCurrency(order.productsDiscount),
        servicesSubtotal: Functions.ShowCurrency(servicesSubtotal),
        servicesDiscount: values.servicesDiscount !== 0 ?
          values.servicesDiscount : Functions.ShowCurrency(order.servicesDiscount),
        total: Functions.ShowCurrency(order.total),
        edit: true
      });
    }

    // eslint-disable-next-line
  }, [order]);

  useEffect(() => {
    if (values.productsSubtotal && values.productsSubtotal) {
      let product = Number(values.productsSubtotal.replace(/\D/g, ''));
      let service = Number(values.servicesSubtotal.replace(/\D/g, ''));

      product = product - Number(values.productsDiscount.replace(/\D/g, ''));
      service = service - Number(values.servicesDiscount.replace(/\D/g, ''));

      const total = String((product + service));

      setValues({
        ...values,
        total: Functions.MaskCurrency(total),
      });
    }

    // eslint-disable-next-line
  }, [values.productsSubtotal, values.servicesSubtotal]);

  useEffect(() => {
    if (_id && _id !== values._id) {
      setValues({
        ...values,
        _id: _id
      })
      dispatch(FindOrder(_id));
      document.title = `SIGEP - Editar pedido`;
    }
    else {
      setValues({
        ...InitialValues,
        client: null,
        date: new Date(),
        products: [],
        services: []
      });
      document.title = `SIGEP - Adicionar pedido`;
    }

    // eslint-disable-next-line
  }, [_id]);

  useEffect(() => {
    if (clients.length === 0) dispatch(ListClients());

    // eslint-disable-next-line
  }, []);

  return (
    <Grid container className='body-main form' spacing={3}>
      {values.closed === 1 && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography
            component='h3'
            color='red'
          >
            Pedido fechados não podem ser editados
          </Typography>
          <Link href='/orders/view'>Voltar para lista</Link>
        </Grid>
      )}
      {values.edit && (
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Input
            id='number'
            label='Numero'
            name='number'
            type='number'
            value={values.number}
            required
            disabled={values.edit}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={values.edit ? 4 : 5} lg={values.edit ? 4 : 5}>
        <Input
          id='date'
          label='Data'
          name='date'
          value={values.date}
          required
          model='dateTime'
          time={true}
          showTodayButton={true}
          minDate={new Date()}
          onChange={(event) => handleDate(event, 'date', true)}
          onBlur={() => validateField('date', values.date, 'date', true)}
          error={invalids.date || (values.date === '' && feedBacks.form !== undefined)}
          helperText={feedBacks.date || feedBacks.form}
          disabled={values.edit}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={values.edit ? 6 : 7} lg={values.edit ? 6 : 7}>
        <Input
          id='client'
          label='Cliente'
          name='client'
          value={values.client}
          model='search'
          list={clients}
          required
          autoFocus
          searchKey='name'
          onChange={(event, value) => handleSearch('client', value, true)}
          onBlur={() => validateField('client', values.client, 'search', true)}
          error={invalids.client || (values.client === null && feedBacks.form !== undefined)}
          helperText={feedBacks.client || feedBacks.form}
          disabled={values.edit}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <OutlinedDiv label='Veículo' >
          <Grid container className='address-form' spacing={3}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Input
                id='vehicle'
                label='Modelo'
                name='vehicle'
                value={values.vehicle}
                onChange={(event) => handle(event, 'text', false, 3)}
                onBlur={() => validateField('vehicle', values.vehicle, 'text', false, 3)}
                error={invalids.vehicle}
                helperText={feedBacks.vehicle}
                disabled={values.closed}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Input
                id='vehicleColor'
                label='Cor'
                name='vehicleColor'
                value={values.vehicleColor}
                onChange={(event) => handle(event, 'text', false, 3)}
                onBlur={() => validateField('vehicleColor', values.vehicleColor, 'text', false, 3)}
                error={invalids.vehicleColor}
                helperText={feedBacks.vehicleColor}
                disabled={values.closed}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Input
                id='vehicleNumber'
                label='Frota'
                name='vehicleNumber'
                value={values.vehicleNumber}
                onChange={(event) => handle(event, 'text', false, 3)}
                onBlur={() => validateField('vehicleNumber', values.vehicleNumber, 'text', false, 3)}
                error={invalids.vehicleNumber}
                helperText={feedBacks.vehicleNumber}
                disabled={values.closed}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Input
                id='vehiclePlate'
                label='Placa'
                name='vehiclePlate'
                value={values.vehiclePlate}
                onChange={(event) => handle(event, 'plate', false)}
                onBlur={() => validateField('vehiclePlate', values.vehiclePlate, 'plate', false)}
                error={invalids.vehiclePlate}
                helperText={feedBacks.vehiclePlate}
                disabled={values.closed}
                maxLength="8"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Input
                id='vehicleKM'
                label='KM'
                name='vehicleKM'
                value={values.vehicleKM}
                onChange={(event) => handle(event, 'number', false)}
                onBlur={() => validateField('vehicleKM', values.vehicleKM, 'number', false)}
                error={invalids.vehicleKM}
                helperText={feedBacks.vehicleKM}
                disabled={values.closed}
              />
            </Grid>
          </Grid>
        </OutlinedDiv>
      </Grid>
      {values.edit && (
        <Fragment>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Input
              id='productsSubtotal'
              label='Subtotal de produtos'
              name='productsSubtotal'
              model='currency'
              value={values.productsSubtotal}
              required
              readOnly={true}
              disabled={values.closed}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Input
              id='productsDiscount'
              label='Desc. de produtos'
              name='productsDiscount'
              model='currency'
              value={values.productsDiscount}
              onChange={handleCurrency}
              onBlur={() => validateField('productsDiscount', values.productsDiscount, 'currency', true)}
              required
              error={invalids.productsDiscount || (values.productsDiscount === '' && feedBacks.form !== undefined)}
              helperText={feedBacks.productsDiscount || feedBacks.form}
              disabled={values.closed}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Input
              id='servicesSubtotal'
              label='Subtotal de serviços'
              name='servicesSubtotal'
              model='currency'
              value={values.servicesSubtotal}
              required
              readOnly={true}
              disabled={values.closed}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Input
              id='servicesDiscount'
              label='Desc. de serviços'
              name='servicesDiscount'
              model='currency'
              value={values.servicesDiscount}
              onChange={handleCurrency}
              onBlur={() => validateField('servicesDiscount', values.servicesDiscount, 'currency', true)}
              required
              error={invalids.servicesDiscount || (values.servicesDiscount === '' && feedBacks.form !== undefined)}
              helperText={feedBacks.servicesDiscount || feedBacks.form}
              disabled={values.closed}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Input
              id='total'
              label='Total'
              name='total'
              model='currency'
              value={values.total}
              required
              readOnly={true}
              disabled={values.closed}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Input
              id='observations'
              label='Observações'
              name='observations'
              value={values.observations}
              onChange={(event) => handle(event, 'text', false)}
              disabled={values.closed}
            />
          </Grid>
        </Fragment>
      )}
      {values.closed === 0 && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ButtonsForm
            cancel={backList}
            save={() => save(0)}
            addProduct={values.edit && setOpenProductForm}
            addService={values.edit && setOpenServiceForm}
            close={values.edit && saveAndClose}
          />
        </Grid>
      )}
      {(values.edit && list.length > 0) && (
        <Fragment>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              component='h3'
              color='white'
            >
              Itens do pedido:
            </Typography>
          </Grid>
          <DataTable
            columns={columns}
            noSelect allRows
            rows={list}
            editAction={editItem}
            deleteAction={deleteItem}
            disabled={values.closed === 1}
          />
        </Fragment>
      )}
      <ProductForm
        open={openProductForm}
        toggle={() => setOpenProductForm(!openProductForm)}
        orderId={values._id}
        product={orderItem}
      />
      <ServiceForm
        open={openServiceForm}
        toggle={() => setOpenServiceForm(!openServiceForm)}
        orderId={values._id}
        service={orderItem}
      />
    </Grid>
  );
}

export default Add;