import React, { useState } from 'react';

import{
  Popover,
  Typography
} from '@mui/material';
import Functions from '../../utils/Functions';
const Footer = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const date = new Date();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className='footer'>
      <smalll
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {process.env.REACT_APP_VERSION}
      </smalll>
      <small> Developed by
        <a href='https://www.jmcreative.com.br' rel='noopener noreferrer' target='_blank'>
          <b> JM Creative</b>
        </a> © 2007 - {date.getFullYear()}
      </small>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{Functions.GetReleaseDate(process.env.REACT_APP_V_DATE)}</Typography>
      </Popover>
    </div>
  );
}

export default Footer;