import { list, find, add, del, update, activate } from './api';
import { SetLoading, RemoveLoading } from '../System';

import Functions from '../../../utils/Functions';

export const FIND_CLIENT = 'FIND_CLIENT';
export const LIST_CLIENTS = 'LIST_CLIENTS';
export const CLEAR_CLIENTS = 'CLEAR_CLIENTS';

export const ListClients = (data) => {
  return (dispatch) => {
    dispatch(SetLoading('ListClients'));
    return list(data)
      .then((response) => {
        dispatch({
          type: LIST_CLIENTS,
          payload: response.data,
        });
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('ListClients')));
  };
};

export const FindClient = (data) => {
  return (dispatch) => {
    dispatch(SetLoading('FindClient'));
    return find(data)
      .then((response) => {
        dispatch({
          type: FIND_CLIENT,
          payload: response.data,
        });
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('FindClient')));
  };
};

export const AddClient = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('AddClient'));
    return add(data)
      .then((response) => call(response.data))
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('AddClient')));
  };
};

export const DeleteClient = (_id, call) => {
  return (dispatch) => {
    dispatch(SetLoading('DeleteClient'));
    return del(_id)
      .then((response) => call(response.data))
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('DeleteClient')));
  };
};

export const UpdateClient = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('UpdateClient'));
    return update(data)
      .then((response) => call(response.data))
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('UpdateClient')));
  };
};

export const ActivateClient = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('ActivateClient'));
    return activate(data)
      .then(call)
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('ActivateClient')));
  };
};

export const ClearClients = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_CLIENTS
    })
  }
};
