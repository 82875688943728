import { model } from '../../../services';

export const list = (data) => {
  if(data){
    return model.post(`/clients`, data);
  }
  else{
    return model.get(`/clients`);
  }
};
export const add = (data) => model.post(`/client`, data);
export const find = (_id) => model.get(`/client/${_id}`);
export const del = (_id) => model.delete(`/client/${_id}`);
export const update = (data) => model.put(`/client`, data);
export const activate = (data) => model.put(`/client/activate`, data);
