import React from 'react';
import { DatePicker } from '@j-meira/mui-theme';

const DateTime = ({ helperText, error, time, value, onChange, disabled, ...params }) => {
    return (      
      <DatePicker
        isNoFormik
        noGrid
        time={time}
        value={value}
        onChange={onChange}
        disabled={disabled}
        helperText={error ? helperText : undefined}
        {...params}
      />      
    );
}

export default DateTime;