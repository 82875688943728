import React, { Fragment, useState } from 'react';

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse
} from '@mui/material';

import { MdExpandLess, MdExpandMore } from 'react-icons/md';

const SideBarItem = ({ label, icon, selected, action, initialState, secondary, children }) => {
  const [open, setOpen] = useState(initialState ? initialState : false);

  if (children) {
    return (
      <Fragment>
        <ListItem
          button
          selected={selected}
          onClick={() => setOpen(!open)}
        >
          {icon && (
            <ListItemIcon>
              {icon}
            </ListItemIcon>
          )}
          <ListItemText primary={label} />
          {open ? <MdExpandLess /> : <MdExpandMore />}
        </ListItem>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {children}
          </List>
        </Collapse>
      </Fragment>
    );
  }
  else {
    return (
      <ListItem
        button selected={selected}
        onClick={action}
        className={secondary ? selected ? 'secondary-selected' : 'secondary' : ''}
      >
        {icon && (
          <ListItemIcon>
            {icon}
          </ListItemIcon>
        )}
        <ListItemText primary={label} />
      </ListItem>
    );
  }
}

export default SideBarItem;