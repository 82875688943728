import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { Grid } from '@mui/material';

import Input from '../../../components/Input';
import ButtonsForm from '../../../components/ButtonsForm';
import OutlinedDiv from '../../../components/OutlinedDiv';

import { AddProvider, UpdateProvider } from '../../../redux/actions/Providers';

import Functions from '../../../utils/Functions';
import Validates from '../../../utils/Validates';

const InitialValues = {
  edit: false,
  _id: undefined,
  name: '',
  document: '',
  salesManName: '',
  salesManPhone: '',
  salesManEmail: '',
  companyName: '',
  stateRegistry: '',
};

const InitialInvalids = {
  name: false,
  document: false,
  salesManName: false,
  salesManPhone: false,
  salesManEmail: false,
  companyName: false,
  stateRegistry: false,
};

const InitialFeedBacks = {
  form: undefined
}

const Add = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [values, setValues] = useState(InitialValues);
  const [invalids, setInvalids] = useState(InitialInvalids);
  const [feedBacks, setFeedBacks] = useState(InitialFeedBacks);
  const [dates, setDates] = useState(undefined);

  const handle = (event, type, required, size, documentType) => {
    const { name, value } = event.target;

    if (type === 'document') {
      setValues({
        ...values,
        [name]: Functions.MaskCNPJ(value)
      });
    }
    else if (type === 'phone') {
      setValues({
        ...values,
        [name]: Functions.MaskPhone(value)
      });
    }
    else {
      setValues({
        ...values,
        [name]: value
      });
    }
    if (invalids[name]) {
      validateField(name, value, type, required, size, documentType);
    }
  };

  const validateField = (name, value, type, required, size, documentType) => {
    let valueReturn = Validates.Field(value, type, required, size, documentType);

    setInvalids({
      ...invalids,
      [name]: valueReturn.invalid
    });
    setFeedBacks({
      ...feedBacks,
      [name]: valueReturn.feedBack
    });

  };

  const save = () => {
    console.log(values);
    if (Validates.Provider(values, invalids)) {
      //erro
      setFeedBacks({
        ...feedBacks,
        form: 'Preenchimento Obrigatório.'
      });
      Functions.ErrorString('Preenchimento incorreto, por favor verifique os campos em destaque.');
    }
    else {
      const data = {
        _id: values._id,
        name: values.name,
        salesMan: {
          name: values.salesManName,
          phone: values.salesManPhone.replace(/\D/g, ''),
          email: values.salesManEmail
        },
        document: values.document.replace(/\D/g, ''),
        companyName: values.companyName,
        stateRegistry: values.stateRegistry
      };

      values.edit ?
        dispatch(UpdateProvider(data, apiReturn)) :
        dispatch(AddProvider(data, apiReturn));
    }

  }

  const apiReturn = (response, error) => {
    if (response) {
      Functions.SuccessString(`Fornecedor ${values.edit ? 'editado' : 'cadastrado'} com sucesso.`);
      return backList();
    }
    else {
      return Functions.ErrorString('NETWORK ERROR');
    }
  }

  const backList = () => {
    return navigate('/registrations/providers');
  }

  useEffect(() => {
    const provider = location.state && location.state.provider ? location.state.provider : null;

    if (provider) {
      console.log(provider);

      setDates({
        createdAt: Functions.ShowDateTime(provider.createdAt),
        updateAt: Functions.ShowDateTime(provider.updateAt),
        createdBy: provider.createdBy,
        updateBy: provider.updateBy
      });
      setValues({
        ...values,
        ...provider,
        salesManName: provider.salesMan.name ?provider.salesMan.name : '',
        salesManPhone: provider.salesMan.phone ? Functions.MaskPhone(provider.salesMan.phone) : '',
        salesManEmail: provider.salesMan.email ? provider.salesMan.email : '',
        edit: true
      });
      document.title = `SIGEP - Editar fornecedor`;
      
    }
    else if (location.pathname === '/registrations/providers/edit') {
      backList();
    }
    else{
      document.title = 'SIGEP - Adicionar fornecedor';
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Grid container className='body-main form' spacing={3}>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Input
          id='name'
          label='Nome'
          name='name'
          value={values.name}
          autoFocus
          required
          onChange={(event) => handle(event, 'text', true, 3)}
          onBlur={() => validateField('name', values.name, 'text', true, 3)}
          error={invalids.name || (values.name === '' && feedBacks.form !== undefined)}
          helperText={feedBacks.name || feedBacks.form}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Input
          id='document'
          label='CNPJ'
          name='document'
          value={values.document}
          required
          onChange={(event) => handle(event, 'document', true)}
          onBlur={() => validateField('document', values.document, 'document', true)}
          error={invalids.document || (values.document === '' && feedBacks.form !== undefined)}
          helperText={feedBacks.document || feedBacks.form}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Input
          id='companyName'
          label='Razão Social'
          name='companyName'
          value={values.companyName}
          required
          onChange={(event) => handle(event, 'text', true, 5)}
          onBlur={() => validateField('companyName', values.companyName, 'text', true, 5)}
          error={invalids.companyName || (values.companyName === '' && feedBacks.form !== undefined)}
          helperText={feedBacks.companyName || feedBacks.form}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Input
          id='stateRegistry'
          label='Inscrisão Estadual'
          name='stateRegistry'
          value={values.stateRegistry}
          required
          onChange={(event) => handle(event, 'number', true)}
          onBlur={() => validateField('stateRegistry', values.stateRegistry, 'number', true)}
          error={invalids.stateRegistry || (values.stateRegistry === '' && feedBacks.form !== undefined)}
          helperText={feedBacks.stateRegistry || feedBacks.form}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <OutlinedDiv label='Vendedor' required >
          <Grid container className='address-form' spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Input
              id='salesManName'
              label='Nome'
              name='salesManName'
              value={values.salesManName}
              onChange={(event) => handle(event, 'text', false, 3)}
              onBlur={() => validateField('salesManName', values.salesManName, 'text', false, 3)}
              error={invalids.salesManName}
              helperText={feedBacks.salesManName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              id='salesManPhone'
              label='Telefone'
              name='salesManPhone'
              value={values.salesManPhone}
              onChange={(event) => handle(event, 'phone', false)}
              onBlur={() => validateField('salesManPhone', values.salesManPhone, 'phone', false)}
              error={invalids.salesManPhone}
              helperText={feedBacks.salesManPhone}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              id='salesManEmail'
              label='Email'
              name='salesManEmail'
              value={values.salesManEmail}
              onChange={(event) => handle(event, 'email', false)}
              onBlur={() => validateField('salesManEmail', values.salesManEmail, 'email', false)}
              error={invalids.salesManEmail}
              helperText={feedBacks.salesManEmail}
            />
          </Grid>
          </Grid>
        </OutlinedDiv>
      </Grid>


      <Grid item xs={12} sm={12} md={12} lg={12}>
        <ButtonsForm cancel={backList} save={save} dates={dates} />
      </Grid>
    </Grid>
  );
}

export default Add;