import { model } from '../../../services';

export const list = (data) => {
  if(data){
    return model.post(`/products`, data);
  }
  else{
    return model.get(`/products`);
  }
};

export const add = (data) => model.post(`/product`, data);

export const find = (_id) => model.get(`/product/${_id}`);

export const del = (_id) => model.delete(`/product/${_id}`);

export const update = (data) => model.put(`/product`, data);

export const activate = (data) => model.put(`/product/activate`, data);

export const printTag = (data) => model.put(`/product/tag`, data);

export const getTags = () => model.get(`/products/print-tags`, {
  responseType: 'arraybuffer',
  headers: {
      Accept: 'application/pdf',
  },
});

export const getBalance = () => model.get(`/products/print-balance`, {
  responseType: 'arraybuffer',
  headers: {
      Accept: 'application/pdf',
  },
});

// export const getTags = () => model.get(`/products/print-tags`,
// {
//   responseType: 'blob'
// });