import Functions from '../../../utils/Functions';
import { GET_STATES, GET_CITIES } from '../../actions/Locations';

const global = {
  states: [],
  cities: []
}

export default function LocationsReducer(state = global, action) {
  const valueReturn = action.payload;

  switch (action.type) {
    case GET_STATES:
      return { ...state, states: Functions.ArrangeAPIList(valueReturn) }
    case GET_CITIES:
      return { ...state, cities: Functions.ArrangeAPIList(valueReturn) }
    default:
      return state;
  }
}