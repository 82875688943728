import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { Grid } from '@mui/material';

import Input from '../../../components/Input';
import SimilarList from '../../../components/SimilarList';
import ButtonsForm from '../../../components/ButtonsForm';

import { AddProduct, UpdateProduct } from '../../../redux/actions/Products';

import { UnitysEnum } from '../../../utils/Enums';
import Functions from '../../../utils/Functions';
import Validates from '../../../utils/Validates';

const InitialSimilar = {
  _id: ''
};

const InitialValues = {
  _id: '',
  description: '',
  brand: '',
  similar: [{ ...InitialSimilar }],
  unity: 'PC',
  stock: '',
  stockAlert: '',
  costPrice: '',
  salePrice: '',
  ncm: '',
  edit: false
};

const InitialInvalids = {
  edit: false,
  _id: false,
  description: false,
  brand: false,
  unity: false,
  costPrice: false,
  salePrice: false,
  stock: false,
  stockAlert: false,
  ncm: false
};

const Add = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [values, setValues] = useState(InitialValues);
  const [invalids, setInvalids] = useState(InitialInvalids);
  const [feedBacks, setFeedBacks] = useState({});
  const [dates, setDates] = useState(undefined);

  const handle = (event, type, required, size) => {
    const { name, value } = event.target;

    if (type === 'currency') {
      if (name === 'costPrice') {
        let sale = String(Number(value.replace(/\D/g, '')) * 2);
        setValues({
          ...values,
          costPrice: Functions.MaskCurrency(value),
          salePrice: Functions.MaskCurrency(sale),
        });
      }
      else {
        setValues({
          ...values,
          [name]: Functions.MaskCurrency(value)
        });
      }
    }
    else if (type === 'text') {
      setValues({
        ...values,
        [name]: value.toUpperCase()
      });
    }
    else if (type === 'select') {
      setValues({
        ...values,
        [name]: Number(value)
      });
    }
    else {
      setValues({
        ...values,
        [name]: value
      });
    }
    if (invalids[name]) {
      validateField(name, value, type, required, size);
    }
  };

  const validateField = (name, value, type, required, size) => {
    let valueReturn = Validates.Field(value, type, required, size);

    setInvalids({
      ...invalids,
      [name]: valueReturn.invalid
    });
    setFeedBacks({
      ...feedBacks,
      [name]: valueReturn.feedBack
    });

  };

  const setSimilar = (list) => {
    setValues({
      ...values,
      similar: list
    });
  }

  const save = () => {
    console.log(values);
    if (Validates.Product(values, invalids)) {
      //erro
      setFeedBacks({
        ...feedBacks,
        form: 'Preenchimento Obrigatório.'
      });
      Functions.ErrorString('Preenchimento incorreto, por favor verifique os campos em destaque.');
    }
    else {
      const data = {
        ...values,
        costPrice: Functions.APICurrency(values.costPrice),
        salePrice: Functions.APICurrency(values.salePrice)
      }
      values.edit ?
        dispatch(UpdateProduct(data, apiReturn)) :
        dispatch(AddProduct(data, apiReturn));;
    }

  }

  const apiReturn = (response, error) => {
    if (response) {
      Functions.SuccessString(`Produto '${response._id}' ${values.edit ? 'editado' : 'cadastrado'} com sucesso.`);
      return backList();
    }
    else {
      if (error.status && error.status === 400) {
        return Functions.ErrorString('Já existe um produto registrado com esse código.')
      }
      else {
        return Functions.ErrorString('NETWORK ERROR');
      }
    }
  }

  const backList = () => {
    return navigate('/registrations/products');
  }

  useEffect(() => {
    const product = location.state && location.state.product ? location.state.product : null;

    if (product) {
      setValues({
        ...values,
        ...product,
        costPrice: Functions.ShowCurrency(product.costPrice),
        salePrice: Functions.ShowCurrency(product.salePrice),
        edit: true
      });
      setDates({
        createdAt: Functions.ShowDateTime(product.createdAt),
        createdBy: product.createdBy,
        updateAt: Functions.ShowDateTime(product.updateAt),
        updateBy: product.updateBy
      });
      document.title = `SIGEP - Editar produto`;
    }
    else if (location.pathname === '/registrations/products/edit') {
      backList();
    }
    else {
      setValues({
        ...InitialValues,
        similar: [{ ...InitialSimilar }],
      });
      document.title = 'SIGEP - Adicionar produto';
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Grid container className='body-main form' spacing={3}>
      <Grid item xs={12} sm={12} md={4} lg={3}>
        <Input
          id='_id'
          label='Código'
          name='_id'
          value={values._id}
          autoFocus
          required
          onChange={(event) => handle(event, 'text', true, 3)}
          onBlur={() => validateField('_id', values._id, 'text', true, 3)}
          error={invalids._id || (values._id === '' && feedBacks.form !== undefined)}
          helperText={feedBacks._id || feedBacks.form}
          disabled={values.edit}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={3}>
        <Input
          id='brand'
          label='Marca'
          name='brand'
          value={values.brand}
          onChange={(event) => handle(event, 'text', true, 3)}
          onBlur={() => validateField('brand', values.brand, 'text', true, 3)}
          required
          error={invalids.brand || (values.brand === '' && feedBacks.form !== undefined)}
          helperText={feedBacks.brand || feedBacks.form}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Input
          id='description'
          label='Descrição'
          name='description'
          value={values.description}
          required
          onChange={(event) => handle(event, 'text', true, 5)}
          onBlur={() => validateField('description', values.description, 'text', true, 5)}
          error={invalids.description || (values.description === '' && feedBacks.form !== undefined)}
          helperText={feedBacks.description || feedBacks.form}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <SimilarList
          list={values.similar}
          setList={setSimilar}
          edit={values.edit}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Grid container spacing={3} className='quantity-form'>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Input
              id='unity'
              label='Unidade'
              name='unity'
              model='select'
              list={UnitysEnum}
              defaultValue='Selecione'
              value={values.unity}
              onChange={(event) => handle(event, 'select', true)}
              onBlur={() => validateField('unity', values.unity, 'select', true)}
              required
              error={invalids.unity || (values.unity === '-1' && feedBacks.form !== undefined)}
              helperText={feedBacks.unity || feedBacks.form}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Input
              id='stock'
              label='Estoque'
              name='stock'
              type='number'
              value={values.stock}
              onChange={(event) => handle(event, 'number', true)}
              onBlur={() => validateField('stock', values.stock, 'number', true)}
              required
              error={invalids.stock || (values.stock === '' && feedBacks.form !== undefined)}
              helperText={feedBacks.stock || feedBacks.form}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Input
              id='stockAlert'
              label='Alerta'
              name='stockAlert'
              type='number'
              value={values.stockAlert}
              onChange={(event) => handle(event, 'number', true)}
              onBlur={() => validateField('stockAlert', values.stockAlert, 'number', true)}
              required
              error={invalids.stockAlert || (values.stockAlert === '' && feedBacks.form !== undefined)}
              helperText={feedBacks.stockAlert || feedBacks.form}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Input
              id='costPrice'
              label='Preço de custo'
              name='costPrice'
              model='currency'
              value={values.costPrice}
              onChange={(event) => handle(event, 'currency', true)}
              onBlur={() => validateField('costPrice', values.costPrice, 'currency', true)}
              required
              error={invalids.costPrice || (values.costPrice === '' && feedBacks.form !== undefined)}
              helperText={feedBacks.costPrice || feedBacks.form}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Input
              id='salePrice'
              label='Preço de venda'
              name='salePrice'
              model='currency'
              value={values.salePrice}
              onChange={(event) => handle(event, 'currency', true)}
              onBlur={() => validateField('salePrice', values.salePrice, 'currency', true)}
              required
              error={invalids.salePrice || (values.salePrice === '' && feedBacks.form !== undefined)}
              helperText={feedBacks.salePrice || feedBacks.form}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Input
              id='ncm'
              label='NCM'
              name='ncm'
              type='number'
              value={values.ncm}
              onChange={(event) => handle(event, 'number', true)}
              onBlur={() => validateField('ncm', values.ncm, 'number', true)}
              required
              error={invalids.ncm || (values.ncm === '' && feedBacks.form !== undefined)}
              helperText={feedBacks.ncm || feedBacks.form}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <ButtonsForm cancel={backList} save={save} dates={dates} />
      </Grid>
    </Grid>
  );
}

export default Add;