import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { OpenDialog } from '../../redux/actions/System';
import { Verify } from '../../redux/actions/Users';

const VerifyUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, requestPass } = useParams();
  const dialog = useSelector((state) => state.system.dialog);

  const goToLogin = () => {
    navigate('/login');
  }

  const verifyReturn = (response) => {
    dispatch(OpenDialog({
      title: 'Sucesso',
      message: 'Email verificado com sucesso, seu acesso ao sistema foi liberado.',
      label: 'Ir para login',
      origin: 'noToken',
      cancel: false,
    }));
  }

  const verifyError = (error) => {
    dispatch(OpenDialog({
      title: 'Erro',
      message: 'Erro na validação do token, por favor tente novamente mais tarde.',
      label: 'Ir para login',
      origin: 'noToken',
      cancel: false,
    }));
  }

  useEffect(() => {
    if (dialog.open === false && dialog.return.status !== '') {
      if (dialog.return.origin === 'noToken' ||
        (dialog.return.origin === 'newPassword' &&
          dialog.return.status === false)) {
        goToLogin();
      }
      else if (dialog.return.origin === 'newPassword') {
        const data = {
          password: dialog.return.status.value
        };
        dispatch(Verify(token, data, verifyReturn, verifyError));
      }
    }


    // eslint-disable-next-line
  }, [dialog]);

  useEffect(() => {
    const openRequest = requestPass ? JSON.parse(requestPass) : undefined;
    if (openRequest && token) {
      console.log(openRequest);
      dispatch(OpenDialog({
        title: 'Cadastrar nova senha',
        message: 'Por favor informe uma nova senha e confime a mesma nos campos abaixo. Sua senha deve ter um mínimo 6 caracteres e conter pelo menos uma letra maiúscula, uma letra minúscula, um numero e um caractere especial',
        label: 'Enviar',
        origin: 'newPassword',
        placeholder: 'Nova senha',
        type: 'password',
      }));
    }
    else if (token) {
      dispatch(Verify(token, undefined, verifyReturn, verifyError));
    }
    else{
      goToLogin();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
    </div>
  );
}

export default VerifyUser;