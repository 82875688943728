import React, { useEffect } from 'react';

import { Grid } from '@mui/material';

const Drashboard = () => {

  useEffect(() => {
    document.title = `SIGEP - Drashboard`;

    // eslint-disable-next-line
  }, []);

  return (
    <Grid container className='body-main' spacing={3}>
    </Grid>
  );
};

export default Drashboard;
