import moment from 'moment';

const Validates = {
  //froms
  Client: (values, invalids) => {
    if ((values.name === '' || values.type === -1 || values.document === '') ||
      (invalids.name || invalids.type || invalids.document)) {
      console.log('start');
      return true;
    }
    else {
      let valueReturn = [];
      values.phones.map((phone, index) => valueReturn.push(phone.number === '' || invalids.phones[index].number));
      values.emails.map((email, index) => valueReturn.push(email.address === '' || invalids.emails[index].address));
      values.addresses.map((address, index) => {
        let invalid = invalids.addresses[index];
        if ((address.post_code === '' || address.state === -1 || address.city === -1 ||
          address.road === '' || address.sub_urb === '' || address.number === null ||
          address.number === '') || (invalid.post_code || invalid.state || invalid.city ||
            invalid.road || invalid.sub_urb || invalid.number)) {
          return valueReturn.push(true);
        }
        else {
          return valueReturn.push(false);
        }
      });
      console.log(valueReturn);
      if (valueReturn.some((element) => (element))) {
        console.log('lists');
        return true;
      }
      else if (values.type === 1) {
        //pj
        if ((values.companyName === '' || values.stateRegistry === '') ||
          (invalids.companyName || invalids.stateRegistry)) {
          console.log('companyDocs');
          return true;
        }
        else {
          let valueReturnNew = [];
          invalids.contacts.map((invalid) => {
            if (invalid.name || invalid.phone || invalid.email) {
              return valueReturnNew.push(true);
            }
            else {
              return valueReturnNew.push(false);
            }
          });
          console.log(valueReturnNew);
          if (valueReturnNew.some((element) => (element))) {
            console.log('contacts');
            return true;
          }
          else {
            return false;
          }
        }
      }
      else {
        //pf
        if ((values.personalRegistry === '' || values.birthDate === '') ||
          (invalids.personalRegistry || invalids.birthDate)) {
          console.log('personalDocs');
          return true;
        }
        else {
          return false
        }
      }
    }
  },
  Product: (values, invalids) => {
    if ((values._id === '' || values.description === '' || values.brand === '' ||
      values.unity === -1 || values.stock === '' || values.stockAlert === '' ||
      values.costPrice === '' || values.salePrice === '' || values.ncm === '') || (
        invalids._id || invalids.description || invalids.brand || invalids.unity ||
        invalids.costPrice || invalids.salePrice || invalids.stock ||
        invalids.stockAlert || invalids.ncm)) {
      return true;
    }
    else {
      return false;
    }
  },
  Provider: (values, invalids) => {
    if ((values.name === '' || values.document === '' || values.companyName === '' ||
      values.stateRegistry === '') || (invalids.name || invalids.document ||
        invalids.salesManName || invalids.salesManPhone || invalids.salesManEmail ||
        invalids.companyName || invalids.stateRegistry)) {
      return true;
    }
    else {
      return false;
    }
  },
  User: (values, invalids) => {
    if ((values.name === '' || values.type === -1 || values.email === '') || (
      invalids.name || invalids.type || invalids.email)) {
      return true;
    }
    else {
      return false;
    }
  },
  Order: (values, invalids) => {
    if (values.edit) {
      if (invalids.productsDiscount || invalids.servicesDiscount || invalids.vehiclePlate ||
        invalids.vehicleNumber || invalids.vehicleColor || invalids.vehicle) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      if ((values.date === null || values.client === null) || (
        invalids.date || invalids.client || invalids.vehiclePlate ||
        invalids.vehicleNumber || invalids.vehicleColor || invalids.vehicle)) {
        return true;
      }
      else {
        return false;
      }

    }
  },
  OrderProduct: (values, invalids) => {
    if ((values._id === null || values.description === '' || values.quantity <= 0 ||
      values.unityPrice === '' || values.total === '') || (
        invalids._id || invalids.description || invalids.quantity ||
        invalids.unityPrice || invalids.total)) {
      return true;
    }
    else {
      return false;
    }
  },
  OrderService: (values, invalids) => {
    if ((values.description === '' || values.quantity <= 0 ||
      values.unityPrice === '' || values.total === '') || (
        invalids.description || invalids.quantity ||
        invalids.unityPrice || invalids.total)) {
      return true;
    }
    else {
      return false;
    }
  },
  Buy: (values, invalids) => {
    if (values.edit) {
      if ((values.subTotal === '' || values.total === '') || (
        invalids.subTotal || invalids.total)) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      if ((values.date === null || values.provider === null ||
        values.number === '' || values.subTotal === '' || values.total === '') || (
          invalids.date || invalids.provider || invalids.number ||
          invalids.subTotal || invalids.total)) {
        return true;
      }
      else {
        return false;
      }

    }
  },
  BuyProduct: (values, invalids) => {
    if ((values._id === null || values.description === '' || values.quantity <= 0 ||
      values.unityPrice === '' || values.total === '' || values.salePrice === '') || (
        invalids._id || invalids.description || invalids.quantity ||
        invalids.unityPrice || invalids.total || invalids.salePrice)) {
      return true;
    }
    else {
      return false;
    }
  },

  //inputs
  Field: (value, type, required, size, documentType) => {
    let valueReturn = {
      invalid: false,
      feedBack: undefined
    }

    if (required && (value && value.length === 0) && type !== 'select') {
      valueReturn = {
        invalid: true,
        feedBack: 'Preenchimento obrigatório.'
      }
    }
    else {
      switch (type) {
        case 'text':
          valueReturn = Validates.Text(value, size, required);
          break
        case 'select':
          if (Number(value) === -1 && required) {
            valueReturn = {
              invalid: true,
              feedBack: 'Seleção inválida.'
            }
          }
          break
        case 'post_code':
          valueReturn = Validates.PostCode(value, required);
          break
        case 'document':
          valueReturn = Validates.Document(value, documentType, required);
          break
        case 'phone':
          valueReturn = Validates.Phone(value, required);
          break
        case 'email':
          valueReturn = Validates.Email(value, required);
          break
        case 'date':
          valueReturn = Validates.Date(value, required);
          break
        case 'password':
          valueReturn = Validates.Password(value);
          break
        case 'simplePassword':
          valueReturn = Validates.SimplePassword(value);
          break
        case 'plate':
          valueReturn = Validates.Plate(value, required);
          break
        case 'search':
          if (value === null && required) {
            valueReturn = {
              invalid: true,
              feedBack: 'Seleção inválida.'
            }
          }
          break
        default:
          valueReturn = {
            invalid: false,
            feedBack: undefined
          }
          break;
      }
    }
    return valueReturn;
  },

  Plate: (plate, required) => {
    let valueReturn = {
      invalid: false,
      feedBack: undefined
    };

    if (plate === '' && required) {
      valueReturn = {
        invalid: true,
        feedBack: `Preenchimento obrigatório`
      };
    }
    else if (plate === '' && !required) {
      valueReturn = {
        invalid: false,
        feedBack: undefined
      };
    }
    else {
      plate = plate.replace(/[^A-Za-z0-9]+/g, '');

      const regexPlate = /^[a-zA-Z]{3}[0-9]{4}$/;
      const regexPlateMercosulCar = /^[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}[0-9]{2}$/;
      const regexPlateMercosulBike = /^[a-zA-Z]{3}[0-9]{2}[a-zA-Z]{1}[0-9]{1}$/;

      let plateTest = false;
      if (regexPlate.test(plate)) {
        plateTest = true;
      }
      if (regexPlateMercosulCar.test(plate)) {
        plateTest = true;
      }
      if (regexPlateMercosulBike.test(plate)) {
        plateTest = true;
      }

      if (!plateTest) {
        valueReturn = {
          invalid: true,
          feedBack: `Placa inválida.`
        };
      }
    }
    return valueReturn;
  },

  SimplePassword: (value) => {
    let valueReturn = {
      invalid: false,
      feedBack: undefined
    };
    if (value.length < 6) {
      valueReturn = {
        invalid: true,
        feedBack: 'Senha inválida.'
      }
    }
    return valueReturn;
  },

  Password: (value) => {
    let valueReturn = {
      invalid: false,
      feedBack: undefined
    };
    if (!(value.length > 6 && value.match(/[a-z]/) && value.match(/[A-Z]/) && value.match(/\d+/) && value.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,),.]/))) {
      valueReturn = {
        invalid: true,
        feedBack: 'Senha inválida.'
      }
    }
    return valueReturn;
  },

  Text: (value, size, required) => {
    let valueReturn = null;
    size = size ? size : 5;
    if (value !== '' && value.length < size) {
      valueReturn = {
        invalid: true,
        feedBack: `Deve ter pelo menos ${size} caracteres.`
      };
    }
    else {
      valueReturn = {
        invalid: false,
        feedBack: undefined
      };
    }
    return valueReturn;
  },

  Email: (email, required) => {
    let valueReturn = null;

    if (email.length === 0 && !required) {
      valueReturn = {
        invalid: false,
        feedBack: undefined
      };
    }
    else if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      valueReturn = {
        invalid: true,
        feedBack: 'Email inválido.'
      }
    }
    else {
      valueReturn = {
        invalid: false,
        feedBack: undefined
      };
    }
    return valueReturn;
  },

  Phone: (phone, required) => {
    let valueReturn = {
      invalid: false,
      feedBack: undefined
    };
    if (phone.length === 0 && !required) {
      valueReturn = {
        invalid: false,
        feedBack: undefined
      };
    }
    else if (phone.length < 14) {
      valueReturn = {
        invalid: true,
        feedBack: 'Número inválido.'
      }
    }
    return valueReturn;
  },

  PostCode: (postCode, required) => {
    let valueReturn = {
      invalid: false,
      feedBack: undefined
    };
    if (postCode.length === 0 && !required) {
      valueReturn = {
        invalid: false,
        feedBack: undefined
      };
    }
    else if (postCode.replace(/\D/g, '').length < 8) {
      valueReturn = {
        invalid: true,
        feedBack: 'Número inválido.'
      }
    }
    return valueReturn;
  },

  Document: (document, type, required) => {
    let valueReturn = {
      invalid: false,
      feedBack: undefined
    };
    if (type === 1) {
      let cnpj = document.replace(/[^\d]+/g, '');

      if (cnpj === '' && !required) {
        return {
          invalid: true,
          feedBack: `Preenchimento obrigatório`
        };
      }
      if (cnpj.length !== 14) {
        return {
          invalid: true,
          feedBack: `CNPJ inválido`
        };
      }
      if (cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999') {
        return {
          invalid: true,
          feedBack: `CNPJ inválido`
        };
      }

      let size = cnpj.length - 2
      let numbers = cnpj.substring(0, size);
      let digits = cnpj.substring(size);
      let sum = 0;
      let pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2)
          pos = 9;
      }
      let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
      if (result !== Number(digits.charAt(0))) {
        return {
          invalid: true,
          feedBack: `CNPJ inválido`
        };
      }

      size = size + 1;
      numbers = cnpj.substring(0, size);
      sum = 0;
      pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2)
          pos = 9;
      }
      result = sum % 11 < 2 ? 0 : 11 - sum % 11;
      if (result !== Number(digits.charAt(1))) {
        return {
          invalid: true,
          feedBack: `CNPJ inválido`
        };
      }
    }
    else if (type === 2) {
      let cpf = document.replace(/[\s.-]*/igm, '')
      if (!cpf && !required) {
        valueReturn = {
          invalid: true,
          feedBack: `Preenchimento obrigatório`
        };
      }
      else {
        if (
          cpf.length !== 11 ||
          cpf === '00000000000' ||
          cpf === '11111111111' ||
          cpf === '22222222222' ||
          cpf === '33333333333' ||
          cpf === '44444444444' ||
          cpf === '55555555555' ||
          cpf === '66666666666' ||
          cpf === '77777777777' ||
          cpf === '88888888888' ||
          cpf === '99999999999'
        ) {
          valueReturn = {
            invalid: true,
            feedBack: `CPF inválido`
          };
        }
        else {
          let sum = 0
          let rest
          for (let i = 1; i <= 9; i++)
            sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
          rest = (sum * 10) % 11
          if ((rest === 10) || (rest === 11)) rest = 0
          if (rest !== parseInt(cpf.substring(9, 10))) {
            valueReturn = {
              invalid: true,
              feedBack: `CPF inválido`
            };
          }
          sum = 0
          for (let i = 1; i <= 10; i++)
            sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
          rest = (sum * 10) % 11
          if ((rest === 10) || (rest === 11)) rest = 0
          if (rest !== parseInt(cpf.substring(10, 11))) {
            valueReturn = {
              invalid: true,
              feedBack: `CPF inválido`
            };
          }
        }
      }
    }
    return valueReturn;
  },
  Date: (date, required) => {
    let valueReturn = {
      invalid: false,
      feedBack: undefined
    };
    if (!date && !required) {
      valueReturn = {
        invalid: false,
        feedBack: undefined
      };
    }
    else if (!moment(date).isValid()) {
      valueReturn = {
        invalid: true,
        feedBack: 'Data inválida.'
      }
    }
    return valueReturn;
  }
};

export default Validates;