import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Grid } from '@mui/material';

import DataTable from '../../../components/DataTable';
import ActionsBar from '../../../components/ActionsBar';

import { ListUsers, ActivateUser } from '../../../redux/actions/Users';
import { UserTypeEnum } from '../../../utils/Enums';
import Functions from '../../../utils/Functions';
import useWindowDimensions from '../../../utils/useWindowDimensions';

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const users = useSelector((state) => state.users.users);
  const [searchValue, setSearchValue] = useState('');
  const [list, setList] = useState([]);
  const [columns, setColumns] = useState([]);

  const add = () => {
    navigate('/registrations/users/add');
  }

  const search = (update) => {
    if (update && searchValue === '') {
      return dispatch(ListUsers());
    }
    else {
      const data = {
        field: '_id',
        search: searchValue
      };
      return dispatch(ListUsers(data));
    }
  }

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    if (value.length >= 3 || value.length === 0) {
      search();
    }
  }

  const handleCheck = (item, column) => {
    switch (column) {
      case 'active':
        dispatch(ActivateUser({ _id: item._id }, () => search(true)))
        break;
      default:
        break;
    }
  }

  const edit = (item) => {
    navigate('/registrations/users/edit', {
      state: {
        user: item
      }
    });
  }

  useEffect(() => {
    if (users.length > 0) {
      const tempList = [];
      users.map((item) => {
        return tempList.push({
          ...item,
          lastSigninAt: item.lastSigninAt ? Functions.ShowDateTime(item.lastSigninAt) : null,
          typeEnum: item.type ? Functions.ShowEnum(UserTypeEnum, item.type) : null
        })
      });
      setList(tempList);
    }
    else {
      setList([])
    }

    // eslint-disable-next-line
  }, [users]);

  useEffect(() => {
    dispatch(ListUsers());
    document.title = 'SIGEP - Usuários';

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let limit = 0;
    if (width < 1300) {
      limit = 30
    }
    else if (width < 1200) {
      limit = 20
    }
    else if (width < 1100) {
      limit = 15
    }
    else {
      limit = 40;
    }
    setColumns([
      { id: 'name', numeric: false, disablePadding: true, label: 'Nome', limit: limit },
      { id: 'email', numeric: false, disablePadding: true, label: 'Email', limit: (limit - 5) },
      { id: 'typeEnum', numeric: false, disablePadding: false, label: 'Tipo' },
      { id: 'lastSigninAt', numeric: false, disablePadding: false, label: 'Último acesso' },
      { id: 'active', numeric: false, disablePadding: false, label: 'Ativo', boolean: true },
    ]);

    // eslint-disable-next-line
  }, [height, width]);

  return (
    <Grid container className='body-main' spacing={3}>
      <ActionsBar
        add={add}
        search={search}
        inputValue={searchValue}
        handle={(event) => handleSearch(event)}
      />
      <DataTable
        columns={columns}
        rows={list}
        checkAction={handleCheck}
        editAction={edit}
      />
    </Grid>
  );
}

export default Users;