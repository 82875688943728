import React from 'react';

import Basic from './Basic';
import Image from './Image';

const Input = ({ model, children, ...params }) => {
  switch (model) {
    case 'image':
      return (
        <Image {...params}>
          {children}
        </Image>
      );
    default:
      return (
        <Basic {...params}>
          {children}
        </Basic>
      );
  }
}

export default Input;