import React from 'react';

import { MdSearch } from 'react-icons/md';

import Button from '../Button';
import Input from '../Input';

const ActionsBar = ({ add, search, inputValue, handle }) => {
  return (
    <div className='actions-bar'>
      <div className='left'>
        <Button
          model='color'
          customColor='red'
          onClick={add}
          className='btn'>
          + ADICIONAR
      </Button>
      </div>
      <div className='right'>
        <Input
          model='icon'
          name='search'
          label='Pesquisar'
          id='search'
          value={inputValue}
          onChange={handle}
          action={search}
          icon={<MdSearch />}
        />
      </div>
    </div>
  );
}

export default ActionsBar;