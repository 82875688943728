import React from 'react';

import {
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormHelperText,
  FormControl
} from '@mui/material';
import Functions from '../../utils/Functions';

const Icon = ({ label, helperText, error, labelWidth, required, action, icon, name, ...params }) => {

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl variant='outlined' margin='normal' fullWidth error={error} required={required}
      size='small' >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <OutlinedInput
        type='text'
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={action}
              onMouseDown={handleMouseDownPassword}
              edge='end'
              tabindex = "-1"
            >
              {icon}
            </IconButton>
          </InputAdornment>
        }
        label={Functions.GetFakeLabel(label)}
        name={name}
        {...params}
      />
      {(helperText && error) && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
export default Icon;
