import React from 'react';

import TextField from '@mui/material/TextField';

const Basic = ({ helperText, error, ...params }) => {
  return (
    <TextField
      variant='outlined'
      margin='normal'
      fullWidth
      size='small'
      error={error}
      helperText={error ? helperText : undefined}
      {...params}
    />
  );
}
export default Basic;