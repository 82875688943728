import React, { forwardRef } from 'react';

import TextField from '@mui/material/TextField';

const InputComponent = forwardRef((props, ref) => {
  const { ...other } = props;
  return <div {...other} />;
});
const OutlinedDiv = ({ children, label, required }) => {
  return (
    <TextField
      variant='outlined'
      label={label}
      multiline
      fullWidth
      required={required}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent
      }}
      name={label}
      onChange={() => false}
      inputProps={{ children: children }}
    />
  );
};
export default OutlinedDiv;