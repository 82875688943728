export const UserTypeEnum = [
  { label: 'Master', value: 1 },
  { label: 'Admistrador', value: 2 },
  { label: 'Atendente', value: 3 }
]

export const UnitysEnum = [
  { label: 'PC', value: 1 },
  { label: 'KT', value: 2 },
  { label: 'JG', value: 3 },
  { label: 'MT', value: 4 },
  { label: 'CM', value: 5 },
  { label: 'LT', value: 6 },
  { label: 'PR', value: 7 },
  { label: 'RL', value: 8 }
]

export const ClientTypeEnum = [
  { label: 'P. Jurídica', value: 1 },
  { label: 'P. Física', value: 2 }
]

export const AddressLabelEnum = [
  { label: 'Residencial', value: 1 },
  { label: 'Comercial', value: 2 },
  { label: 'Cobrança', value: 3 },
  { label: 'Outro', value: 4 }
]

export const EmailLabelEnum = [
  { label: 'Pessoal', value: 1 },
  { label: 'Corporativo', value: 2 },
  { label: 'Outro', value: 3 }
]

export const PhoneLabelEnum = [
  { label: 'Celular', value: 1 },
  { label: 'Pessoal', value: 2 },
  { label: 'Residencial', value: 3 },
  { label: 'Corporativo', value: 4 },
  { label: 'Outro', value: 5 }
]