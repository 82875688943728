import axios from 'axios';

import cookie from 'react-cookies';

const baseURL = process.env.REACT_APP_URL;

const modelLogin = axios.create({
  baseURL: baseURL,
});

const model = axios.create({
  baseURL: baseURL,
});

modelLogin.interceptors.request.use(function (config) {

  config.headers.authorization = `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`;

  return config;
});

model.interceptors.request.use(function (config) {
  let accessToken = cookie.load('ACCESS_TOKEN');

  config.headers.authorization = `Bearer ${accessToken || null}`;

  return config;
});


export { model, modelLogin };