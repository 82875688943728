import { model } from '../../../services';

export const list = (data) => {
  if(data){
    return model.post(`/buys`, data);
  }
  else{
    return model.get(`/buys`);
  }
};

export const add = (data) => model.post(`/buy`, data);

export const find = (_id) => model.get(`/buy/${_id}`);

export const update = (data) => model.put(`/buy`, data);

export const addProduct = (data) => model.post(`/buy/product`, data);

export const updateProduct = (data) => model.put(`/buy/product`, data);

export const delProduct = (data) => model.delete(`/buy/product`, {data});