import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { Grid } from '@mui/material';

import Input from '../../../components/Input';
import ButtonsForm from '../../../components/ButtonsForm';

import { AddUser, UpdateUser } from '../../../redux/actions/Users';

import { UserTypeEnum } from '../../../utils/Enums';
import Functions from '../../../utils/Functions';
import Validates from '../../../utils/Validates';

const InitialValues = {
  edit: false,
  _id: undefined,
  name: '',
  type: -1,
  email: '',
};

const InitialInvalids = {
  name: false,
  type: false,
  email: false,
};

const InitialFeedBacks = {
  form: undefined
}

const Add = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  
  const [values, setValues] = useState(InitialValues);
  const [invalids, setInvalids] = useState(InitialInvalids);
  const [feedBacks, setFeedBacks] = useState(InitialFeedBacks);
  const [dates, setDates] = useState(undefined);

  const userType = useSelector((state) => state.users.user.type);

  const handle = (event, type, required, size) => {
    const { name, value } = event.target;

    if (type === 'select') {
      setValues({
        ...values,
        [name]: Number(value)
      });
    }
    else {
      setValues({
        ...values,
        [name]: value
      });
    }
    if (invalids[name]) {
      validateField(name, value, type, required, size);
    }
  };

  const validateField = (name, value, type, required, size) => {
    let valueReturn = Validates.Field(value, type, required, size);

    setInvalids({
      ...invalids,
      [name]: valueReturn.invalid
    });
    setFeedBacks({
      ...feedBacks,
      [name]: valueReturn.feedBack
    });

  };

  const save = () => {
    console.log(values);
    if (Validates.User(values, invalids)) {
      //erro
      setFeedBacks({
        ...feedBacks,
        form: 'Preenchimento Obrigatório.'
      });
      Functions.ErrorString('Preenchimento incorreto, por favor verifique os campos em destaque.');
    }
    else {
      const data = {
        ...values
      };

      values.edit ?
        dispatch(UpdateUser(data, apiReturn)) :
        dispatch(AddUser(data, apiReturn));
    }

  }

  const apiReturn = (response, error) => {
    if (response) {
      Functions.SuccessString(`Usuário ${values.edit ? 'editado' : 'cadastrado'} com sucesso.`);
      return backList();
    }
    else {
      return Functions.ErrorString('NETWORK ERROR');
    }
  }

  const backList = () => {
    return navigate('/registrations/users');
  }

  useEffect(() => {
    const user = location.state && location.state.user ? location.state.user : null;

    if (user) {
      console.log(user);

      setDates({
        createdAt: Functions.ShowDateTime(user.createdAt),
        updateAt: Functions.ShowDateTime(user.updateAt),
        createdBy: user.createdBy,
        updateBy: user.updateBy
      })
      setValues({
        ...values,
        ...user,
        edit: true
      });
      document.title = `SIGEP - Editar usuário`;
    }
    else if (location.pathname === '/registrations/users/edit') {
      backList();
    }
    else{
      document.title = 'SIGEP - Adicionar usuário';
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Grid container className='body-main form' spacing={3}>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Input
          id='name'
          label='Nome'
          name='name'
          value={values.name}
          autoFocus
          required
          onChange={(event) => handle(event, 'text', true, 3)}
          onBlur={() => validateField('name', values.name, 'text', true, 3)}
          error={invalids.name || (values.name === '' && feedBacks.form !== undefined)}
          helperText={feedBacks.name || feedBacks.form}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Input
          id='type'
          label='Tipo'
          name='type'
          model='select'
          list={Functions.ArrangeEnumByPermission(UserTypeEnum, userType)}
          defaultValue='SELECIONE'
          value={values.type}
          onChange={(event) => handle(event, 'select', true)}
          onBlur={() => validateField('type', values.type, 'select', true)}
          required
          error={invalids.type || (values.type === -1 && feedBacks.form !== undefined)}
          helperText={feedBacks.type || feedBacks.form}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Input
          id='email'
          label='Email'
          name='email'
          value={values.email}
          required
          type='email'
          onChange={(event) => handle(event, 'email', true)}
          onBlur={() => validateField('email', values.email, 'email', true)}
          error={invalids.email || (values.email === '' && feedBacks.form !== undefined)}
          helperText={feedBacks.email || feedBacks.form}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <ButtonsForm cancel={backList} save={save} dates={dates} />
      </Grid>
    </Grid>
  );
}

export default Add;