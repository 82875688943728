import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const Search = ({ list, id, label, searchKey, helperText, onBlur, value, onChange, error, required, disabled }) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete
      freeSolo
      options={list}
      fullWidth
      size='small'
      id={id}
      value={value}
      onChange={onChange}   
      inputValue={inputValue}
      disabled={disabled}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={(option) => option[searchKey]}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          margin='normal'
          variant='outlined'
          onBlur={onBlur}
          error={error}
          helperText={error ? helperText : undefined}
        />
      )}
    />

    // <TextField
    //   variant='outlined'
    //   margin='normal'
    //   fullWidth
    //   size='small'
    //   select
    //   error={error}
    //   helperText={error ? helperText : undefined}
    //   SelectProps={{ native: true }}
    //   {...params}
    // >
    //   {defaultValue && (
    //     <option value={-1} >{defaultValue}</option>
    //   )}
    //   {list &&
    //     list.map((op) => (
    //       <option key={`${op.value}-${op.label}`} value={op.value}>{op.label}</option>
    //     ))}
    // </TextField>
  );
}
export default Search;