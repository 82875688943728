import {
  list,
  find,
  add,
  update,

  addProduct,
  updateProduct,
  delProduct,

} from './api';
import { SetLoading, RemoveLoading } from '../System';

import Functions from '../../../utils/Functions';

export const FIND_BUY = 'FIND_BUY';
export const LIST_BUYS = 'LIST_BUYS';
export const CLEAR_BUYS = 'CLEAR_BUYS';
export const PRINT_BUY = 'PRINT_BUY';
export const ADD_PRODUCT_BUY = 'ADD_PRODUCT_BUY';
export const EDIT_PRODUCT_BUY = 'EDIT_PRODUCT_BUY';
export const DELETE_PRODUCT_BUY = 'DELETE_PRODUCT_BUY';

export const ListBuys = (data) => {
  return (dispatch) => {
    dispatch(SetLoading('ListBuys'));
    return list(data)
      .then((response) => {
        dispatch({
          type: LIST_BUYS,
          payload: response.data,
        });
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('ListBuys')));
  };
};

export const FindBuy = (_id, call) => {
  return (dispatch) => {
    dispatch(SetLoading('FindBuy'));
    return find(_id)
      .then((response) => {
        if (call) call();
        dispatch({
          type: FIND_BUY,
          payload: response.data,
        });
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('FindBuy')));
  };
};

export const AddBuy = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('AddBuy'));
    return add(data)
      .then((response) => call(response.data))
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('AddBuy')));
  };
};

export const UpdateBuy = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('UpdateBuy'));
    return update(data)
      .then((response) => call(response.data))
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('UpdateBuy')));
  };
};

export const ClearBuys = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_BUYS
    })
  }
};

export const AddProductBuy = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('AddProductBuy'));
    return addProduct(data)
      .then((response) => {
        call(response);
        dispatch({
          type: ADD_PRODUCT_BUY,
          payload: data
        });
      })
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('AddProductBuy')));
  };
};

export const UpdateProductBuy = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('UpdateProductBuy'));
    return updateProduct(data)
      .then((response) => {
        call(response);
        dispatch({
          type: EDIT_PRODUCT_BUY,
          payload: data
        });
      })
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('UpdateProductBuy')));
  };
};

export const DeleteProductBuy = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('DeleteProductBuy'));
    return delProduct(data)
      .then((response) => {
        call({ ...response, type: 'Produto' });
        dispatch({
          type: DELETE_PRODUCT_BUY,
          payload: data
        });
      })
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('DeleteProductBuy')));
  };
};
