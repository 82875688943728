import React from 'react';

import OutlinedDiv from '../OutlinedDiv'
import Address from './Address';

const InitialInvalid = {
  post_code: false,
  state: false,
  city: false,
  road: false,
  sub_urb: false,
  number: false
}

const InitialAddress = {
  _id: undefined,
  label: -1,
  post_code: '',
  state: -1,
  city: -1,
  road: '',
  sub_urb: '',
  number: undefined,
  complement: '',
  reference: ''
}
const AddressList = ({ list, setList, invalidsList, feedBacksList, required, formFeedBack }) => {
  const addItem = () => {
    const newList = list;
    const newInvalids = invalidsList;
    const newFeedBacks = feedBacksList;

    newList.push({ ...InitialAddress });
    newInvalids.push({ ...InitialInvalid });
    newFeedBacks.push({});

    setList('addresses', newList, newInvalids, newFeedBacks);
  }

  const removeItem = (index) => {
    const newList = list;
    const newInvalids = invalidsList;
    const newFeedBacks = feedBacksList;

    if (list.length > 1 || index !== 0) {
      newList.splice(index, 1);
      newInvalids.splice(index, 1);
      newFeedBacks.splice(index, 1);
    }
    
    setList('addresses', newList, newInvalids, newFeedBacks);
  }

  const setItem = (index, item, invalid, feedBack) => {
    const newList = list;
    const newInvalids = invalidsList;
    const newFeedBacks = feedBacksList;

    newList[index] = item;
    newInvalids[index] = invalid;
    newFeedBacks[index] = feedBack;
    
    setList('addresses', newList, newInvalids, newFeedBacks);
  }

  return (
    <div className='address-list-area'>
      <OutlinedDiv label='Endereços' required={required} >
        {list &&
          list.map((item, index) => (
            <Address
              key={`address-${index}`}
              item={item}
              index={index}
              addItem={addItem}
              removeItem={removeItem}
              setItem={setItem}
              listSize={list.length}
              list={list}
              invalids={invalidsList[index]}
              feedBacks={feedBacksList[index]}
              formFeedBack={formFeedBack}
            />
          ))}
      </OutlinedDiv>
    </div>
  )
}

export default AddressList;