import React from 'react';

import Input from '../Input'
import OutlinedDiv from '../OutlinedDiv'
import Button from '../Button';
import { MdAdd, MdDelete } from 'react-icons/md';

const InitialSimilar = {
  _id: '',
}

const SimilarList = ({ list, setList }) => {
  const addItem = () => {
    const newList = list;
    newList.push({ ...InitialSimilar });
    setList(newList);
  }
  const removeItem = (index) => {
    if (list.length > 1) {
      const newList = list;
      newList.splice(index, 1);
      setList(newList);
    }
    else if (index !== 0) {
      const newList = list;
      newList.splice(index, 1);
      setList(newList);
    }
  }

  const handle = (index, event) => {
    const { name, value } = event.target;
    list[index][name] = value.toUpperCase();
    setList(list);
  }

  return (
    <div className='similar-area'>
      <OutlinedDiv label='Similares' >
        {list &&
          list.map((item, index) => (
            <div key={index} className='body'>
              <div className='content'>
                <Input
                  id={`_id_${index}`}
                  label='Código'
                  name='_id'
                  value={item._id}
                  onChange={(event) => handle(index, event)}
                  required
                />
              </div>
              <div className='actions'>
                <Button
                  model='color'
                  customColor='red'
                  size='large'
                  onClick={() => removeItem(index)}
                  disabled={list.length === 1}
                  className='btn'>
                  <MdDelete />
                </Button>
              </div>
              <div className='actions'>
                <Button
                  model='color'
                  customColor='green'
                  size='large'
                  onClick={() => addItem()}
                  className='btn'>
                  <MdAdd />
                </Button>
              </div>
            </div>
          ))}
      </OutlinedDiv>
    </div>
  )
}

export default SimilarList;