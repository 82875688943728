import React from 'react';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const Toast = () => (
  <ToastContainer
    position='top-right'
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop
    theme='colored'
    closeOnClick
    rtl={false}
    pauseOnVisibilityChange
    draggable
    pauseOnHove
  />
);

export default Toast;