import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import {
  Drawer,
  List,
  IconButton
} from '@mui/material';

import ClosseIcon from '@mui/icons-material/Close';

import SideBarItem from '../SideBarItem';

import AppRoutes from '../../routes/routesArray';

import Logo from '../../assets/logo/white.svg';

const SideBar = ({ open, toggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userType = useSelector((state) => state.users.user.type);

  return (
    <Drawer
      variant='persistent'
      anchor='left'
      open={open}
      className='side-bar'
    >
      <div className='header'>
        <div className='toggle'>
          <IconButton onClick={toggle}>
            <ClosseIcon />
          </IconButton>
        </div>
        <img
          src={Logo}
          alt='Logo SIGEP'
          className='logo'
          onClick={() => navigate('/drashboard')} />
      </div>
      <div className='body'>
        <List>
          {AppRoutes.map((route) => {
            if (route.children) {
              return (
                <SideBarItem
                  key={route.path}
                  label={route.name}
                  icon={route.icon ? route.icon : undefined}
                  selected={(location.pathname.indexOf(route.path) !== -1)}
                  initialState={(location.pathname.indexOf(route.path) !== -1)}
                >
                  {route.children.map((subRoute) => {
                    if ((subRoute.name !== 'Usuários' || userType <= 2) && !subRoute.hide) {
                      return (
                        <SideBarItem
                          key={subRoute.path}
                          label={subRoute.name}
                          icon={subRoute.icon ? subRoute.icon : undefined}
                          selected={(location.pathname === subRoute.path)}
                          action={() => navigate(subRoute.path)}
                          secondary
                        />
                      )
                    }
                    else {
                      return null;
                    }
                  })}
                </SideBarItem>
              )
            }
            else if (!route.hide) {
              return (
                <SideBarItem
                  key={route.path}
                  label={route.name}
                  icon={route.icon ? route.icon : undefined}
                  selected={(location.pathname === route.path)}
                  action={() => navigate(route.path)}
                />
              )
            }
            else {
              return null;
            }
          })}
        </List>
      </div>

    </Drawer>
  );
}

export default SideBar;