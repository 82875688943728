import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Input from '../Input';

import { CloseDialog } from '../../redux/actions/System';

import Functions from '../../utils/Functions';
import Validates from '../../utils/Validates';

const initialState = {
  value: '',
  invalid: false,
  feedBack: undefined,
  reEnter: {
    value: '',
    invalid: false,
    feedBack: undefined
  }
}

const DialogBox = ({ dialog }) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState(initialState);

  const close = (data) => {
    if (dialog.placeholder === '' || data === false) {
      dispatch(CloseDialog(data));
    }
    else {
      if (dialog.type !== 'password') {

      } else {
        if (input.invalid || input.value === '' || input.reEnter.value === '') {
          Functions.ErrorString('Entrada inválida, por favor tente novamente.');
          setInput({
            ...input,
            invalid: (input.value === ''),
            feedBack: (input.value === '') ? 'Preenchimento Obrigatório' : undefined,
            reEnter: {
              ...input.reEnter,
              invalid: (input.reEnter.value === ''),
              feedBack: (input.reEnter.value === '') ? 'Preenchimento Obrigatório' : undefined,
            }
          });
        }
        else {
          dispatch(CloseDialog(input));
        }
      }
    }
  }

  const KeySpect = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      close(true)
    }
  }

  const handle = (event) => {
    const { name, value } = event.target;
    let valueReturn = null;
    switch (dialog.type) {
      case 'email':
        valueReturn = Validates.Email(value, true);
        break
      case 'password':
        valueReturn = Validates.Password(value);
        break
      default:
        valueReturn = Validates.Text(value, 3);
        break;
    }
    if (name === 'input') {
      setInput({
        ...input,
        value: value,
        invalid: valueReturn.invalid,
        feedBack: valueReturn.feedBack
      });
    }
    else {
      if (input.value === value) {
        setInput({
          ...input,
          reEnter: {
            value: value,
            invalid: valueReturn.invalid,
            feedBack: valueReturn.feedBack
          }
        });
      }
      else {
        setInput({
          ...input,
          reEnter: {
            value: value,
            invalid: true,
            feedBack: 'Senha e confirmção são diferentes.'
          }
        });
      }
    }
  };

  return (
    <Dialog
      open={dialog.open}
      onClose={close}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{dialog.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {dialog.message}
        </DialogContentText>
        {(dialog.placeholder !== '' && dialog.type === 'password') &&
          (
            <div>
              <Input
                model='password'
                name='input'
                label={dialog.placeholder}
                id='input'
                value={input.value}
                onChange={handle}
                onKeyDown={KeySpect}
                labelWidth={150}
                required
                error={input.invalid}
                helperText={input.feedBack}
              />
              <Input
                model='password'
                name='re-enter'
                label='Confirmação'
                id='re-enter'
                value={input.reEnter.value}
                onChange={handle}
                onKeyDown={KeySpect}
                labelWidth={150}
                required
                error={input.reEnter.invalid}
                helperText={input.reEnter.feedBack}
              />
            </div>
          )}
        {(dialog.placeholder !== '' && dialog.type === 'email') &&
          (
            <Input
              type='email'
              name='input'
              label={dialog.placeholder}
              id='input'
              value={input.value}
              onChange={handle}
              onKeyDown={KeySpect}
              labelWidth={150}
              required
              error={input.invalid}
              helperText={input.feedBack}
            />
          )}
        {(dialog.placeholder !== '' && dialog.type !== 'email' && dialog.type !== 'password') &&
          (
            <Input
              name='input'
              label={dialog.placeholder}
              id='input'
              value={input.value}
              onChange={handle}
              onKeyDown={KeySpect}
              labelWidth={150}
              required
              error={input.invalid}
              helperText={input.feedBack}
            />
          )}
      </DialogContent>
      <DialogActions>
        {dialog.cancel && (
          <Button onClick={() => close(false)} color='primary'>
            Cancelar
          </Button>
        )}
        <Button onClick={() => close(true)} color='primary' autoFocus>
          {dialog.label}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogBox;