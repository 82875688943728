import React from 'react';
import { useNavigate } from 'react-router';

import { Menu, MenuItem } from '@mui/material';

const ListMenu = ({ open, toggle, anchorEl, list }) => {
  const navigate = useNavigate()

  const redirect = (destiny) => {
    toggle();
    navigate(destiny);
  }

  return (
    <Menu
      id='simple-menu'
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={toggle}
    >
      {list &&
        list.map((item, index) => (
          <MenuItem
            key={`${index} - ${item.label}`}
            onClick={item.destiny ?
              () => redirect(item.destiny) :
              item.action ?
                () => item.action() :
                null}
          >
            {item.label}
          </MenuItem>
        ))}
    </Menu>
  );
}

export default ListMenu;