import { list, find, add, del, update, activate, printTag, getTags, getBalance } from './api';
import { SetLoading, RemoveLoading } from '../System';

import Functions from '../../../utils/Functions';

export const FIND_PRODUCT = 'FIND_PRODUCT';
export const LIST_PRODUCTS = 'LIST_PRODUCTS';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
export const GET_TAGS = 'GET_TAGS';
export const GET_BALANCE = 'GET_BALANCE';

export const ListProducts = (data) => {
  return (dispatch) => {
    dispatch(SetLoading('ListProducts'));
    return list(data)
      .then((response) => {
        dispatch({
          type: LIST_PRODUCTS,
          payload: response.data,
        });
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('ListProducts')));
  };
};

export const FindProduct = (data) => {
  return (dispatch) => {
    dispatch(SetLoading('FindProduct'));
    return find(data)
      .then((response) => {
        dispatch({
          type: FIND_PRODUCT,
          payload: response.data,
        });
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('FindProduct')));
  };
};

export const AddProduct = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('AddProduct'));
    return add(data)
      .then((response) => call(response.data))
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('AddProduct')));
  };
};

export const DeleteProduct = (_id, call) => {
  return (dispatch) => {
    dispatch(SetLoading('DeleteProduct'));
    return del(_id)
      .then((response) => call(response.data))
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('DeleteProduct')));
  };
};

export const UpdateProduct = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('UpdateProduct'));
    return update(data)
      .then((response) => call(response.data))
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('UpdateProduct')));
  };
};

export const ActivateProduct = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('ActivateProduct'));
    return activate(data)
      .then(call)
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('ActivateProduct')));
  };
};

export const PrintTag = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('PrintTag'));
    return printTag(data)
      .then(call)
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('PrintTag')));
  };
};

export const ClearProducts = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_PRODUCTS
    })
  }
};

export const GetTags = () => {
  return (dispatch) => {
    dispatch(SetLoading('GetTags'));
    return getTags()
      .then((response) => {
        dispatch({
          type: GET_TAGS,
          payload: Functions.Download(response, 'Etiquetas')
        });
      })
      .catch((error) => {
        console.log(error);
        Functions.ErrorString('Não existem etiquetas a serem impressas');
      })
      .finally(() => dispatch(RemoveLoading('GetTags')));
  };
};

export const GetBalance = () => {
  return (dispatch) => {
    dispatch(SetLoading('GetBalance'));
    return getBalance()
      .then((response) => {
        dispatch({
          type: GET_BALANCE,
          payload: Functions.Download(response, 'Balanco')
        });
      })
      .catch((error) => {
        console.log(error);
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('GetBalance')));
  };
};