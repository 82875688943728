import { LIST_CLIENTS, CLEAR_CLIENTS } from '../../actions/Clients';

const global = {
  clients: [],
}

export default function ClientsReducer(state = global, action) {
  const valueReturn = action.payload;

  switch (action.type) {
    case LIST_CLIENTS:
      return { ...state, clients: valueReturn }
    case CLEAR_CLIENTS:
      return { ...state, clients: [] }
    default:
      return state;
  }
}