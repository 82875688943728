import React from 'react';

import { useSelector } from 'react-redux';

import { AppBar, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import Logo from '../../assets/logo/gray.svg';

const Header = ({ sideBarControl, menuControl, userMenuList }) => {
  const md5 = require('md5');
  const user = useSelector((state) => state.users.user);
  const socialUser = useSelector((state) => state.users.socialUser);

  return (
    <AppBar
      position='fixed'
      className='app-bar'>
      <Toolbar className={'tool-bar'}>
        <IconButton
          edge='start'
          color='inherit'
          aria-label='menu'
          onClick={() => sideBarControl()}>
          <MenuIcon />
        </IconButton>
        <div className='logo'>
          <img src={Logo} alt='Logo SIGEP' />
        </div>
        <div>
          <img
            alt='user avatar'
            src={(socialUser && socialUser.photo) ?
              socialUser.photo :
              `https://www.gravatar.com/avatar/${md5(user && user.email ?
              user.email : '12345')}?d=mp&amp;r=pg&amp;s=40`}
            className='user-avatar'
            onClick={(event) => menuControl(userMenuList, event)}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
}
export default Header;