import React from 'react';

import TextField from '@mui/material/TextField';

const Select = ({ list, defaultValue, helperText, error, ...params }) => (
  <TextField
    variant='outlined'
    margin='normal'
    fullWidth
    size='small'
    select
    error={error}
    helperText={error ? helperText : undefined}
    SelectProps={{ native: true }}
    {...params}
  >
    {defaultValue && (
      <option value={-1} >{defaultValue}</option>
    )}
    {list &&
      list.map((op) => (
        <option key={`${op.value}-${op.label}`} value={op.value}>{op.label}</option>
      ))}
  </TextField>
);

export default Select;

/*
 const Select = ({ label, helperText, error, required, list, defaultValue, ...options }) => (
   <FormControl variant='outlined' margin='normal' fullWidth error={error} required={required}
      size='small' >
      <InputLabel htmlFor='outlined-age-native-simple'>{label}</InputLabel>
      <Select
        native
        {...options}
      >
        {defaultValue &&(
          <option aria-label={defaultValue} value={-1} />
        )}
        {list &&
          list.map((op) => (
            <option key={op.label} value={op.value}>{op.label}</option>
          ))}
      </Select>
      {helperText && (
        <FormHelperText id='outlined-weight-helper-text'>{helperText}</FormHelperText>
      )}
    </FormControl> */