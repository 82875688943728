import React from 'react';

import { Grid } from '@mui/material';
import { MdAdd, MdDelete } from 'react-icons/md';

import Input from '../Input';
import Button from '../Button';

import Functions from '../../utils/Functions';
import Validates from '../../utils/Validates';

const Contact = ({
  item,
  index,
  addItem,
  removeItem,
  setItem,
  listSize,
  invalids,
  feedBacks,
  formFeedBack
}) => {

  const handle = (event, type, required, size) => {
    const { name, value } = event.target;
    let newItem = item;

    if (type === 'phone') {
      newItem[name] = Functions.MaskPhone(value);
    }
    else {
      newItem[name] = value;
    }
    setItem(index, newItem, invalids, feedBacks);
    if (invalids[name]) {
      validateField(name, value, type, required, size);
    }
  }

  const validateField = (name, value, type, required, size) => {
    let newInvalids = invalids;
    let newFeedBacks = feedBacks;

    let valueReturn = Validates.Field(value, type, required, size);
    
    newInvalids[name] = valueReturn.invalid;
    newFeedBacks[name] = valueReturn.feedBack
    setItem(index, item, newInvalids, newFeedBacks);
  }

  return (
    <div className='body'>
      <div className='content'>
        <Grid container className='address-form' spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              id={`name_${index}`}
              label='Nome'
              name='name'
              value={item.name}
              onChange={(event) => handle(event, 'text', false, 4)}
              onBlur={() => validateField('name', item.name, 'text', false, 4)}
              error={invalids.name}
              helperText={feedBacks.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              id={`label_${index}`}
              label='Cargo/Referência'
              name='label'
              value={item.label}
              onChange={(event) => handle(event, 'text', false)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              id={`phone_${index}`}
              label='Telefone'
              name='phone'
              value={item.phone}
              onChange={(event) => handle(event, 'phone', false)}
              onBlur={() => validateField('phone', item.phone, 'phone', false)}
              error={invalids.phone}
              helperText={feedBacks.phone}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              id={`email_${index}`}
              label='Email'
              name='email'
              value={item.email}
              type='email'
              onChange={(event) => handle(event, 'email', false)}
              onBlur={() => validateField('email', item.email, 'email', false)}
              error={invalids.email}
              helperText={feedBacks.email}
            />
          </Grid>
        </Grid>
      </div>
      <div className='actions'>
        <Button
          model='color'
          customColor='red'
          size='large'
          onClick={() => removeItem(index)}
          disabled={listSize === 1}
          className='btn'>
          <MdDelete />
        </Button>
        <Button
          model='color'
          customColor='green'
          size='large'
          onClick={() => addItem()}
          className='btn'>
          <MdAdd />
        </Button>
      </div>
    </div>
  )
}

export default Contact;