import { SET_LOADING, REMOVE_LOADING, OPEN_DIALOG, CLOSE_DIALOG, SET_ROWS } from '../../actions/System';

import cookie from 'react-cookies'

const global = {
  loading: { status: false, actions: undefined },
  dialog: {
    open: false,
    cancel: true,
    title: '',
    message: '',
    label: '',
    placeholder: '',
    type: '',
    return: { origin: '', status: '' }
  },
  rowsPerPage: Number(cookie.load('ROWS_PER_PAGE')) || 5,
};

const LoadingReducer = (state = global, action) => {
  const valueReturn = action.payload;

  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: { status: true, actions: { ...state.loading.actions, [valueReturn]: true } }
      };
    case REMOVE_LOADING:
      return {
        ...state,
        loading: { status: false, actions: { ...state.loading.actions, [valueReturn]: false } }
      };
    case OPEN_DIALOG:
      return {
        ...state,
        dialog: {
          open: true,
          cancel: valueReturn.cancel === false ? undefined : true,
          title: valueReturn.title,
          message: valueReturn.message,
          label: valueReturn.label,
          placeholder: valueReturn.placeholder ? valueReturn.placeholder : '',
          type: valueReturn.placeholder ? valueReturn.type : '',
          return: { origin: valueReturn.origin, status: '' }
        }
      };
    case CLOSE_DIALOG:
      return {
        ...state,
        dialog: {
          open: false,
          cancel: true,
          title: '',
          message: '',
          label: '',
          placeholder: '',
          type: '',
          return: { ...state.dialog.return, status: valueReturn }
        }
      };
    case SET_ROWS:
      cookie.save('ROWS_PER_PAGE', valueReturn);
      return {
        ...state,
        rowsPerPage: valueReturn
      };
    default:
      return state;
  }
};

export default LoadingReducer;