import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import Footer from '../../components/Footer';
import ListMenu from '../../components/ListMenu';

import AppRoutes from '../../routes';
import BreadcrumbsBar from '../../components/Breadcrumbs';

import User from '../User';

import { SignOut } from '../../redux/actions/Users';
import UpdateEmailForm from '../User/UpdateEmailForm';
import UpdatePasswordForm from '../User/UpdatePasswordForm';


const Main = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [openUser, setOpenUser] = useState(false);
  const [openUpdateEmail, setOpenUpdateEmail] = useState(false);
  const [openUpdatePassword, setOpenUpdatePassword] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuList, setMenuList] = useState([]);

  const menuControl = (list, event, close) => {
    if (menuOpen || close) {
      setAnchorEl(null);
      setMenuOpen(false);
      setMenuList([]);
    } else {
      setMenuList(list);
      setAnchorEl(event.currentTarget);
      setMenuOpen(true);
    }
  };

  const signOut = () => {
    dispatch(SignOut());
  }

  const toggleUser = () => {
    menuControl(undefined, null, true);
    setOpenUser(!openUser);
    setOpenUpdateEmail(false);
    setOpenUpdatePassword(false);
  }
  
  const toggleUpdateEmail = () => {
    menuControl(undefined, null, true);
    setOpenUpdateEmail(!openUpdateEmail);
    setOpenUser(false);
    setOpenUpdatePassword(false);
  }

  const toggleUpdatePassword = () => {
    menuControl(undefined, null, true);
    setOpenUpdatePassword(!openUpdatePassword);
    setOpenUpdateEmail(false);
    setOpenUser(false);
  }

  const userMenuList = [
    { label: 'Meus dados', action: toggleUser },
    { label: 'Alterar email', action: toggleUpdateEmail },
    { label: 'Alterar senha', action: toggleUpdatePassword },
    { destiny: '/settings', label: 'Configurtaçoes' },
    { label: 'Sair', action: signOut }
  ];

  return (
    <div className='app'>
      <Header
        sideBarOpen={sideBarOpen}
        menuControl={menuControl}
        sideBarControl={() => setSideBarOpen(!sideBarOpen)}
        userMenuList={userMenuList}
      />
      <SideBar open={sideBarOpen} toggle={() => setSideBarOpen(!sideBarOpen)} location={location.pathname} />
      {/* <div className='main' onClick={() => close()}> */}      
      <BreadcrumbsBar location={location.pathname} />
      <div className={sideBarOpen ? 'main' : 'main closed'} >
        <AppRoutes />
      </div>
      <Footer />
      <ListMenu
        open={menuOpen}
        anchorEl={anchorEl}
        toggle={menuControl}
        list={menuList}
      />
      <User open={openUser} toggle={toggleUser} openUpdateEmail={toggleUpdateEmail} openUpdatePassword={toggleUpdatePassword} />
      <UpdateEmailForm open={openUpdateEmail} toggle={toggleUpdateEmail} />
      <UpdatePasswordForm open={openUpdatePassword} toggle={toggleUpdatePassword} />
    </div>
  );
};
export default Main;
