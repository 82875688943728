import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Checkbox,
  IconButton,
  Tooltip
} from '@mui/material';

import { Delete, Edit, Forward, Print, Visibility } from '@mui/icons-material';

import { SetRows } from '../../redux/actions/System';

import Functions from '../../utils/Functions';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function DataTableHead({ columns, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, actions, noSelect }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {!noSelect && (
          <TableCell padding='checkbox'>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
        )}
        {columns.map((col) => {
          if (col.boolean) {
            return (
              <TableCell
                key={col.id}
                align='center'
                padding={col.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === col.id ? order : false}
              >
                {col.label}
              </TableCell>
            )
          }
          else {
            return (
              <TableCell
                key={col.id}
                align={col.numeric ? 'right' : 'left'}
                padding={col.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === col.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === col.id}
                  direction={orderBy === col.id ? order : 'asc'}
                  onClick={createSortHandler(col.id)}
                >
                  {col.label}
                </TableSortLabel>
              </TableCell>
            )
          }
        }
        )}
        {actions && (
          <TableCell align='center' padding='none'>
            Ações
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

const SelectedRows = ({ numSelected, action }) => {
  return (
    <div className='rows-selected'>
      {numSelected > 0 && (
        <Typography color='inherit' variant='subtitle1' component='div'>
          {numSelected > 1 ? `${numSelected} selecionados` : `1 selecionado`}
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title='Delete'>
          <IconButton aria-label='delete' onClick={action}>
            <Delete />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default function DataTable({
  size = 'small',
  columns,
  rows,
  editAction,
  viewAction,
  deleteAction,
  deleteSelection,
  checkAction,
  selectAction,
  printAction,
  noSelect,
  allRows,
  defaultOrderBy,
  editCondition,
  disabled,
  initialSelected = []
}) {
  const dispatch = useDispatch();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = useSelector((state) => state.system.rowsPerPage);

  const setRowsPerPage = (value) => {
    dispatch(SetRows(Number(value)));
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, _id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (_id) => selected.indexOf(_id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  useEffect(() => {
    if (initialSelected.length === 0 && selected.length !== 0) {
      setSelected([]);
    }

    // eslint-disable-next-line
  }, [initialSelected]);

  return (
    <div className='data-table'>
      <TableContainer>
        <Table
          aria-labelledby='tableTitle'
          size={size}
          aria-label='enhanced table'
        >
          <DataTableHead
            columns={columns}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            noSelect={noSelect}
            actions={((editAction || deleteAction || selectAction || printAction || viewAction) && !disabled) ? true : false}
          />
          {!allRows && (
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      //onClick={(event) => handleClick(event, row._id)}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                      {!noSelect && (
                        <TableCell padding='checkbox'>
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, row._id)}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                      )}
                      {columns.map((col) => {
                        if (col.coin) {
                          return (
                            <TableCell
                              key={index + col.id}
                              align={col.numeric ? 'right' : 'left'}
                              padding={col.disablePadding ? 'none' : 'normal'}
                            >
                              R$ {Functions.ShowCurrency(row[col.id])}
                            </TableCell>
                          )
                        }
                        else if (col.limit) {
                          return (
                            <TableCell
                              key={index + col.id}
                              align={col.numeric ? 'right' : 'left'}
                              padding={col.disablePadding ? 'none' : 'normal'}
                            >
                              {row[col.id].length > col.limit ? row[col.id].slice(0, col.limit) + '...' : row[col.id]}
                            </TableCell>
                          )
                        }
                        else if (col.boolean) {
                          return (
                            <TableCell key={index + col.id} padding='checkbox' align='center'>
                              <Checkbox
                                name={index + col.id}
                                id={index + col.id}
                                onChange={() => checkAction(row, col.id)}
                                checked={row[col.id] === 1}
                              />
                            </TableCell>
                          )
                        }
                        else {
                          return (
                            <TableCell
                              key={index + col.id}
                              align={col.numeric ? 'right' : 'left'}
                              padding={col.disablePadding ? 'none' : 'normal'}
                            >
                              {row[col.id]}
                            </TableCell>
                          )
                        }
                      })}
                      {((editAction || deleteAction || selectAction || printAction || viewAction) && !disabled) && (
                        <TableCell align='center' padding='none' >
                          {(editAction && !row[editCondition]) ? (
                            <IconButton size='small' aria-label='Editar' onClick={() => editAction(row)}>
                              <Edit />
                            </IconButton>
                          ) : (
                            <IconButton size='small' aria-label='Editar' onClick={() => viewAction(row)}>
                              <Visibility />
                            </IconButton>
                          )}
                          {(!editAction && viewAction) && (
                            <IconButton size='small' aria-label='Editar' onClick={() => viewAction(row)}>
                              <Visibility />
                            </IconButton>
                          )}
                          {deleteAction && (
                            <IconButton size='small' aria-label='Deletar' onClick={() => deleteAction(row)}>
                              <Delete />
                            </IconButton>
                          )}
                          {selectAction && (
                            <IconButton size='small' aria-label='Selecionar' onClick={() => selectAction(row)}>
                              <Forward />
                            </IconButton>
                          )}
                          {printAction && (
                            <IconButton size='small' aria-label='Imprimir' onClick={() => printAction(row)}>
                              <Print />
                            </IconButton>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
          {allRows && (
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      //onClick={(event) => handleClick(event, row._id)}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                      {!noSelect && (
                        <TableCell padding='checkbox'>
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, row._id)}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                      )}
                      {columns.map((col) => {
                        if (col.coin) {
                          return (
                            <TableCell
                              key={index + col.id}
                              align={col.numeric ? 'right' : 'left'}
                              padding={col.disablePadding ? 'none' : 'normal'}
                            >
                              R$ {Functions.ShowCurrency(row[col.id])}
                            </TableCell>
                          )
                        }
                        else if (col.limit) {
                          return (
                            <TableCell
                              key={index + col.id}
                              align={col.numeric ? 'right' : 'left'}
                              padding={col.disablePadding ? 'none' : 'normal'}
                            >
                              {row[col.id].length > col.limit ? row[col.id].slice(0, col.limit) + '...' : row[col.id]}
                            </TableCell>
                          )
                        }
                        else if (col.boolean) {
                          return (
                            <TableCell key={index + col.id} padding='checkbox' align='center'>
                              <Checkbox
                                name={index + col.id}
                                id={index + col.id}
                                onChange={() => checkAction(row, col.id)}
                                checked={row[col.id] === 1}
                              />
                            </TableCell>
                          )
                        }
                        else {
                          return (
                            <TableCell
                              key={index + col.id}
                              align={col.numeric ? 'right' : 'left'}
                              padding={col.disablePadding ? 'none' : 'normal'}
                            >
                              {row[col.id]}
                            </TableCell>
                          )
                        }
                      })}
                      {((editAction || deleteAction || selectAction || printAction || viewAction) && !disabled) && (
                        <TableCell align='center' padding='none' >
                          {(editAction && !row[editCondition]) ? (
                            <IconButton size='small' aria-label='Editar' onClick={() => editAction(row)}>
                              <Edit />
                            </IconButton>
                          ) : (
                            <IconButton size='small' aria-label='Editar' onClick={() => viewAction(row)}>
                              <Visibility />
                            </IconButton>
                          )}
                          {(!editAction && viewAction) && (
                            <IconButton size='small' aria-label='Editar' onClick={() => viewAction(row)}>
                              <Visibility />
                            </IconButton>
                          )}
                          {deleteAction && (
                            <IconButton size='small' aria-label='Deletar' onClick={() => deleteAction(row)}>
                              <Delete />
                            </IconButton>
                          )}
                          {selectAction && (
                            <IconButton size='small' aria-label='Selecionar' onClick={() => selectAction(row)}>
                              <Forward />
                            </IconButton>
                          )}
                          {printAction && (
                            <IconButton size='small' aria-label='Imprimir' onClick={() => printAction(row)}>
                              <Print />
                            </IconButton>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {!noSelect && (
        <div className='footer-table'>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20, 25]}
            component='div'
            labelRowsPerPage='Registros por página'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <SelectedRows numSelected={selected.length} action={() => deleteSelection(selected)} />
        </div>
      )}
    </div>
  );
}