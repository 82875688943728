import { getStates, getCities, getAddress } from './api';
import { SetLoading, RemoveLoading } from '../System';

import Functions from '../../../utils/Functions';

export const GET_STATES = 'GET_STATES';
export const GET_CITIES = 'GET_CITIES';
export const GET_ADRESS = 'GET_ADRESS';

export const GetStates = () => {
  return (dispatch) => {
    dispatch(SetLoading('GetStates'));
    return getStates()
      .then((response) => {
        dispatch({
          type: GET_STATES,
          payload: response.data,
        });
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('GetStates')));
  };
};

export const GetCities = (state, call, address) => {
  return (dispatch) => {
    dispatch(SetLoading('GetCities'));
    return getCities(state)
      .then((response) => {
        dispatch({
          type: GET_CITIES,
          payload: response.data,
        });
        if (call) call(response.data, address);
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('GetCities')));
  };
};

export const GetAddress = (postal_code, call) => {
  return (dispatch) => {
    dispatch(SetLoading('GetAddress'));
    return getAddress(postal_code)
      .then((response) => {
        // dispatch({
        //   type: GET_ADRESS,
        //   payload: response.data,
        // });
        call(response.data);
      })
      .catch((error) => {
        // Functions.ErrorString('NETWORK ERROR');
        call(null, error);
      })
      .finally(() => dispatch(RemoveLoading('GetAddress')));
  };
};