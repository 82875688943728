import React from 'react';

import Logo from '../../assets/logo/icon-black.svg';

const Loading = () => {
  return (
    <div className='main-loading'>
      <img src={Logo} alt='Logo SIGEP' className='img-loading' />
    </div>
  );
}

export default Loading;