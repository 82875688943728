import {
  SIGN_IN,
  SOCIAL_USER,
  SIGN_OUT,
  FIND_USER,
  LIST_USERS,
  CLEAR_USERS,
} from '../../actions/Users';

import cookie from 'react-cookies';

const global = {
  user: cookie.load('USER') || undefined,
  socialUser: cookie.load('SOCIAL_USER') || undefined,
  authentication: cookie.load('ACCESS_TOKEN') || undefined,
  users: []
}

export default function UsersReducer(state = global, action) {
  const valueReturn = action.payload;

  switch (action.type) {
    case SIGN_IN:
       cookie.save('ACCESS_TOKEN', valueReturn.accessToken, { maxAge: 86399 });
       cookie.save('USER', valueReturn.user, { maxAge: 86399 });
      return { ...state, authentication: valueReturn.accessToken, user: valueReturn.user }
    case SOCIAL_USER:
       cookie.save('SOCIAL_USER', valueReturn, { maxAge: 86399 });
      return { ...state, socialUser: valueReturn }
    case FIND_USER:
      cookie.save('USER', valueReturn, { maxAge: 86399 });
      return { ...state, user: valueReturn }
    case LIST_USERS:
      return { ...state, users: valueReturn }
    case CLEAR_USERS:
      return { ...state, users: [] }
    case SIGN_OUT:
      cookie.remove('ACCESS_TOKEN');
      cookie.remove('SOCIAL_USER');
      cookie.remove('USER');
      return {
        ...state,
        authentication: undefined,
        user: undefined,
        socialUser: undefined
      };
    default:
      return state;
  }
}