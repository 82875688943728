import { model, modelLogin } from '../../../services';

export const signin = (data) => modelLogin.post('/signin', data);
export const verify = (token, data) =>  data ?
  modelLogin.post(`/user/verify/${token}`, data) :
  modelLogin.post(`/user/verify/${token}`);
export const registered = (data) => modelLogin.post('/user/registered', data);
export const credentials = (data) => modelLogin.put('/user/credentials', data);
export const resetPassword = (data) => modelLogin.post('/user/password', data);
export const resend = (data) => modelLogin.post('/user/resend', data);
export const list = (data) => {
  if(data){
    return model.post(`/users`, data);
  }
  else{
    return model.get(`/users`);
  }
};
export const add = (data) => model.post(`/user`, data);
export const find = (_id) => model.get(`/user/${_id}`);
export const update = (data) => model.put(`/user`, data);
export const updatePassword = (data) => model.put('/user/password', data);
export const updateEmail = (data) => model.put('/user/email', data);
export const activate = (data) => model.put(`/user/activate`, data);