import { model } from '../../../services';

export const list = (data) => {
  if(data){
    return model.post(`/orders`, data);
  }
  else{
    return model.get(`/orders`);
  }
};

export const add = (data) => model.post(`/order`, data);

export const find = (_id) => model.get(`/order/${_id}`);

export const update = (data) => model.put(`/order`, data);

export const printOrder = (_id) => model.get(`/order/print/${_id}`, {
  responseType: 'arraybuffer',
  headers: {
      Accept: 'application/pdf',
  },
});

export const addProduct = (data) => model.post(`/order/product`, data);

export const updateProduct = (data) => model.put(`/order/product`, data);

export const delProduct = (data) => model.delete(`/order/product`, {data});

export const addService = (data) => model.post(`/order/service`, data);

export const updateService = (data) => model.put(`/order/service`, data);

export const delService = (data) => model.delete(`/order/service`, {data});