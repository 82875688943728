import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Grid } from '@mui/material';

import DataTable from '../../../components/DataTable';
import ActionsBar from '../../../components/ActionsBar';

import { ListProviders, ActivateProvider, DeleteProvider } from '../../../redux/actions/Providers';
import Functions from '../../../utils/Functions';
import useWindowDimensions from '../../../utils/useWindowDimensions';

const Providers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();

  const [searchValue, setSearchValue] = useState('');
  const [list, setList] = useState([]);
  const [columns, setColumns] = useState([]);

  const providers = useSelector((state) => state.providers.providers);

  const add = () => {
    navigate('/registrations/providers/add');
  }

  const search = (update) => {
    if (update && searchValue === '') {
      return dispatch(ListProviders());
    }
    else {
      const data = {
        field: '_id',
        search: searchValue
      };
      return dispatch(ListProviders(data));
    }
  }

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    if (value.length >= 3 || value.length === 0) {
      search();
    }
  }

  const handleCheck = (item, column) => {
    switch (column) {
      case 'active':
        dispatch(ActivateProvider({ _id: item._id }, () => search(true)))
        break;
      default:
        break;
    }
  }

  const edit = (item) => {
    navigate('/registrations/providers/edit', {
      state: {
        provider: item
      }
    });
  }

  const delet = (item) => {
    dispatch(DeleteProvider(item._id, deleteReturn));
  }

  const deleteReturn = (response, error) => {
    if (response) {
      Functions.SuccessString(`Fornecedor excluido com sucesso.`);
      dispatch(ListProviders());
    }
    else {
      Functions.ErrorString('NETWORK ERROR');
    }
  }

  useEffect(() => {
    if (providers.length > 0) {
      const tempList = [];
      providers.map((item) => {
        return tempList.push({
          ...item,
          salesManPhone: item.salesMan.phone ? Functions.MaskPhone(item.salesMan.phone) : null,
          salesManName: item.salesMan.name,
          document: Functions.MaskCNPJ(item.document)
        })
      });
      setList(tempList);
    }
    else {
      setList([])
    }

    // eslint-disable-next-line
  }, [providers]);

  useEffect(() => {
    dispatch(ListProviders());
    document.title = 'SIGEP - Fornecedores';

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let limit = 0;
    if (width < 1300) {
      limit = 30
    }
    else if (width < 1200) {
      limit = 20
    }
    else if (width < 1100) {
      limit = 15
    }
    else {
      limit = 40;
    }
    setColumns([
      { id: 'name', numeric: false, disablePadding: true, label: 'Nome', limit: limit },
      { id: 'salesManName', numeric: false, disablePadding: false, label: 'Vendedor' },
      { id: 'salesManPhone', numeric: false, disablePadding: false, label: 'Telefone' },
      { id: 'active', numeric: false, disablePadding: false, label: 'Ativo', boolean: true },
    ]);

    // eslint-disable-next-line
  }, [height, width]);

  return (
    <Grid container className='body-main' spacing={3}>
      <ActionsBar
        add={add}
        search={search}
        inputValue={searchValue}
        handle={(event) => handleSearch(event)}
      />
      <DataTable
        columns={columns}
        rows={list}
        checkAction={handleCheck}
        editAction={edit}
        deleteAction={delet}
      />
    </Grid>
  );
}

export default Providers;