import {
  FIND_BUY,
  LIST_BUYS,
  CLEAR_BUYS,

  ADD_PRODUCT_BUY,
  EDIT_PRODUCT_BUY,
  DELETE_PRODUCT_BUY

} from '../../actions/Buys';

const global = {
  buy: undefined,
  buys: [],
}

export default function BuysReducer(state = global, action) {
  const valueReturn = action.payload;

  switch (action.type) {
    case LIST_BUYS:
      return { ...state, buys: valueReturn }
    case FIND_BUY:
      return { ...state, buy: valueReturn }
    case ADD_PRODUCT_BUY:
      const newProducts = state.buy.products;
      newProducts.push(valueReturn);
      return {
        ...state,
        buy: {
          ...state.buy,
          products: newProducts
        }
      }
    case EDIT_PRODUCT_BUY:
      const editProducts = state.buy.products.map(item => {
        if (item._id === valueReturn._id) {
          item.quantity = valueReturn.quantity;
          item.unityPrice = valueReturn.unityPrice;
          item.salePrice = valueReturn.salePrice;
          item.total = valueReturn.total;
        }
        return item;
      });
      return {
        ...state,
        buy: {
          ...state.buy,
          products: editProducts
        }
      };
    case DELETE_PRODUCT_BUY:
      const deleteProducts = state.buy.products.filter(item => 
        item._id !== valueReturn._id);
      return {
        ...state,
        buy: {
          ...state.buy,
          products: deleteProducts
        }
      };
    case CLEAR_BUYS:
      return { ...state, buys: [] }
    default:
      return state;
  }
}