import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

const Redirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    if(location.pathname === '/orders'){
      navigate('/orders/view');
    }
    else{
      navigate('/drashboard');
    }
    
    // eslint-disable-next-line
  }, []);
  return null;
}
 
export default Redirect;