import React from 'react';

const Image = ({ children, className, ...params }) => (
  <div className={className}>
    <button
      className='image-button'
      {...params}
    >
      {children}
    </button>
  </div>
);

export default Image;