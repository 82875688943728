import React from 'react';

import {
  MdDashboard,
  MdLibraryBooks,
  MdPerson,
  MdPeople,
  MdDomain,
  MdToys,
  MdPrint,
  MdAssignment,
  MdLayers,
  MdReceipt,
  MdNoteAdd,
  MdPageview,
  MdShoppingBag,
  //MdSettings
} from 'react-icons/md';

import Drashboard from '../pages/Drashboard';
import DrashboardRedirect from '../pages/Drashboard/Redirect';

//CLIENTS
import Clients from '../pages/Registrations/Clients';
import AddClient from '../pages/Registrations/Clients/Add';

//PRODUCTS
import Products from '../pages/Registrations/Products';
import AddProduct from '../pages/Registrations/Products/Add';

//PROVIDERS
import Providers from '../pages/Registrations/Providers';
import AddProvider from '../pages/Registrations/Providers/Add';

//USERS
import Users from '../pages/Registrations/Users';
import AddUser from '../pages/Registrations/Users/Add';

//ORDERS
import Orders from '../pages/Orders';
import AddOrder from '../pages/Orders/Add';

//BUYS
import Buys from '../pages/Buys';
import AddBuy from '../pages/Buys/Add';

//ULTILIES
import Balance from '../pages/Utilities/Balance';
import PrintTags from '../pages/Utilities/PrintTags';

const routesArray = [
  //exact: true,
  { path: '/drashboard', element: <Drashboard />, name: 'Drashboard', icon: <MdDashboard /> },
  {
    path: '/registrations', element: <DrashboardRedirect />, name: 'Cadastros', icon: <MdLibraryBooks />, children: [
      {
        path: '/registrations/clients', element: <Clients />, name: 'Clientes', icon: <MdPeople />, children: [
          { path: '/registrations/clients/add', element: <AddClient />, name: 'Adicionar novo' },
          { path: '/registrations/clients/edit', element: <AddClient />, name: 'Editar' }
        ]
      },
      {
        path: '/registrations/providers', element: <Providers />, name: 'Fornecedores', icon: <MdDomain />, children: [
          { path: '/registrations/providers/add', element: <AddProvider />, name: 'Adicionar novo' },
          { path: '/registrations/providers/edit', element: <AddProvider />, name: 'Editar' }
        ]
      },
      {
        path: '/registrations/products', element: <Products />, name: 'Produtos', icon: <MdToys />, children: [
          { path: '/registrations/products/add', element: <AddProduct />, name: 'Adicionar novo' },
          { path: '/registrations/products/edit', element: <AddProduct />, name: 'Editar' }
        ]
      },
      {
        path: '/registrations/users', element: <Users />, name: 'Usuários', icon: <MdPerson />, children: [
          { path: '/registrations/users/add', element: <AddUser />, name: 'Adicionar novo' },
          { path: '/registrations/users/edit', element: <AddUser />, name: 'Editar' }
        ]
      }
    ]
  },
  {
    path: '/orders', element: <DrashboardRedirect />, name: 'Pedidos', icon: <MdReceipt />, children: [      
      { path: '/orders/add', element: <AddOrder />, name: 'Adicionar novo', icon: <MdNoteAdd /> },
      { path: '/orders/view', element: <Orders />, name: 'Visualizar pedidos', icon: <MdPageview /> },
      { path: '/orders/edit/:_id', element: <AddOrder />, name: 'Editar', hide: true }
    ]
  },
  {
    path: '/buys', element: <DrashboardRedirect />, name: 'Compras', icon: <MdShoppingBag />, children: [      
      { path: '/buys/add', element: <AddBuy />, name: 'Adicionar novo', icon: <MdNoteAdd /> },
      { path: '/buys/view', element: <Buys />, name: 'Visualizar compras', icon: <MdPageview /> },
      { path: '/buys/edit/:_id', element: <AddBuy />, name: 'Editar', hide: true }
    ]
  },
  {
    path: '/utilities', element: <DrashboardRedirect />, name: 'Utilitários', icon: <MdLayers />, children: [
      { path: '/utilities/balance', element: <Balance />, name: 'Balanço Anual', icon: <MdAssignment /> },
      { path: '/utilities/printtags', element: <PrintTags />, name: 'Imprimir Etiquetas', icon: <MdPrint /> }
    ]
  },
  // { path: '/settings', element: <Drashboard />, name: 'Settings', icon: <MdSettings /> }
];

export default routesArray;