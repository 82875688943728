import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { Grid } from '@mui/material';

import Input from '../../../components/Input';
import AddressList from '../../../components/AddressList';
import ButtonsForm from '../../../components/ButtonsForm';
import PhoneList from '../../../components/PhoneList';
import EmailList from '../../../components/EmailList';
import ContactList from '../../../components/ContactList';

import { AddClient, UpdateClient } from '../../../redux/actions/Clients';

import { ClientTypeEnum } from '../../../utils/Enums';
import Functions from '../../../utils/Functions';
import Validates from '../../../utils/Validates';

const InitialAddress = {
  _id: undefined,
  label: '',
  post_code: '',
  state: -1,
  city: -1,
  road: '',
  sub_urb: '',
  number: undefined,
  complement: '',
  reference: ''
};
const InitialEmail = {
  _id: undefined,
  address: '',
  label: -1
};
const InitialPhone = {
  _id: undefined,
  number: '',
  label: -1
};
const InitialContact = {
  _id: undefined,
  name: '',
  label: '',
  phone: '',
  email: ''
};

const InitialValues = {
  edit: false,
  _id: undefined,
  name: '',
  type: 1,
  document: '',
  addresses: [{ ...InitialAddress }],
  emails: [{ ...InitialEmail }],
  phones: [{ ...InitialPhone }],
  companyName: '',
  stateRegistry: '',
  segment: '',
  contacts: [{ ...InitialContact }],
  personalRegistry: '',
  birthDate: null
};

const InitialAddressInvalids = {
  post_code: false,
  state: false,
  city: false,
  road: false,
  sub_urb: false,
  number: false
};
const InitialEmailInvalids = {
  address: false,
};
const InitialPhoneInvalids = {
  number: false,
};
const InitialContactInvalids = {
  name: false,
  phone: false,
  email: false
}

const InitialInvalids = {
  name: false,
  type: false,
  document: false,
  addresses: [{ ...InitialAddressInvalids }],
  emails: [{ ...InitialEmailInvalids }],
  phones: [{ ...InitialPhoneInvalids }],
  companyName: false,
  stateRegistry: false,
  contacts: [{ ...InitialContactInvalids }],
  personalRegistry: false,
  birthDate: false
};

const InitialFeedBacks = {
  addresses: [{}],
  emails: [{}],
  phones: [{}],
  contacts: [{}],
  form: undefined
}

const Add = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [values, setValues] = useState(InitialValues);
  const [invalids, setInvalids] = useState(InitialInvalids);
  const [feedBacks, setFeedBacks] = useState(InitialFeedBacks);
  const [dates, setDates] = useState(undefined);

  const handleDate = (value, name, required) => {
    setValues({
      ...values,
      [name]: value
    });
    if (invalids[name]) {
      validateField(name, value, 'date', required);
    }
  }

  const handle = (event, type, required, size, documentType) => {
    const { name, value } = event.target;

    if (type === 'document') {
      setValues({
        ...values,
        [name]: values.type === 1 ? Functions.MaskCNPJ(value) : Functions.MaskCPF(value)
      });
    }
    else if (type === 'select') {
      setValues({
        ...values,
        [name]: Number(value)
      });
    }
    else {
      setValues({
        ...values,
        [name]: value
      });
    }
    if (name === 'type' && values.document !== '') {
      const documentType = Number(value);
      validateField('document', values.document, 'document', true, null, documentType);
    }
    else if (invalids[name]) {
      validateField(name, value, type, required, size, documentType);
    }
  };

  const validateField = (name, value, type, required, size, documentType) => {
    let valueReturn = Validates.Field(value, type, required, size, documentType);

    setInvalids({
      ...invalids,
      [name]: valueReturn.invalid
    });
    setFeedBacks({
      ...feedBacks,
      [name]: valueReturn.feedBack
    });

  };

  const setList = (name, list, invalidsList, feedBacksList) => {
    setValues({
      ...values,
      [name]: list
    });
    setInvalids({
      ...invalids,
      [name]: invalidsList
    });
    setFeedBacks({
      ...feedBacks,
      [name]: feedBacksList
    });
  }
  const save = () => {
    console.log(values);
    if (Validates.Client(values, invalids)) {
      //erro
      setFeedBacks({
        ...feedBacks,
        form: 'Preenchimento Obrigatório.'
      });
      Functions.ErrorString('Preenchimento incorreto, por favor verifique os campos em destaque.');
    }
    else {
      const phonesTemp = [];
      values.phones.map((phone) => phonesTemp.push({
        ...phone,
        number: phone.number.replace(/\D/g, '')
      }));
      const data = {
        ...values,
        phones: phonesTemp,
        document: values.document.replace(/\D/g, ''),
        birthDate: Number(values.type) === 2 ?
          values.birthDate.toISOString() : null,
      };



      values.edit ?
        dispatch(UpdateClient(data, apiReturn)) :
        dispatch(AddClient(data, apiReturn));
    }

  }

  const apiReturn = (response, error) => {
    if (response) {
      Functions.SuccessString(`Cliente ${values.edit ? 'editado' : 'cadastrado'} com sucesso.`);
      return backList();
    }
    else {
      return Functions.ErrorString('NETWORK ERROR');
    }
  }

  const backList = () => {
    return navigate('/registrations/clients');
  }

  useEffect(() => {
    const client = location.state && location.state.client ? location.state.client : null;

    if (client) {
      const typeClient = parseInt(client.type);

      let addressInvalids = [];
      let addressFeedBacks = [];
      let phoneInvalids = [];
      let phoneFeedbacks = [];
      let emailInvalids = [];
      let emailFeedbacks = [];
      let contactInvalids = [];
      let contactFeedbacks = [];
      let phonesArrange = [];

      setDates({
        createdAt: Functions.ShowDateTime(client.createdAt),
        updateAt: Functions.ShowDateTime(client.updateAt),
        createdBy: client.createdBy,
        updateBy: client.updateBy
      })

      if (client.addresses.length > 0) {
        for (let index = 0; index < client.addresses.length; index++) {
          addressFeedBacks.push({});
          addressInvalids.push({ ...InitialInvalids.addresses });
        }
      }
      if (client.phones.length > 0) {
        for (let index = 0; index < client.phones.length; index++) {
          phoneFeedbacks.push({});
          phoneInvalids.push({ ...InitialInvalids.phones });
          phonesArrange.push({
            ...client.phones[index],
            number: Functions.MaskPhone(client.phones[index].number)
          });
        }
      }
      if (client.emails.length > 0) {
        for (let index = 0; index < client.emails.length; index++) {
          emailFeedbacks.push({});
          emailInvalids.push({ ...InitialInvalids.emails });
        }
      }
      if (client.contacts && client.contacts.length > 0) {
        for (let index = 0; index < client.contacts.length; index++) {
          contactFeedbacks.push({});
          contactInvalids.push({ ...InitialInvalids.contacts });
        }
      }
      else {
        contactFeedbacks.push({});
        contactInvalids.push({ ...InitialInvalids.contacts });
      }

      setValues({
        ...values,
        ...client,
        type: typeClient,
        phones: phonesArrange,
        contacts: typeClient === 1 ?
          client.contacts : InitialValues.contacts,
        birthDate: typeClient === 2 ?
          new Date(client.birthDate) : null,
        edit: true
      });
      setInvalids({
        ...invalids,
        addresses: addressInvalids,
        phones: phoneInvalids,
        emails: emailInvalids,
        contacts: contactInvalids
      });
      setFeedBacks({
        ...feedBacks,
        addresses: addressFeedBacks,
        phones: phoneInvalids,
        emails: emailInvalids,
        contacts: contactFeedbacks
      });
      document.title = `SIGEP - Editar cliente`;
    }
    else if (location.pathname === '/registrations/clients/edit') {
      backList();
    }
    else {
      setValues({
        ...InitialValues,
        addresses: [{ ...InitialAddress }],
        emails: [{ ...InitialEmail }],
        phones: [{ ...InitialPhone }],
        contacts: [{ ...InitialContact }],
      });
      setInvalids({
        ...InitialInvalids,
        addresses: [{ ...InitialAddressInvalids }],
        emails: [{ ...InitialEmailInvalids }],
        phones: [{ ...InitialPhoneInvalids }],
        contacts: [{ ...InitialContactInvalids }],
      });
      setFeedBacks({
        ...InitialFeedBacks,
        addresses: [{}],
        emails: [{}],
        phones: [{}],
        contacts: [{}],
      });
      document.title = 'SIGEP - Adicionar cliente';
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Grid container className='body-main form' spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Input
          id='name'
          label='Nome'
          name='name'
          value={values.name}
          autoFocus
          required
          onChange={(event) => handle(event, 'text', true, 3)}
          onBlur={() => validateField('name', values.name, 'text', true, 3)}
          error={invalids.name || (values.name === '' && feedBacks.form !== undefined)}
          helperText={feedBacks.name || feedBacks.form}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Input
          id='type'
          label='Tipo'
          name='type'
          model='select'
          list={ClientTypeEnum}
          defaultValue='SELECIONE'
          value={values.type}
          onChange={(event) => handle(event, 'select', true)}
          onBlur={() => validateField('type', values.type, 'select', true)}
          required
          error={invalids.type || (values.type === -1 && feedBacks.form !== undefined)}
          helperText={feedBacks.type || feedBacks.form}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Input
          id='document'
          label={values.type === 1 ? 'CNPJ' : 'CPF'}//'CPF/CNPJ'
          name='document'
          value={values.document}
          required
          onChange={(event) => handle(event, 'document', true, null, values.type)}
          onBlur={() => validateField('document', values.document, 'document', true, null, values.type)}
          error={invalids.document || (values.document === '' && feedBacks.form !== undefined)}
          helperText={feedBacks.document || feedBacks.form}
        />
      </Grid>
      {values.type === 1 && (
        <Fragment>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              id='companyName'
              label='Razão Social'
              name='companyName'
              value={values.companyName}
              required
              onChange={(event) => handle(event, 'text', true, 5)}
              onBlur={() => validateField('companyName', values.companyName, 'text', true, 5)}
              error={invalids.companyName || (values.companyName === '' && feedBacks.form !== undefined)}
              helperText={feedBacks.companyName || feedBacks.form}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Input
              id='stateRegistry'
              label='Inscrisão Estadual'
              name='stateRegistry'
              value={values.stateRegistry}
              required
              onChange={(event) => handle(event, 'number', true)}
              onBlur={() => validateField('stateRegistry', values.stateRegistry, 'number', true)}
              error={invalids.stateRegistry || (values.stateRegistry === '' && feedBacks.form !== undefined)}
              helperText={feedBacks.stateRegistry || feedBacks.form}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Input
              id='segment'
              label='Segmento'
              name='segment'
              value={values.segment}
              onChange={(event) => handle(event, 'text', false)}
            />
          </Grid>
        </Fragment>
      )}
      {values.type === 2 && (
        <Fragment>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              id='personalRegistry'
              label='RG'
              name='personalRegistry'
              value={values.personalRegistry}
              required
              onChange={(event) => handle(event, 'number', true)}
              onBlur={() => validateField('personalRegistry', values.personalRegistry, 'number', true)}
              error={invalids.personalRegistry || (values.personalRegistry === '' && feedBacks.form !== undefined)}
              helperText={feedBacks.personalRegistry || feedBacks.form}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              id='birthDate'
              label='Data Nascimento'
              name='birthDate'
              maxdate={'12/03/2022'}
              value={values.birthDate}
              required
              model='dateTime'
              onChange={(event) => handleDate(event, 'birthDate', true)}
              onBlur={() => validateField('birthDate', values.birthDate, 'date', true)}
              error={invalids.birthDate || (values.birthDate === '' && feedBacks.form !== undefined)}
              helperText={feedBacks.birthDate || feedBacks.form}
            />
          </Grid>
        </Fragment>
      )}
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <PhoneList
          list={values.phones}
          invalidsList={invalids.phones}
          feedBacksList={feedBacks.phones}
          setList={setList}
          edit={values.edit}
          formFeedBack={feedBacks.form}
          required
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <EmailList
          list={values.emails}
          invalidsList={invalids.emails}
          feedBacksList={feedBacks.emails}
          setList={setList}
          edit={values.edit}
          formFeedBack={feedBacks.form}
          required
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <AddressList
          list={values.addresses}
          invalidsList={invalids.addresses}
          feedBacksList={feedBacks.addresses}
          setList={setList}
          edit={values.edit}
          formFeedBack={feedBacks.form}
          required
        />
      </Grid>
      {values.type === 1 && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ContactList
            list={values.contacts}
            invalidsList={invalids.contacts}
            feedBacksList={feedBacks.contacts}
            setList={setList}
            edit={values.edit}
            formFeedBack={feedBacks.form}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <ButtonsForm cancel={backList} save={save} dates={dates} />
      </Grid>
    </Grid>
  );
}

export default Add;