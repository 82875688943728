import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { MultiProvider } from '@j-meira/mui-theme';

import './style/index.scss';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import Store from './redux/store';
import App from './App';
import Toast from './components/Toast';

ReactDOM.render(
  <Provider store={Store}>
    <MultiProvider
      adapterLocalePtBR
      snackAnchorHorizontal='right'
      snackAnchorVertical='top'
      snackAutoHideDuration={5000}
      snackMax={3}
      palette={{
        primary: {
          light: '#cdd0d9',
          main: '#9c9fa8',
          dark: '#6e7179',
          contrastText: '#000',
        },
        secondary: {
          light: '#ff5f4e',
          main: '#ed1c24',
          dark: '#b20000',
          contrastText: '#fff',
        },
      }}
    >
      <App />
      <Toast />
    </MultiProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
reportWebVitals();