import React from 'react';

import Basic from './Basic';
import Password from './Password';
import Select from './Select';
import Icon from './Icon';
import Currency from './Currency';
import DateTime from './DateTime';
import Search from './Search';

const Input = ({ model, ...params }) => {
  switch (model) {
    case 'select':
      return (
        <Select {...params} />
      );
    case 'password':
      return (
        <Password {...params} />
      );
    case 'icon':
      return (
        <Icon {...params} />
      );
    case 'currency':
      return (
        <Currency {...params} />
      );
    case 'dateTime':
      return (
        <DateTime {...params} />
      );
    case 'search':
      return (
        <Search {...params} />
      );
    default:
      return (
        <Basic {...params} />
      );
  }
}

export default Input;