import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@mui/material';

import Input from '../../components/Input';
import DialogPopUp from '../../components/DialogPopUp';

import Functions from '../../utils/Functions';
import Validates from '../../utils/Validates';
import { ListProducts } from '../../redux/actions/Products';
import { AddProductBuy, FindBuy, UpdateProductBuy } from '../../redux/actions/Buys';

const InitialValues = {
  _id: null,
  description: '',
  quantity: 0,
  unityPrice: '',
  total: '',
  salePrice: '',
  edit: false
};

const InitialInvalids = {
  _id: false,
  description: false,
  quantity: false,
  unityPrice: false,
  total: false,
  salePrice: false
}

const InitialFeedBacks = {
  form: undefined
}

const ProductForm = ({ open, toggle, buyId, product }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(InitialValues);
  const [invalids, setInvalids] = useState(InitialInvalids);
  const [feedBacks, setFeedBacks] = useState(InitialFeedBacks);
  const products = useSelector((state) => state.products.products);

  const handle = (event, type, required, size) => {
    const { name, value } = event.target;

    if (name === 'quantity') {
      const total = String(Number(values.unityPrice.replace(/\D/g, '')) * value);
      setValues({
        ...values,
        quantity: value,
        total: Functions.MaskCurrency(total)
      });
    }
    else if (name === 'unityPrice') {
      const total2 = String(Number(value.replace(/\D/g, '')) * values.quantity);
      setValues({
        ...values,
        unityPrice: Functions.MaskCurrency(value),
        total: Functions.MaskCurrency(total2)
      });
    }
    else if (name === 'salePrice') {
      setValues({
        ...values,
        salePrice: Functions.MaskCurrency(value),
      });
    }
    else {
      setValues({
        ...values,
        [name]: value
      });
    }
    if (invalids[name]) {
      validateField(name, value, type, required, size);
    }
  };

  const handleSearch = (name, value, required) => {
    setValues({
      ...values,
      [name]: value,
      description: value && value.description ? value.description : '',
      salePrice: value && value.salePrice ? Functions.ShowCurrency(value.salePrice) : ''
    });
    if (invalids[name]) {
      validateField(name, value, 'search', required);
    }
  }

  const validateField = (name, value, type, required, size) => {
    let valueReturn = Validates.Field(value, type, required, size);

    setInvalids({
      ...invalids,
      [name]: valueReturn.invalid
    });
    setFeedBacks({
      ...feedBacks,
      [name]: valueReturn.feedBack
    });

  };

  const save = () => {
    console.log(values);
    if (Validates.BuyProduct(values, invalids)) {
      setFeedBacks({
        ...feedBacks,
        form: 'Preenchimento Obrigatório.'
      });
      Functions.ErrorString('Preenchimento incorreto, por favor verifique os campos em destaque.');
    }
    else {
      const data = {
        ...values,
        _id: values._id._id,
        buyId,
        salePrice: Functions.APICurrency(values.salePrice),
        unityPrice: Functions.APICurrency(values.unityPrice)
      }
      values.edit ?
        dispatch(UpdateProductBuy(data, apiReturn)) :
        dispatch(AddProductBuy(data, apiReturn));
    }

  }

  const apiReturn = (response, error) => {
    if (response) {
      Functions.SuccessString(`Produto ${values.edit ? 'editado' : 'adcionada'} com sucesso.`);
      toggle();
    }
    else {
      if (error.status && error.status === 404) {
        Functions.ErrorString('Produto não encontrado.')
      }
      else if (error.status && error.status === 400) {
        Functions.ErrorString('Quantidade em estoque não atende ao pedido.')
      }
      else {
        Functions.ErrorString('NETWORK ERROR');
      }
      return dispatch(FindBuy(buyId, toggle));

    }
  }  

  const KeySpect = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      save(values);
    }
  }

  useEffect(() => {
    if (!open) {
      setValues(InitialValues);
      setInvalids(InitialInvalids);
      setFeedBacks(InitialFeedBacks);
    }
    else if (product) {
      setValues({
        ...values,
        _id: {_id: product._id},
        description: product.description,
        quantity: product.quantity,
        unityPrice: Functions.ShowCurrency(product.unityPrice),
        total: Functions.ShowCurrency(product.total),
        salePrice: Functions.ShowCurrency(product.salePrice),
        edit: true
      })
    }

    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (products.length === 0) dispatch(ListProducts({ field: 'description' }));

    // eslint-disable-next-line
  }, []);

  return (
    <DialogPopUp
      open={open}
      toggle={toggle}
      title={values.edit ? 'Alterar produto' : 'Adicionar produto'}
      action={save}
    >
      <Grid container className='body-main form' spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Input
            id='_id'
            label='Código'
            name='_id'
            value={values._id}
            model='search'
            list={products}
            required
            searchKey='_id'
            onChange={(event, value) => handleSearch('_id', value, true)}
            onBlur={() => validateField('_id', values._id, 'search', true)}
            error={invalids._id || (values._id === null && feedBacks.form !== undefined)}
            helperText={feedBacks._id || feedBacks.form}
            disabled={values.edit}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Input
            id='description'
            label='Descrição'
            name='description'
            value={values.description}
            required
            onChange={(event) => handle(event, 'text', true, 5)}
            onBlur={() => validateField('description', values.description, 'text', true, 5)}
            error={invalids.description || (values.description === '' && feedBacks.form !== undefined)}
            helperText={feedBacks.description || feedBacks.form}
            disabled={true}
            onKeyDown={KeySpect}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Input
            id='unityPrice'
            label='Valor unitário'
            name='unityPrice'
            model='currency'
            value={values.unityPrice}
            onChange={(event) => handle(event, 'currency', true)}
            onBlur={() => validateField('unityPrice', values.unityPrice, 'currency', true)}
            required
            error={invalids.unityPrice || (values.unityPrice === '' && feedBacks.form !== undefined)}
            helperText={feedBacks.unityPrice || feedBacks.form}
            onKeyDown={KeySpect}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Input
            id='quantity'
            label='Quantidade'
            name='quantity'
            type='number'
            value={values.quantity}
            onChange={(event) => handle(event, 'number', true)}
            onBlur={() => validateField('quantity', values.quantity, 'number', true)}
            required
            error={invalids.quantity || (values.quantity === 0 && feedBacks.form !== undefined)}
            helperText={feedBacks.quantity || feedBacks.form}
            onKeyDown={KeySpect}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <Input
            id='total'
            label='Valor total'
            name='total'
            model='currency'
            value={values.total}
            onChange={(event) => handle(event, 'currency', true)}
            onBlur={() => validateField('total', values.total, 'currency', true)}
            required
            readOnly={true}
            error={invalids.total || (values.total === '' && feedBacks.form !== undefined)}
            helperText={feedBacks.total || feedBacks.form}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Input
            id='salePrice'
            label='Preço de venda'
            name='salePrice'
            model='currency'
            value={values.salePrice}
            onChange={(event) => handle(event, 'currency', true)}
            onBlur={() => validateField('salePrice', values.salePrice, 'currency', true)}
            required
            error={invalids.salePrice || (values.salePrice === '' && feedBacks.form !== undefined)}
            helperText={feedBacks.salePrice || feedBacks.form}
            onKeyDown={KeySpect}
          />
        </Grid>
      </Grid>
    </DialogPopUp>
  );
}

export default ProductForm;