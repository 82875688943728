import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DialogPopUp = ({ open, toggle, title, action, children }) => {
  return (
    <Dialog
      open={open}
      onClose={toggle}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='dialog-pop-up'
    >
      <DialogTitle id='alert-dialog-title' onClose={toggle}>
        {title}
        <IconButton aria-label='close' className='dialog-pop-up-close' onClick={toggle}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => toggle()} color='primary'>
          Cancelar
        </Button>
        <Button onClick={() => action()} color='primary'>
          Salvar
        </Button>
      </DialogActions>
    </Dialog >
  );
}

export default DialogPopUp;