import React from 'react';

import Button from '@mui/material/Button';

const Basic = ({ children, className, ...params }) => (
  <div className={className}>
    <Button
      fullWidth
      variant='contained'
      {...params}
    >
      {children}
    </Button>
  </div>
);

export default Basic;


