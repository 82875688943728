import React from 'react';

import OutlinedDiv from '../OutlinedDiv'
import Address from './Contact';

const InitialInvalid = {
  name: false,
  phone: false,
  email: false
}

const InitialContact = {
  _id: undefined,
  name: '',
  label: '',
  phone: '',
  email: ''
}
const ContactList = ({ list, setList, invalidsList, feedBacksList, required, formFeedBack }) => {
  const addItem = () => {
    const newList = list;
    const newInvalids = invalidsList;
    const newFeedBacks = feedBacksList;

    newList.push({ ...InitialContact });
    newInvalids.push({ ...InitialInvalid });
    newFeedBacks.push({});

    setList('contacts', newList, newInvalids, newFeedBacks);
  }

  const removeItem = (index) => {
    const newList = list;
    const newInvalids = invalidsList;
    const newFeedBacks = feedBacksList;

    if (list.length > 1 || index !== 0) {
      newList.splice(index, 1);
      newInvalids.splice(index, 1);
      newFeedBacks.splice(index, 1);
    }

    setList('contacts', newList, newInvalids, newFeedBacks);
  }

  const setItem = (index, item, invalid, feedBack) => {
    const newList = list;
    const newInvalids = invalidsList;
    const newFeedBacks = feedBacksList;

    newList[index] = item;
    newInvalids[index] = invalid;
    newFeedBacks[index] = feedBack;

    setList('contacts', newList, newInvalids, newFeedBacks);
  }

  return (
    <div className='address-list-area'>
      <OutlinedDiv label='Contatos' required={required} >
        {list &&
          list.map((item, index) => (
            <Address
              key={`contato-${index}`}
              item={item}
              index={index}
              addItem={addItem}
              removeItem={removeItem}
              setItem={setItem}
              listSize={list.length}
              list={list}
              invalids={invalidsList[index]}
              feedBacks={feedBacksList[index]}
              formFeedBack={formFeedBack}
            />
          ))}
      </OutlinedDiv>
    </div>
  )
}

export default ContactList;