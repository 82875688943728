import moment from 'moment';
import { toast } from 'react-toastify';

const ShowError = (mensage) => {
  return toast.error(mensage);
};

const ShowSuccess = (mensage) => {
  return toast.success(mensage);
};

const Functions = {
  ErrorCode: (code) => {
    switch (code) {
      case 1:
        ShowError('Usuário não encontrado.');
        break;
      case 10:
        ShowError('Erro de comunicação com o Facebook.');
        break;
      case 11:
        ShowError('Erro de comunicação com o Google.');
        break;
      case 401:
        ShowError('Email ou senha inválidos.');
        break;
      case 416:
        ShowError('Email não verificado.');
        break;
      case 423:
        ShowError('Usuário inativo.');
        break;
      case 500:
        ShowError('Erro interno no servidor.');
        break;
      default:
        ShowError('NETWORK ERROR');
        break;
    }
  },

  ErrorString: (Message) => {
    ShowError(Message);
  },

  SuccessString: (Message) => {
    ShowSuccess(Message);
  },

  GetReleaseDate: (value) => {
    let date = new Date(value);
    return (`De: ${Functions.ConvertNumber(date.getDate())}/${
      Functions.ConvertNumber(date.getMonth() + 1)}/${
      Functions.ConvertNumber(date.getFullYear())} as ${
      Functions.ConvertNumber(date.getHours() - 3)}:${
      Functions.ConvertNumber(date.getMinutes())}`);
  },

  MaskCurrency: (value) => {
    value = value
      .replace(/\D/g, '')
      .replace(/^(0+)(\d)/g, '$2');
    let valueReturn = null;
    switch (value.length) {
      case 1:
        valueReturn = `0,0${value}`
        break;
      case 2:
        valueReturn = `0,${value}`
        break;
      default:
        let int = value.slice(0, -2);
        int = int.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        valueReturn = `${int},${value.slice(-2)}`;
        break;
    }
    return valueReturn;
  },

  MaskCPF: (value) => {
    value = value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
    return value;
  },

  MaskCNPJ: (value) => {
    value = value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
    return value;
  },

  MaskCEP: (value) => {
    value = value
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(\d{5})(\d)/, '$1-$2');

    return value;
  },

  MaskPhone: (value) => {
    value = value.replace(/\D/g, '');
    if (value.length < 11) {
      value = value
        .replace(/(\d{0})(\d)/, '$1($2')
        .replace(/(\d{2})(\d)/, '$1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(\d{4})(\d)/, '$1-$2');
    }
    else {
      value = value
        .replace(/(\d{0})(\d)/, '$1($2')
        .replace(/(\d{2})(\d)/, '$1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(\d{4})(\d)/, '$1$2');
    }
    return value;
  },

  MaskPlate: (value) => {
    value = value
      .toUpperCase()
      .replace(/[^A-Za-z0-9]+/g, '')
      .replace(/([\d\w]{3})([\d\w])/, '$1-$2')
      .replace(/([\d\w]{4})([\d\w])/, '$1-$2')
      .slice(0, 8);

    return value.toUpperCase();
  },

  ShowCurrency: (value) => {
    // let valueReturn = String(value.toFixed(2));
    // valueReturn = valueReturn.replace('.', ',');
    return value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL' });
  },

  ShowDateTime: (value, noTime) => {
    let valueReturn = value ?
      noTime ? moment(value).format('DD/MM/YYYY') :
        moment(value).format('DD/MM/YYYY hh:mm') : null;
    return valueReturn;
  },

  ShowEnum: (list, value) => {
    const valueReturn = list.filter((item) => item.value === value)[0];
    return valueReturn ? valueReturn.label : null;
  },

  APICurrency: (value) => {
    value = value
      .replace('.', '')
      .replace(',', '.');
    let valueReturn = Number(value);
    return valueReturn;
  },

  ArrangeEnumByPermission: (list, value) => {
    const valueReturn = list.filter((item) => item.value >= value);
    return valueReturn;
  },

  ArrangeAPIList: (list) => {
    const newList = [];
    list.map((item) =>
      newList.push({
        label: item.name,
        value: Number(item._id),
        abbreviation: item.abbreviation ? item.abbreviation : undefined
      })
    );
    return newList;
  },

  ConvertNumber: (value) => {
    switch (value) {
      case 0:
        value = '00';
        break;
      case 1:
        value = '01';
        break;
      case 2:
        value = '02';
        break;
      case 3:
        value = '03';
        break;
      case 4:
        value = '04';
        break;
      case 5:
        value = '05';
        break;
      case 6:
        value = '06';
        break;
      case 7:
        value = '07';
        break;
      case 8:
        value = '08';
        break;
      case 9:
        value = '09';
        break;
      default:
        value = String(value);
        break;
    }
    return value
  },

  Download: (response, fileNameExt) => {
    console.log(response);
    let fileName = '';
    if (response.headers && response.headers['content-disposition']) {
      const matches = response.headers['content-disposition'].split('"');
      if (matches[1]) fileName = matches[1];
    }
    if (fileName === '') fileName = fileNameExt + '.pdf';
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  },

  GetWindowDimensions: () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  },

  GetFakeLabel: (label) => {
    return `${label} *`;
  },
};

export default Functions;