
export const SET_LOADING = 'SET_LOADING';
export const REMOVE_LOADING = 'REMOVE_LOADING';
export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const SET_ROWS = 'SET_ROWS';


export const SetLoading = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_LOADING,
      payload
    })
  }
}

export const RemoveLoading = (payload) => {
  return dispatch => {
    dispatch({
      type: REMOVE_LOADING,
      payload
    })
  }
}

export const OpenDialog = (payload) => {
  return dispatch => {
    console.log(payload);
    dispatch({
      type: OPEN_DIALOG,
      payload
    })
  }
}

export const CloseDialog = (payload) => {
  return dispatch => {
    dispatch({
      type: CLOSE_DIALOG,
      payload
    })
  }
}

export const SetRows = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_ROWS,
      payload
    })
  }
}