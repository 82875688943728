import { list, find, add, del, update, activate } from './api';
import { SetLoading, RemoveLoading } from '../System';

import Functions from '../../../utils/Functions';

export const FIND_PROVIDER = 'FIND_PROVIDER';
export const LIST_PROVIDERS = 'LIST_PROVIDERS';
export const CLEAR_PROVIDERS = 'CLEAR_PROVIDERS';

export const ListProviders = (data) => {
  return (dispatch) => {
    dispatch(SetLoading('ListProviders'));
    return list(data)
      .then((response) => {
        dispatch({
          type: LIST_PROVIDERS,
          payload: response.data,
        });
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('ListProviders')));
  };
};

export const FindProvider = (data) => {
  return (dispatch) => {
    dispatch(SetLoading('FindProvider'));
    return find(data)
      .then((response) => {
        dispatch({
          type: FIND_PROVIDER,
          payload: response.data,
        });
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('FindProvider')));
  };
};

export const AddProvider = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('AddProvider'));
    return add(data)
      .then((response) => call(response.data))
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('AddProvider')));
  };
};

export const DeleteProvider = (_id, call) => {
  return (dispatch) => {
    dispatch(SetLoading('DeleteProvider'));
    return del(_id)
      .then((response) => call(response.data))
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('DeleteProvider')));
  };
};

export const UpdateProvider = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('UpdateProvider'));
    return update(data)
      .then((response) => call(response.data))
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('UpdateProvider')));
  };
};

export const ActivateProvider = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('ActivateProvider'));
    return activate(data)
      .then(call)
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('ActivateProvider')));
  };
};

export const ClearProviders = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_PROVIDERS
    })
  }
};
