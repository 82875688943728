import React, { useEffect } from 'react';
import { Typography } from '@mui/material';

import logo from '../../assets/logo/logo.svg';
import LogoJM from '../../assets/logo/jm-mono.svg';

const LandingPage = () => {

  useEffect(() => {
    document.title = `Auto Elétrica e Mecânica Mineira`;

    // eslint-disable-next-line
  }, []);

  return (
    <div className="landing-page">
      <img src={logo} className='logo' alt='Logo Mineira' />
      <Typography className='title' component='h2'>
        Serviços Elétricos e Mecânicos, Peças em geral
      </Typography>
      <Typography className='phone' component='h3'>
      Tel.: (33) 3521-2634 - Cel.: (33) 99103-0268
      </Typography>
      <Typography className='msg' component='h4'>
        Página em construção.
      </Typography>
      <div className='copyright'>
        <span className='text'>Developer by:</span>
        <a href='https://jmcreative.com.br' target='_blank' rel='noopener noreferrer' >
          <img className='img' src={LogoJM} alt='Logo JM Creative' />
        </a>
      </div>
    </div>
  );
}

export default LandingPage;