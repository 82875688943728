import React from 'react';

import {
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormHelperText,
  FormControl
} from '@mui/material';
import Functions from '../../utils/Functions';

const Currency = ({ label, helperText, error, required, action, icon, name, ...params }) => {

  return (
    <FormControl value='outlined' margin='normal' fullWidth error={error} required={required}
      size='small' >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <OutlinedInput
        type='text'
        startAdornment={
          <InputAdornment position='start'>R$</InputAdornment>
        }
        label={Functions.GetFakeLabel(label)}
        name={name}
        {...params}
      />
      {(helperText && error) && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
export default Currency;
