import React, { useState } from 'react';

import {
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormHelperText,
  FormControl
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Functions from '../../utils/Functions';


const Password = ({ label, helperText, error, required, name, ...params }) => {
  const [showPassword, setshowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl variant='outlined' margin='normal' fullWidth error={error} required={required}
      size='small' >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <OutlinedInput
        type={showPassword ? 'text' : 'password'}
        label={Functions.GetFakeLabel(label)}
        name={name}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        {...params}
      />
      {(helperText && error) && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
export default Password;
