import {
  list,
  find,
  add,
  update,
  printOrder,

  addProduct,
  updateProduct,
  delProduct,

  addService,
  updateService,
  delService

} from './api';
import { SetLoading, RemoveLoading } from '../System';

import Functions from '../../../utils/Functions';

export const FIND_ORDER = 'FIND_ORDER';
export const LIST_ORDERS = 'LIST_ORDERS';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const PRINT_ORDER = 'PRINT_ORDER';
export const ADD_PRODUCT_ORDER = 'ADD_PRODUCT_ORDER';
export const EDIT_PRODUCT_ORDER = 'EDIT_PRODUCT_ORDER';
export const DELETE_PRODUCT_ORDER = 'DELETE_PRODUCT_ORDER';
export const ADD_SERVICE_ORDER = 'ADD_SERVICE_ORDER';
export const EDIT_SERVICE_ORDER = 'EDIT_SERVICE_ORDER';
export const DELETE_SERVICE_ORDER = 'DELETE_SERVICE_ORDER';

export const ListOrders = (data) => {
  return (dispatch) => {
    dispatch(SetLoading('ListOrders'));
    return list(data)
      .then((response) => {
        dispatch({
          type: LIST_ORDERS,
          payload: response.data,
        });
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('ListOrders')));
  };
};

export const FindOrder = (_id, call) => {
  return (dispatch) => {
    dispatch(SetLoading('FindOrder'));
    return find(_id)
      .then((response) => {
        if (call) call();
        dispatch({
          type: FIND_ORDER,
          payload: response.data,
        });
      })
      .catch((error) => {
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('FindOrder')));
  };
};

export const AddOrder = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('AddOrder'));
    return add(data)
      .then((response) => call(response.data))
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('AddOrder')));
  };
};

export const UpdateOrder = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('UpdateOrder'));
    return update(data)
      .then((response) => call(response.data))
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('UpdateOrder')));
  };
};

export const ClearOrders = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_ORDERS
    })
  }
};

export const PrintOrder = (_id) => {
  return (dispatch) => {
    dispatch(SetLoading('PrintOrder'));
    return printOrder(_id)
      .then((response) => {
        dispatch({
          type: PRINT_ORDER,
          payload: Functions.Download(response, 'Pedido')
        });
      })
      .catch((error) => {
        console.log(error);
        Functions.ErrorString('NETWORK ERROR');
      })
      .finally(() => dispatch(RemoveLoading('PrintOrder')));
  };
};

export const AddProductOrder = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('AddProductOrder'));
    return addProduct(data)
      .then((response) => {
        call(response);
        dispatch({
          type: ADD_PRODUCT_ORDER,
          payload: data
        });
      })
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('AddProductOrder')));
  };
};

export const UpdateProductOrder = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('UpdateProductOrder'));
    return updateProduct(data)
      .then((response) => {
        call(response);
        dispatch({
          type: EDIT_PRODUCT_ORDER,
          payload: data
        });
      })
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('UpdateProductOrder')));
  };
};

export const DeleteProductOrder = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('DeleteProductOrder'));
    return delProduct(data)
      .then((response) => {
        call({ ...response, type: 'Produto' });
        dispatch({
          type: DELETE_PRODUCT_ORDER,
          payload: data
        });
      })
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('DeleteProductOrder')));
  };
};

export const AddServiceOrder = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('AddServiceOrder'));
    return addService(data)
      .then((response) => {
        call(response);
        dispatch({
          type: ADD_SERVICE_ORDER,
          payload: {
            ...data,
            _id: response.data._id
          }
        });
      })
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('AddServiceOrder')));
  };
};

export const UpdateServiceOrder = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('UpdateServiceOrder'));
    return updateService(data)
      .then((response) => {
        call(response);
        dispatch({
          type: EDIT_SERVICE_ORDER,
          payload: data
        });
      })
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('UpdateServiceOrder')));
  };
};

export const DeleteServiceOrder = (data, call) => {
  return (dispatch) => {
    dispatch(SetLoading('DeleteServiceOrder'));
    return delService(data)
      .then((response) => {
        call({ ...response, type: 'Serviço' });
        dispatch({
          type: DELETE_SERVICE_ORDER,
          payload: data
        });
      })
      .catch((error) => {
        console.log(error);
        call(null, error.response);
      })
      .finally(() => dispatch(RemoveLoading('DeleteServiceOrder')));
  };
};