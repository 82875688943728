import { LIST_PROVIDERS, CLEAR_PROVIDERS } from '../../actions/Providers';

const global = {
  providers: [],
}

export default function ProvidersReducer(state = global, action) {
  const valueReturn = action.payload;

  switch (action.type) {
    case LIST_PROVIDERS:
      return { ...state, providers: valueReturn }
    case CLEAR_PROVIDERS:
      return { ...state, providers: [] }
    default:
      return state;
  }
}